import { captureException } from "@sentry/react";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";

import { RainBadge } from "brand/components/Badge";
import Day from "brand/components/Day";
import tableStyles from "brand/components/Table.module.css";
import DropdownMenu, { DropdownMenuItem } from "brand/components/DropdownMenu";
import {
  fmtRainM,
  fmtRainI,
  fmtWindM,
  fmtWindI,
  fmtWindUnitM,
  fmtWindUnitI,
  fmtSnowRangeM,
  fmtSnowRangeI,
  forecastDays,
  mpsToMph,
  useLocationHourly,
  mphToMps,
  fmtAgo,
} from "shared";

import { mngr } from "../store";
import { Link } from "react-router-dom";
import Pin from "brand/components/Pin";
import BarChart from "brand/components/chart/BarChart";
import colors from "brand/scripts/colors";
import { useDarkMode } from "brand/hooks/useDarkMode";
import { trackEvent } from "../tracking";

const nbsp = "\u00A0";

export default function LocationRow({
  l,
  data,
  isLoadingByLayer,
  error,
  mode,
}) {
  const {
    data: groups,
    loading: groupsLoading,
    error: groupsError,
  } = mngr.useGroups();
  const pageLink = `/location/${l.properties.id}`;

  if (error || groupsError) {
    // TODO - should we be capturing errors here or in the store? or somewhere else?
    captureException(error);
    return (
      <tr>
        <td colSpan={16}>
          <div style={{ color: "var(--text-danger)", padding: "0.5rem 0" }}>
            Error loading data for {l.properties.name || "this location"}
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tr data-testid="LocationTableRow-tr">
      <td>
        <Link className={tableStyles.tableCellIconWrapper} to={pageLink}>
          <Pin
            isPinned={l.properties.isPinned}
            onClick={() => {
              mngr.toggleLocationPin(l.properties.id);
            }}
          />
          <div>
            <div className={tableStyles.tableCellTitle}>
              {l.properties.name || (
                <span style={{ color: "var(--text-disabled)" }}>
                  Unnamed location
                </span>
              )}
            </div>
            <div className={tableStyles.tableCellSub}></div>
          </div>
        </Link>
      </td>
      <td>
        <Link className={tableStyles.tableCellIconWrapper} to={pageLink}>
          {groupsLoading
            ? "..."
            : groups[l.properties.groupId]?.name || (
                // This span makes the anchor tag fill the space of the cell so people can click it
                <span style={{ opacity: 0, userSelect: "none" }}>.</span>
              )}
        </Link>
      </td>
      {mode === "wind" ? (
        <LocationRowWindFragment
          l={l}
          data={data}
          isLoadingByLayer={isLoadingByLayer}
          error={error}
        />
      ) : (
        <LocationRowRainFragment
          l={l}
          data={data}
          isLoadingByLayer={isLoadingByLayer}
          error={error}
        />
      )}
      <td>
        <DropdownMenu label="Location options" align="end">
          <DropdownMenuItem
            onSelect={() => {
              const name = prompt(
                "Enter a new name for this location",
                l.properties.name
              );
              if (!name || name === "") return;
              trackEvent("location_renamed");
              mngr.changeLocationName(l.properties.id, name);
            }}
          >
            <Pencil1Icon />
            Edit name
          </DropdownMenuItem>
          <DropdownMenuItem
            destructive
            onSelect={() => {
              if (confirm("Are you sure you want to delete this location?")) {
                trackEvent("location_removed");
                mngr.deleteLocation(l.properties.id);
              }
            }}
          >
            <TrashIcon />
            Delete location
          </DropdownMenuItem>
        </DropdownMenu>
      </td>
    </tr>
  );
}

function LocationRowRainFragment({ l, data, isLoadingByLayer, error }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;
  const fmtSnowRange =
    preferredUnits === "metric" ? fmtSnowRangeM : fmtSnowRangeI;

  const last48 = !isLoadingByLayer.last48 && data?.last48?.properties?.precip;
  const precipType = data?.last48?.properties?.precip_type || "rain";

  const recentRain =
    !isLoadingByLayer.recentRain && data?.recentRain?.properties?.precip;

  const pageLink = `/location/${l.properties.id}`;

  if (error) return null;

  const byDate = data?.next16?.properties?.days.reduce((acc, d) => {
    acc[d.startTime.slice(0, 10)] = d;
    return acc;
  }, {});

  return (
    <>
      <td>
        <Link className={tableStyles.tableCellIconWrapper} to={pageLink}>
          {
            <RainBadge
              isLoading={isLoadingByLayer.last48}
              amount={last48}
              precipType={precipType}
              fmtRain={fmtRain}
              fmtSnowRange={fmtSnowRange}
            />
          }
        </Link>
      </td>
      <td>
        <Link className={tableStyles.tableCellIconWrapper} to={pageLink}>
          {!isLoadingByLayer.recentRain && `${fmtRain(recentRain || 0)}`}
          {nbsp}
          {fmtAgo(data?.recentRain?.properties?.endTime)}
        </Link>
      </td>
      {forecastDays().map((d) => {
        // NOTE our daily data is 12Z
        const dkey = d.date.toISOString().slice(0, 10);
        const amount = !isLoadingByLayer.next16 && byDate?.[dkey]?.precip;
        const probability =
          !isLoadingByLayer.next16 && byDate?.[dkey]?.precip_probability;
        const precipType = byDate?.[dkey]?.precip_type;

        return (
          <td className={tableStyles.tableCellCentered} key={dkey}>
            <Link
              className={tableStyles.tableCellIconWrapper}
              to={pageLink}
              style={{
                display: "inline-flex",
              }}
            >
              {
                <Day
                  precipType={precipType}
                  isLoading={isLoadingByLayer.next16}
                  amount={amount}
                  percent={!isLoadingByLayer.next16 && probability}
                  fmtRain={fmtRain}
                  fmtSnowRange={fmtSnowRange}
                />
              }
            </Link>
          </td>
        );
      })}
    </>
  );
}
function LocationRowWindFragment({ l, data, isLoadingByLayer, error }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtWind = preferredUnits === "metric" ? fmtWindM : fmtWindI;
  const fmtWindUnit = preferredUnits === "metric" ? fmtWindUnitM : fmtWindUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mpsToMph;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : mphToMps;

  const pageLink = `/location/${l.properties.id}`;
  const isDarkMode = useDarkMode();

  const {
    data: dataHourly,
    isLoading: isLoadingHourly,
    error: errorHourly,
  } = useLocationHourly(
    l,
    new Date(),
    new Date(Date.now() + 48 * 60 * 60 * 1000)
  );

  const hourlyWindData = dataHourly?.properties?.hours?.map(
    ({ startTime, wind_speed, source }) => {
      return {
        value: toConvertedUnits(wind_speed),
        date: new Date(startTime),
        source,
      };
    }
  );

  if (error) return null;

  return (
    <>
      <td>{fmtWind(data?.wind?.properties?.wind_speed_10m)}</td>
      <td>
        <Link
          className={tableStyles.tableCellIconWrapper}
          to={pageLink}
          style={{ display: "flex" }}
        >
          <div
            style={{
              width: "100%",
              height: "30px",
            }}
          >
            {errorHourly ? (
              "Error loading wind"
            ) : (
              <BarChart
                spark
                fullHeightMode
                xAxisLabel=""
                yAxisLabel=""
                xAxisTimeFormat="%m/%d - %I%p"
                unitFormatter={fmtWindUnit}
                toOriginalUnits={toOriginalUnits}
                colorScale={
                  isDarkMode ? colors["wind-dark"] : colors["wind-light"]
                }
                data={hourlyWindData}
                isLoading={isLoadingHourly}
                // data={hourlyWindDataFake}
              />
            )}
          </div>
        </Link>
      </td>
    </>
  );
}
