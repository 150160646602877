type Heading = "N" | "NE" | "E" | "SE" | "S" | "SW" | "W" | "NW";

const headingToDegrees = (heading: Heading) => {
  // Don't hate! Ifs were more dense than a switch. Whatever.
  if (heading === "N") return 0;
  if (heading === "NE") return 45;
  if (heading === "E") return 90;
  if (heading === "SE") return 135;
  if (heading === "S") return 180;
  if (heading === "SW") return 225;
  if (heading === "W") return 270;
  if (heading === "NW") return 315;
  console.warn("Invalid heading", heading);
  return 0;
};

export default function ({
  heading,
  degrees: degreesParam,
  size = 20,
}: {
  heading?: Heading;
  degrees?: number;
  size?: number;
}) {
  const hasDegrees = typeof degreesParam === "number";
  const degrees = hasDegrees ? degreesParam : headingToDegrees(heading!);

  return (
    <div
      style={{
        rotate: `${degrees}deg`,
        width: `${size}px`,
        height: `${size}px`,
      }}
    >
      <svg
        viewBox="0 0 100 100"
        style={{
          width: "100%",
          height: "100%",
          fill: "currentColor",
        }}
      >
        <path d="M50 0 L90 100 L50 70 L10 100 Z" fill="current" />
      </svg>
    </div>
  );
}
