import { LockClosedIcon } from "@radix-ui/react-icons";

import Button from "./Button";
import styles from "./Blocker.module.css";

export default function Blocker({
  title,
  cta = "See upgrade options",
  onUpgrade = () => {},
}: {
  title?: string;
  cta?: string;
  onUpgrade?: () => void;
}) {
  return (
    <div className={styles.wrapper}>
      <div style={{ margin: "0 0 .5rem" }}>
        <LockClosedIcon />
      </div>
      {title && <h3 style={{ margin: "0 0 .5rem" }}>{title}</h3>}
      <Button large secondary onClick={onUpgrade}>
        {cta}
      </Button>
      <div className={styles.blocker} />
    </div>
  );
}
