import { useState } from "react";

import Card from "brand/components/Card";
import {
  fmtWindUnitM,
  fmtWindUnitI,
  mpsToMph,
  useLocationHourly,
  chartTimeRangeOptions,
  ChartTimeRangeOption,
  mphToMps,
} from "shared";
// import { useDarkMode } from "brand/hooks/useDarkMode";
import colors from "brand/scripts/colors";
import { Spinner } from "brand/components/Loading";
import { ErrorState } from "brand/components/EmptyState";
import LineChart from "brand/components/chart/LineChart";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
import { mngr } from "../store";

export default function LocationPageWind({ location }) {
  // const isDarkMode = useDarkMode();

  const { preferredUnits } = mngr.useUnits();
  const fmtWindUnit = preferredUnits === "metric" ? fmtWindUnitM : fmtWindUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mpsToMph;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : mphToMps;
  const windUnit = preferredUnits === "metric" ? "m/s" : "mph";

  const [timeRange, setTimeRange] = useState<ChartTimeRangeOption>(
    chartTimeRangeOptions[chartTimeRangeOptions.length - 1]
  );

  const {
    data: dataHourly,
    isLoading: isLoadingHourly,
    error: errorHourly,
  } = useLocationHourly(location, timeRange.start, timeRange.end);

  const hourlyWindData = dataHourly?.properties?.hours?.map(
    ({ startTime, wind_speed, source }) => {
      return {
        value: toConvertedUnits(wind_speed),
        date: new Date(startTime),
        source,
      };
    }
  );

  const startString = timeRange.start.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });
  const endString = timeRange.end.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });

  return (
    <Card>
      <div
        className="flex-row gap-4 justify-between align-center"
        style={{ marginBottom: "1rem" }}
      >
        <h4>
          Recent wind speed{" "}
          <span className="color-tertiary font-xs weight-normal">
            {startString} - {endString}
          </span>
        </h4>
        <ButtonGroupWrapper>
          {chartTimeRangeOptions.map((t) => (
            <Button
              key={t.label}
              secondary
              selected={timeRange.label === t.label}
              onClick={() => setTimeRange(t)}
            >
              {t.label}
            </Button>
          ))}
        </ButtonGroupWrapper>
      </div>

      {errorHourly ? (
        <ErrorState error={errorHourly} />
      ) : isLoadingHourly ? (
        <Spinner padded />
      ) : (
        <>
          <LineChart
            yAxisLabel={`Average wind speed (${windUnit})`}
            xAxisTimeFormat="%a %I%p"
            xAxisTickFrequency={"daily"}
            hoverTimeFormat={"%a %I%p"}
            nowLine={true}
            unitFormatter={fmtWindUnit}
            toConvertedUnits={toConvertedUnits}
            toOriginalUnits={toOriginalUnits}
            showRules
            cumulative={false}
            colorScale={colors["slate"]}
            // gradient={true}
            // weatherFactor={"wind"}
            // colorScale={isDarkMode ? colors["wind-dark"] : colors["wind-light"]}
            data={hourlyWindData}
          />
          <br />
        </>
      )}
    </Card>
  );
}
