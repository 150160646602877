import { useState } from "react";
import { SearchFeature, geocode, getGeocodeDetails } from "shared";
import styles from "./GeoSearch.module.css";

// TODO when this is schmicko let's replace MapboxGeocoder in dashboard/src/pages/Map.tsx
export default function GeoSearch({ mapRef }) {
  const [results, setResults] = useState<SearchFeature[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  return (
    <div className={styles.container}>
      <input
        type="search"
        placeholder="Find a location"
        onChange={async (e) => {
          setIsSearching(true);
          const fc = await geocode(e.target.value);
          setResults(fc.features);
          setIsSearching(false);
        }}
      />
      {!results.length ? (
        ""
      ) : (
        <div className={styles.results}>
          {!isSearching &&
            results.map((result) => {
              return (
                <div
                  key={result.id}
                  className={styles.result}
                  onClick={async () => {
                    const results = await getGeocodeDetails(result);
                    const viewport = results.result.geometry.viewport;
                    mapRef.current?.fitBounds([
                      [viewport.southwest.lng, viewport.southwest.lat],
                      [viewport.northeast.lng, viewport.northeast.lat],
                    ]);
                  }}
                >
                  <div className={styles.title}>{result.text}</div>
                  <div className={styles.subtitle}>{result.place_name}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}
