// @ts-nocheck
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Card from "brand/components/Card";
import BarChart from "brand/components/chart/BarChart";
import LineChart from "brand/components/chart/LineChart";

import PageLayout from "../layouts/PageLayout";
import LocationMapThumb from "../components/LocationMapThumb";
import { mngr } from "../store";
import {
  useLocationDaily,
  fmtRainM,
  fmtRainI,
  fmtRainUnitM,
  fmtRainUnitI,
  mmToIn,
  inToMM,
} from "shared";
import Breadcrumbs from "brand/components/Breadcrumbs";
import ErrorPage from "./ErrorPage";
import { Placeholder, Spinner } from "brand/components/Loading";
import styles from "./Location.module.css";
import NoAuthPage from "./NoAuthPage";
import { CalendarIcon, Crosshair1Icon } from "@radix-ui/react-icons";

export default function LocationWrapped() {
  const { locationId } = useParams();
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();
  const location = locations?.[locationId];
  if (locationsError) return <ErrorPage error={locationsError} />;
  if (!location) return <NoAuthPage />;
  return <Location location={location} locationsLoading={locationsLoading} />;
}

export const dtfmt = (d) => d.toISOString().slice(0, 10);

function Location({ location, locationsLoading }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;
  const fmtRainUnit = preferredUnits === "metric" ? fmtRainUnitM : fmtRainUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : inToMM;
  const rainUnit = preferredUnits === "metric" ? "mm" : `"`;

  const start = new Date("Jan 1 2023 12:00 am CST");
  const end = new Date("Dec 31 2023 11:59 pm CST");
  const monthDt = start;

  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location, start, end);

  const name = location?.properties?.name;
  const group = mngr.groups?.[location?.properties?.groupId]?.name;

  // locationsLoading probably redundant here but keeping just in case
  const isLoading = locationsLoading || isLoadingDaily;

  if (errorDaily) {
    return <ErrorPage error={errorDaily} />;
  }

  // const byDay =
  //   !isLoading &&
  //   keyBy(dataDaily?.properties?.days, (d) => d["startTime"].slice(0, 10));

  const dataFormattedDaily = dataDaily?.properties?.days?.map(
    ({ startTime, precip, source }) => {
      return {
        value: toConvertedUnits(precip),
        date: new Date(startTime),
        source,
      };
    }
  );

  const dataFormattedWeeklyObj = dataFormattedDaily?.reduce((acc, d) => {
    const dt = new Date(d.date);
    const week = dtfmt(
      new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - dt.getDay())
    );
    const source = d.source;
    if (!acc[week]) acc[week] = { value: 0, date: new Date(week), source };
    acc[week].value += d.value;
    return acc;
  }, {});
  const dataFormattedWeekly = Object.values(dataFormattedWeeklyObj || {});

  const dataFormattedMonthlyObj = dataFormattedDaily?.reduce((acc, d) => {
    const dt = new Date(d.date);
    const month = dtfmt(new Date(dt.getFullYear(), dt.getMonth()));
    const source = d.source;
    if (!acc[month]) acc[month] = { value: 0, date: new Date(month), source };
    acc[month].value += d.value;
    return acc;
  }, {});
  const dataFormattedMonthly = Object.values(dataFormattedMonthlyObj || {});

  const pageName = isLoading ? "..." : name || "Unnamed location";

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Chicago", // CST timezone
    // hour12: false, // 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit'
  });

  return (
    <PageLayout>
      <Helmet>
        <title>Precip | {pageName}</title>
      </Helmet>

      <div className={styles.columns}>
        <div>
          <div
            className="flex-col border-bottom w100"
            style={{
              padding: "2vh 0 1rem",
              margin: "0 0 2rem",
            }}
          >
            <Breadcrumbs
              pages={[
                { name: "Locations", url: "/locations" },
                { name: pageName },
              ]}
            />
            <div className="flex-row gap-4 justify-between align-end">
              <div className="flex-col gap-2 align-base">
                <h1
                  style={{
                    margin: 0,
                    lineHeight: 1,
                  }}
                >
                  Annual precipitation report:{" "}
                  {isLoading ? (
                    <Placeholder
                      style={{
                        width: "12rem",
                        height: "2rem",
                      }}
                    />
                  ) : (
                    name || (
                      <span style={{ color: "var(--text-disabled)" }}>
                        Unnamed location
                      </span>
                    )
                  )}
                </h1>
                <div
                  className="flex-row gap-4 align-base"
                  style={{ lineHeight: 1, fontSize: "var(--s)" }}
                >
                  {group && (
                    <span
                      style={{
                        color: "var(--text-tertiary)",
                        margin: "0 .5rem 0 0",
                      }}
                    >
                      {group}
                    </span>
                  )}

                  <div className="flex-row align-end gap-2">
                    <CalendarIcon />
                    <div>
                      {formatter.format(start)} to {formatter.format(end)}
                    </div>
                  </div>
                  <div className="flex-row align-end gap-2">
                    <Crosshair1Icon />
                    <div>
                      {location?.geometry?.coordinates?.[0].toFixed(4)}
                      {", "}
                      {location?.geometry?.coordinates?.[1].toFixed(4)}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <LocationMapThumb coordinates={location.geometry.coordinates} />
          <br />

          <Card>
            <h4>Cumulative precipitation</h4>
            {isLoading ? (
              <Spinner padded />
            ) : (
              <LineChart
                xAxisLabel={String(monthDt.getFullYear())}
                yAxisLabel={`Total rainfall (${rainUnit})`}
                xAxisTimeFormat="%b"
                nowLine={true}
                unitFormatter={fmtRainUnit}
                toConvertedUnits={toConvertedUnits}
                toOriginalUnits={toOriginalUnits}
                showRules
                data={dataFormattedDaily}
              />
            )}
          </Card>

          <Card>
            <h4>By month</h4>
            <BarChart
              xAxisLabel={String(monthDt.getFullYear())}
              yAxisLabel={`Total rainfall (${rainUnit})`}
              xAxisTimeFormat="%b"
              nowLine={false}
              unitFormatter={fmtRainUnit}
              toOriginalUnits={toOriginalUnits}
              showRules
              isLoading={isLoading}
              // @ts-ignore
              data={dataFormattedMonthly}
            />
            <br />
            <div
              style={{
                columns: "3",
                columnGap: "3rem",
              }}
            >
              {isLoading ? (
                <Spinner padded />
              ) : (
                (dataFormattedMonthly || []).map((d) => (
                  <div
                    key={d.date.toISOString()}
                    className="pad-2 border-bottom flex-row align-center justify-between"
                    style={{
                      background:
                        d.value >= 5 ? "var(--item-primary)" : "transparent",
                      fontWeight: d.value >= 5 ? "bold" : "normal",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-tertiary)",
                      }}
                    >
                      {d.date.toLocaleDateString(undefined, {
                        month: "short",
                        year: "numeric",
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-primary)",
                      }}
                    >
                      {fmtRain(toOriginalUnits(d.value))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </Card>
          <Card>
            <h4>By week</h4>
            <BarChart
              xAxisLabel={String(monthDt.getFullYear())}
              yAxisLabel={`Total rainfall (${rainUnit})`}
              xAxisTimeFormat="%b %d"
              nowLine={false}
              unitFormatter={fmtRainUnit}
              toOriginalUnits={toOriginalUnits}
              showRules
              isLoading={isLoading}
              // @ts-ignore
              data={dataFormattedWeekly}
            />
            <br />
            <div
              style={{
                columns: "3",
                columnGap: "3rem",
              }}
            >
              {isLoading ? (
                <Spinner padded />
              ) : (
                (dataFormattedWeekly || []).map((d) => (
                  <div
                    key={d.date.toISOString()}
                    className="pad-2 border-bottom flex-row align-center justify-between"
                    style={{
                      background:
                        d.value >= 1 ? "var(--item-primary)" : "transparent",
                      fontWeight: d.value >= 1 ? "bold" : "normal",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-tertiary)",
                      }}
                    >
                      {d.date.toLocaleDateString(undefined, {
                        month: "short",
                        day: "numeric",
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-primary)",
                      }}
                    >
                      {fmtRain(toOriginalUnits(d.value))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </Card>
          <Card>
            <h4>By day</h4>
            <BarChart
              xAxisLabel={String(monthDt.getFullYear())}
              yAxisLabel={`Total rainfall (${rainUnit})`}
              xAxisTimeFormat="%b"
              nowLine={false}
              unitFormatter={fmtRainUnit}
              toOriginalUnits={toOriginalUnits}
              showRules
              isLoading={isLoading}
              // @ts-ignore
              data={dataFormattedDaily}
            />

            <div
              style={{
                columns: "5",
                columnGap: "3rem",
              }}
            >
              {isLoading ? (
                <Spinner padded />
              ) : (
                (dataFormattedDaily || [])
                  .filter((d) => d.value >= 0.01)
                  .map((d) => (
                    <div
                      key={d.date.toISOString()}
                      className="pad-2 border-bottom flex-row align-center justify-between"
                      style={{
                        background:
                          d.value >= 1 ? "var(--item-primary)" : "transparent",
                        fontWeight: d.value >= 1 ? "bold" : "normal",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "var(--s)",
                          color: "var(--text-tertiary)",
                        }}
                      >
                        {d.date.toLocaleDateString(undefined, {
                          month: "short",
                          day: "numeric",
                        })}
                      </div>
                      <div
                        style={{
                          fontSize: "var(--s)",
                          color: "var(--text-primary)",
                        }}
                      >
                        {fmtRain(toOriginalUnits(d.value))}
                      </div>
                    </div>
                  ))
              )}
            </div>
            <p
              style={{
                fontSize: "var(--s)",
                paddingTop: "1rem",
                color: "var(--text-tertiary)",
              }}
            >
              Days with zero rainfall not shown
            </p>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
}
