import { MapLayer, mapLayers } from "brand/components/map/Layers";
import { addLayerRasterAnimated, removeLayerRasterAnimated } from "./rasterLayerAnimated";


// Types
function addLayerRaster(map: mapboxgl.Map, layerObj: MapLayer) {
  const rasterLayerOpacity = 0.8;
  const layerUrl = layerObj.url;
  if (!layerUrl) {
    console.error("no layer url for layer", layerObj);
    return;
  }
  // double check if layer already exists
  if (map?.getLayer(layerObj.id)) {
    map?.setLayoutProperty(layerObj.id, "visibility", "visible");
    return;
  }
  map?.addLayer({
    id: layerObj.id,
    type: "raster",
    paint: {
      "raster-opacity": rasterLayerOpacity,
    },
    // @ts-ignore
    source: {
      maxzoom: 7,
      minzoom: 3,
      type: "raster",
      // scheme: "tms",
      tiles: [layerUrl],
    },
  });
}


// TODO - talk to Sam then make MapLayer type a class
export function addLayer(map: mapboxgl.Map, layerObj: MapLayer) {
  if (layerObj?.layerType === 'raster-animated') {
    addLayerRasterAnimated(map, layerObj);
  } else {
    addLayerRaster(map, layerObj);
  }
}

// export function removeLayer(map: mapboxgl.Map, layerObj: MapLayer) {
//     if (layerObj.layerType === 'raster-animated') {
//         removeLayerRasterAnimated(map, layerObj);
//     }
// }
