import { Link, NavLink } from "react-router-dom";

import Logo from "brand/components/Logo";
import AccountDropdown from "./components/AccountDropdown";
import styles from "./RawLayout.module.css";
import { mngr } from "../store";

const RawLayout = ({
  hideNav,
  children,
}: {
  hideNav?: boolean;
  children: React.ReactNode;
}) => {
  const { hasEnterpriseBetaAccess } = mngr.useSubscription();

  return (
    <>
      <header className={styles.appHeader}>
        <div>
          <Link className={styles.logo} to={"/locations"}>
            <Logo size={20} />
            Precip
          </Link>
          {!hideNav && (
            <nav className={`${styles.navLinks} print-hidden`}>
              <NavLink
                className={({ isActive }) =>
                  `${styles.navLink} ${isActive && styles.navLinkActive}`
                }
                to={"/locations"}
              >
                Locations
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  `${styles.navLink} ${isActive && styles.navLinkActive}`
                }
                to={"/map"}
              >
                Map
              </NavLink>
            </nav>
          )}
        </div>

        <div className="flex-center print-hidden">
          {hasEnterpriseBetaAccess && (
            <NavLink
              className={({ isActive }) =>
                `${styles.navLink} ${isActive && styles.navLinkActive}`
              }
              to={"/compare"}
            >
              Compare
            </NavLink>
          )}
          <a
            className={`${styles.navLink} ${styles.navLinkSmall}`}
            href="https://webforms.pipedrive.com/f/c58y2vZyUhK6yrfmnb5Eb0DDHUQaaxEkdyQyPHFkWcn7BdeBP51SbK3g6xRi9TlJ0n"
            target="_blank"
          >
            Feedback
          </a>
          {!hideNav && <AccountDropdown />}
        </div>
      </header>
      <main className={styles.main}>{children}</main>
    </>
  );
};
export default RawLayout;
