export type PlaceType = "address" | "neighborhood" | "place" | "poi";

export type SearchFeature = {
  id: string;
  text: string;
  place_name: string;
  place_type: PlaceType[];
  geometry: {
    coordinates: [number, number];
  };
};

const GOOGLE_MAPS_KEY = "GOOGLE_MAPS_KEY"; // replaced by proxy
const sessiontoken = Math.random().toString(36).substring(2, 15);

// Just playing with styles, not sure how the component should be set up though
// - Should this be its own page instead of just a toolbar?
// - Should it use the Ionic Toolbar at all?
// - Better animation between isSearching state?
// - Would need real data obv

// const GEOCODE_BASE = "https://maps.googleapis.com";
const GEOCODE_BASE = "https://proxy-kxe64thdqq-uc.a.run.app";

export async function geocode(query) {
  // OPTION 2: Use google
  const fc = await fetch(
    `${GEOCODE_BASE}/maps/api/place/queryautocomplete/json?` +
      new URLSearchParams({
        key: GOOGLE_MAPS_KEY,
        input: query,
        // components: 'country:us',
        region: "us",
        sessiontoken,
      })
  )
    .then((r) => r.json())
    .then((data) => {
      const features = data["predictions"].map((r) => {
        // const loc = r['geometry']['location'];
        // const x = loc['lng'];
        // const y = loc['lat'];
        // const [first, ...rest] = r['formatted_address'].split(', ');
        return {
          id: r["place_id"],
          // TODO highlight main_text_matched_substrings
          text: r["structured_formatting"]["main_text"],
          place_name: r["structured_formatting"]["secondary_text"],
          type: "Feature",
          properties: {
            types: r["types"],
          },
          // geometry: { type: 'Point', coordinates: [x, y] }
        };
      });
      return { type: "FeatureCollection", features: features };
    });
  return fc;
}

export async function getGeocodeDetails(result) {
  const results = await fetch(
    `${GEOCODE_BASE}/maps/api/place/details/json?` +
      new URLSearchParams({
        key: GOOGLE_MAPS_KEY,
        place_id: result["id"],
        sessiontoken,
      })
  ).then((r) => r.json());
  return results;
}
