import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";

/* Global Precip styles */
import "brand/css/ramps.css";
import "brand/css/semantic.css";
import "brand/css/global.css";
import "brand/css/utility.css";
import "brand/css/mapbox.css";

import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
