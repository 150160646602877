import styles from "./Background.module.css";
import topoImg from "../assets/backgrounds/Andorra_topographic_map.svg";
import dotsImg from "../assets/backgrounds/dots.svg";

type PositionOption =
  | "center"
  | "top"
  | "bottom"
  | "top-bottom"
  | "left"
  | "right"
  | "left-right"
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left";

export default function Background({
  topo,
  fuzzy,
  dots,
  gradient,
  gradientColor,
  fade,
  position,
}: {
  topo?: boolean;
  fuzzy?: boolean;
  dots?: boolean;
  gradient?: PositionOption;
  gradientColor?: string;
  fade?: PositionOption;
  position?: PositionOption;
}) {
  const gradientStart = gradientColor || "rgba(var(--blue-100-rgb), 1)";
  const gradientEnd = "rgba(var(--background-primary-rgb), 1)";
  const gradientBg = !gradient
    ? ``
    : gradient === `center`
    ? `radial-gradient(${gradientStart}, ${gradientStart} 50%, ${gradientEnd} 100%)`
    : gradient === `top`
    ? `linear-gradient(to top, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `bottom`
    ? `linear-gradient(to bottom, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `top-bottom`
    ? `linear-gradient(to bottom, ${gradientStart}, ${gradientEnd} 50%, ${gradientStart} 100%)`
    : gradient === `left`
    ? `linear-gradient(to left, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `right`
    ? `linear-gradient(to right, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `left-right`
    ? `linear-gradient(to right, ${gradientStart}, ${gradientEnd} 50%, ${gradientStart} 100%)`
    : gradient === `top-left`
    ? `linear-gradient(to top left, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `top-right`
    ? `linear-gradient(to top right, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `bottom-left`
    ? `linear-gradient(to bottom left, ${gradientEnd}, ${gradientStart} 100%)`
    : gradient === `bottom-right`
    ? `linear-gradient(to bottom right, ${gradientEnd}, ${gradientStart} 100%)`
    : ``;

  const fadeClear = "rgba(var(--background-primary-rgb), 0)";
  const fadeOpaque = "rgba(var(--background-primary-rgb), 1)";
  const fadeBg = !fade
    ? ``
    : fade === `center`
    ? `radial-gradient(${fadeOpaque}, ${fadeOpaque} 50%, ${fadeClear} 100%)`
    : fade === `top`
    ? `linear-gradient(to top, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `bottom`
    ? `linear-gradient(to bottom, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `top-bottom`
    ? `linear-gradient(to bottom, ${fadeOpaque}, ${fadeClear} 50%, ${fadeOpaque} 100%)`
    : fade === `left`
    ? `linear-gradient(to left, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `right`
    ? `linear-gradient(to right, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `left-right`
    ? `linear-gradient(to right, ${fadeOpaque}, ${fadeClear} 50%, ${fadeOpaque} 100%)`
    : fade === `top-left`
    ? `linear-gradient(to top left, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `top-right`
    ? `linear-gradient(to top right, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `bottom-left`
    ? `linear-gradient(to bottom left, ${fadeClear}, ${fadeOpaque} 100%)`
    : fade === `bottom-right`
    ? `linear-gradient(to bottom right, ${fadeClear}, ${fadeOpaque} 100%)`
    : ``;
  const fuzzyPosition = !fuzzy
    ? {}
    : position === `center`
    ? { left: "50%", top: "50%", transform: "translate(-50%, -50%)" }
    : position === `top`
    ? { left: "50%", top: "0", transform: "translate(-50%, 0)" }
    : position === `bottom`
    ? { left: "50%", bottom: "0", transform: "translate(-50%, 0)" }
    : position === `top-bottom`
    ? { left: "50%", top: "0", transform: "translate(-50%, 0)" }
    : position === `left`
    ? { left: "0", top: "50%", transform: "translate(0, -50%)" }
    : position === `right`
    ? { right: "0", top: "50%", transform: "translate(0, -50%)" }
    : position === `left-right`
    ? { left: "0", top: "50%", transform: "translate(0, -50%)" }
    : position === `top-left`
    ? { left: "0", top: "0", transform: "translate(0, 0)" }
    : position === `top-right`
    ? { right: "0", top: "0", transform: "translate(0, 0)" }
    : position === `bottom-left`
    ? { left: "0", bottom: "0", transform: "translate(0, 0)" }
    : position === `bottom-right`
    ? { right: "0", bottom: "0", transform: "translate(0, 0)" }
    : ``;

  return (
    <div className={styles.base}>
      {gradient && (
        <div
          style={{
            zIndex: 10,
            position: "absolute",
            inset: 0,
            width: "100%",
            height: "100%",
            backgroundImage: gradientBg,
          }}
        />
      )}
      {fuzzy && (
        <div style={{ position: "absolute", ...fuzzyPosition }}>
          {/* <div className={styles.red} /> */}
          <div className={styles.blue} />
        </div>
      )}
      {topo && (
        <>
          <div
            style={{
              zIndex: 10,
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
              backgroundImage: fadeBg,
            }}
          />
          <img
            src={String(topoImg)}
            style={{
              zIndex: 1,
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              opacity: 0.1,
            }}
          />
        </>
      )}
      {dots && (
        <>
          <div
            style={{
              zIndex: 10,
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
              backgroundImage: fadeBg,
            }}
          />
          <div
            style={{
              zIndex: 1,
              position: "absolute",
              inset: 0,
              width: "100%",
              height: "100%",
              objectPosition: "center",
              color: "var(--text-tertiary)",
              backgroundImage: `url(${dotsImg})`,
              backgroundSize: "auto 50px",
              backgroundRepeat: "repeat",
              opacity: 0.3,
            }}
          />
        </>
      )}
    </div>
  );
}
