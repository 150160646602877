import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import AccountLayout from "../../layouts/AccountLayout";
import { handleLinkFromEmailLinkSignIn } from "../../Auth";
import { Spinner } from "brand/components/Loading";
import { trackEvent } from "../../tracking";

export default function SignIn() {
  // TODO - check for auth and automatically redirect to dashboard if already signed in?

  useEffect(() => {
    handleLinkFromEmailLinkSignIn().then(({ user, error }) => {
      if (error) {
        // TODO - show in UI, not an alert
        // TODO - handle common errors gracefully (invalid email, invalid or expired OTP)
        alert(
          `Sorry, we could not sign you in right now. (${error.message} - ${error.code})`
        );
      } else {
        trackEvent("user_signed_in");
        window.location.href = "/";
      }
    });
  }, []);

  return (
    <AccountLayout>
      <Helmet>
        <title>Precip | Verifying email link...</title>
      </Helmet>

      <Spinner padded />
    </AccountLayout>
  );
}
