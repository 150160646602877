// Generate all colors for Precip products and brands
// Uses https://culorijs.org/api/ for color interpolation

import { rgb, interpolate as culoriInterpolate } from "culori";
import {
  interpolateViridis,
  interpolateTurbo,
  interpolateMagma,
  interpolatePlasma,
  interpolateWarm,
  interpolateCool,
  interpolateRainbow,
  interpolateSinebow,
  interpolateSpectral,
} from "d3-scale-chromatic";
import {
  fToC,
  inToMM,
  mphToMps,
  PrecipType,
  WeatherFactorId,
  weatherFactorsObj as wf,
} from "shared";

export type Stop = {
  number: number;

  obj: {
    r: number;
    g: number;
    b: number;
    alpha: number;
  };

  // Add string of raw, comma-separated RGB values to each color definition
  // Format: "200, 40, 0"
  // Used for changing opacity with CSS variables
  // Ie, "rgba(var(--red-100-rgb), 0.5)"
  rgbaTriad: string;
  cssString: string;
};

export type Ramp = {
  id: string;
  name: string;
  type: string;

  // A function that returns a value based on an input from 0 to 1
  // Ie, fn(0) returns the first color, fn(1) returns the last color
  fn: (c: number) => any;
  stops?: {
    [key: number]: Stop;
  };
};

const standardStops = [
  0, 25, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700,
  750, 800, 850, 900, 950, 1000,
];

const ramps: Ramp[] = [];

// Simple color definitions, ie Roy G. Biv rainbow and neutral palettes
// Originally based on Untitled UI: https://www.untitledui.com/components/color-styles
ramps.push(
  {
    id: "slate",
    name: "Slate",
    type: "neutral",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(240, 20%, 99%)", 0.025],
      ["hsl(210, 20%, 98%)", 0.05],
      ["hsl(216, 24%, 96%)", 0.1],
      ["hsl(220, 17%, 93%)", 0.2],
      ["hsl(220, 16%, 84%)", 0.3],
      ["hsl(218, 15%, 65%)", 0.4],
      ["hsl(221, 13%, 46%)", 0.5],
      ["hsl(216, 18%, 34%)", 0.6],
      ["hsl(218, 24%, 27%)", 0.7],
      ["hsl(215, 33%, 14%)", 0.8],
      ["hsl(220, 43%, 11%)", 0.9],
      ["hsl(222, 41%, 8%)", 0.95],
      ["black", 1],
    ]),
  },
  {
    id: "neutral",
    name: "Neutral",
    type: "neutral",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(240, 10%, 99%)", 0.025],
      ["hsl(210, 10%, 98%)", 0.05],
      ["hsl(216, 10%, 96%)", 0.1],
      ["hsl(220, 10%, 93%)", 0.2],
      ["hsl(217, 10%, 84%)", 0.3],
      ["hsl(218, 10%, 65%)", 0.4],
      ["hsl(221, 10%, 46%)", 0.5],
      ["hsl(216, 12%, 34%)", 0.6],
      ["hsl(218, 14%, 27%)", 0.7],
      ["hsl(215, 17%, 14%)", 0.8],
      ["hsl(220, 22%, 11%)", 0.9],
      ["hsl(222, 21%, 8%)", 0.95],
      ["black", 1],
    ]),
  },
  {
    id: "red",
    name: "Red",
    type: "rainbow",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(12, 100%, 99%)", 0.025],
      ["hsl(0, 100%, 98%)", 0.05],
      ["hsl(4, 93%, 94%)", 0.1],
      ["hsl(3, 96%, 89%)", 0.2],
      ["hsl(4, 96%, 80%)", 0.3],
      ["hsl(4, 92%, 69%)", 0.4],
      ["hsl(4, 86%, 58%)", 0.5],
      ["hsl(4, 74%, 49%)", 0.6],
      ["hsl(4, 76%, 40%)", 0.7],
      ["hsl(4, 72%, 33%)", 0.8],
      // /* --red-900: #7a271a; */
      ["hsl(8, 82%, 13%)", 0.9],
      // /* --red-950: #55160c; */
      ["hsl(7, 84%, 10%)", 0.95],
      ["black", 1],
    ]),
  },
  {
    id: "yellow",
    name: "Yellow",
    type: "rainbow",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(42, 100%, 98%)", 0.025],
      ["hsl(45, 96%, 96%)", 0.05],
      ["hsl(45, 96%, 89%)", 0.1],
      ["hsl(44, 98%, 77%)", 0.2],
      ["hsl(42, 99%, 65%)", 0.3],
      ["hsl(39, 98%, 56%)", 0.4],
      ["hsl(34, 94%, 50%)", 0.5],
      ["hsl(28, 97%, 44%)", 0.6],
      ["hsl(22, 92%, 37%)", 0.7],
      ["hsl(19, 84%, 31%)", 0.8],
      ["hsl(18, 79%, 27%)", 0.9],
      ["hsl(17, 79%, 17%)", 0.95],
      ["black", 1],
    ]),
  },
  {
    id: "green",
    name: "Green",
    type: "rainbow",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(142, 80%, 98%)", 0.025],
      ["hsl(145, 81%, 96%)", 0.05],
      ["hsl(140, 75%, 92%)", 0.1],
      ["hsl(144, 68%, 80%)", 0.2],
      ["hsl(148, 63%, 67%)", 0.3],
      ["hsl(150, 57%, 54%)", 0.4],
      ["hsl(152, 77%, 39%)", 0.5],
      ["hsl(153, 91%, 30%)", 0.6],
      ["hsl(155, 90%, 24%)", 0.7],
      ["hsl(155, 84%, 20%)", 0.8],
      ["hsl(156, 83%, 16%)", 0.9],
      ["hsl(157, 82%, 11%)", 0.95],
      ["black", 1],
    ]),
  },
  // based on blue-dark from untitled
  {
    id: "blue",
    name: "Blue",
    type: "rainbow",
    fn: culoriInterpolate([
      ["white", 0],
      ["hsl(222, 100%, 98%)", 0.025],
      ["hsl(221, 100%, 97%)", 0.05],
      ["hsl(220, 100%, 91%)", 0.1],
      ["hsl(220, 100%, 85%)", 0.2],
      ["hsl(220, 100%, 76%)", 0.3],
      ["hsl(220, 100%, 66%)", 0.4],
      ["hsl(220, 100%, 58%)", 0.5],
      ["hsl(220, 87%, 51%)", 0.6],
      ["hsl(220, 100%, 46%)", 0.7],
      ["hsl(220, 100%, 38%)", 0.8],
      ["hsl(220, 100%, 31%)", 0.9],
      ["hsl(220, 100%, 20%)", 0.95],
      ["black", 1],
    ]),
  },

  // Shadow
  {
    id: "shadow",
    name: "Shadow",
    type: "shadow",
    fn: culoriInterpolate([
      ["hsla(220, 20%, 0%, 0)", 0],
      ["hsla(220, 20%, 0%, 1)", 1],
    ]),
  }
);

// Custom weather scales for specific weather conditions
// TODO - some way to reference other ramps here,
// ie, fn: culoriInterpolate([ [red.500, 0] ])
ramps.push(
  {
    id: "rain-light",
    name: "Rain light",
    type: "weather",
    fn: culoriInterpolate([
      ["hsla(175, 100%, 98%, 0)", wf.precip.getIntensity(inToMM(0))],
      ["hsla(175, 100%, 98%, 1)", wf.precip.getIntensity(inToMM(0.001))],
      ["hsla(205, 90%, 80%, 1)", wf.precip.getIntensity(inToMM(0.999))],
      ["hsla(215, 80%, 65%, 1)", wf.precip.getIntensity(inToMM(1))],
      ["hsla(235, 80%, 55%, 1)", wf.precip.getIntensity(inToMM(2))],
    ]),
  },
  {
    id: "rain-light-text",
    name: "Rain light (text)",
    type: "weather",
    fn: culoriInterpolate([
      ["hsla(220, 30%, 25%, 0)", wf.precip.getIntensity(inToMM(0))],
      ["hsla(220, 30%, 25%, 1)", wf.precip.getIntensity(inToMM(0.001))],
      ["hsla(235, 80%, 55%, 1)", wf.precip.getIntensity(inToMM(0.999))],
      ["hsla(215, 90%, 95%, 1)", wf.precip.getIntensity(inToMM(1))],
      ["hsla(215, 90%, 100%, 1)", wf.precip.getIntensity(inToMM(2))],
    ]),
  },
  {
    id: "rain-dark",
    name: "Rain dark",
    type: "weather",
    fn: culoriInterpolate([
      ["transparent", wf.precip.getIntensity(inToMM(0))],
      ["hsla(222, 41%, 8%, 0.1)", wf.precip.getIntensity(inToMM(0.001))],
      ["hsla(220, 90%, 45%, 1)", wf.precip.getIntensity(inToMM(2))],
    ]),
  },
  {
    id: "rain-dark-text",
    name: "Rain dark (text)",
    type: "weather",
    fn: culoriInterpolate([
      ["transparent", wf.precip.getIntensity(inToMM(0))],
      ["hsl(210, 60%, 90%)", wf.precip.getIntensity(inToMM(0.001))],
      ["hsl(0, 0%, 100%)", wf.precip.getIntensity(inToMM(2))],
    ]),
  },
  {
    id: "map-precip",
    name: "Map precip",
    type: "weather",
    fn: culoriInterpolate([
      ["hsla(270, 50%, 30%, 0)", wf.precip.getIntensity(inToMM(0))], // transparent purple
      ["hsl(220, 100%, 58%)", wf.precip.getIntensity(inToMM(0.25))], // blue
      ["hsl(200, 85%, 50%)", wf.precip.getIntensity(inToMM(0.5))], // cyan
      ["hsl(152, 77%, 39%)", wf.precip.getIntensity(inToMM(0.75))], // green
      ["hsl(85, 85%, 60%)", wf.precip.getIntensity(inToMM(1))], // lime
      ["hsl(55, 95%, 60%)", wf.precip.getIntensity(inToMM(1.5))], // gold
      ["hsl(30, 90%, 50%)", wf.precip.getIntensity(inToMM(2))], // orange
      ["hsl(4, 86%, 55%)", wf.precip.getIntensity(inToMM(3))], // red
      ["hsl(340, 86%, 58%)", wf.precip.getIntensity(inToMM(5))], // magenta
      ["hsl(320, 75%, 75%)", wf.precip.getIntensity(inToMM(7))], // pink
      ["hsl(0, 0%, 80%)", wf.precip.getIntensity(inToMM(10))], // gray
      ["hsl(0, 0%, 100%)", wf.precip.getIntensity(inToMM(15))], // white
    ]),
  },
  {
    id: "air-temp",
    name: "Air temp",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(0, 0%, 80%)", wf.temp.getIntensity(fToC(-10))], // gray
      ["hsl(0, 0%, 0%)", wf.temp.getIntensity(fToC(0))], // black
      ["hsl(280, 100%, 60%)", wf.temp.getIntensity(fToC(10))], // purple
      ["hsl(240, 80%, 60%)", wf.temp.getIntensity(fToC(20))], // indigo
      ["hsl(220, 100%, 58%)", wf.temp.getIntensity(fToC(30))], // blue
      ["hsl(200, 85%, 50%)", wf.temp.getIntensity(fToC(40))], // cyan
      ["hsl(152, 77%, 39%)", wf.temp.getIntensity(fToC(50))], // green
      ["hsl(55, 95%, 60%)", wf.temp.getIntensity(fToC(60))], // gold
      ["hsl(30, 90%, 50%)", wf.temp.getIntensity(fToC(70))], // orange
      ["hsl(4, 86%, 55%)", wf.temp.getIntensity(fToC(80))], // red
      ["hsl(340, 86%, 58%)", wf.temp.getIntensity(fToC(90))], // magenta
      ["hsl(320, 75%, 75%)", wf.temp.getIntensity(fToC(100))], // pink
      ["hsl(0, 0%, 100%)", wf.temp.getIntensity(fToC(110))], // white
    ]),
  },
  {
    id: "soil-temp",
    name: "Soil temp",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(220, 21%, 11%)", wf.temp.getIntensity(fToC(-15))], // black
      ["hsl(264, 95%, 32%)", wf.temp.getIntensity(fToC(10))], // deep blue
      ["hsl(202, 74%, 52%)", wf.temp.getIntensity(fToC(31.999))], // light blue
      ["hsl(42, 99%, 65%)", wf.temp.getIntensity(fToC(32))], // yellow
      ["hsl(35, 100%, 57%)", wf.temp.getIntensity(fToC(56))], // orange
      ["hsl(4, 86%, 58%)", wf.temp.getIntensity(fToC(80))], // red
    ]),
  },
  {
    id: "soil-temp-text",
    name: "Soil temp text",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(0, 0%, 100%)", wf.temp.getIntensity(fToC(-15))], // white
      ["hsl(0, 0%, 100%)", wf.temp.getIntensity(fToC(31.999))], // white
      ["hsl(20, 50%, 20%)", wf.temp.getIntensity(fToC(32))], // light brown
      ["hsl(0, 90%, 0%)", wf.temp.getIntensity(fToC(55.999))], // dark brown
      ["hsl(0, 0%, 100%)", wf.temp.getIntensity(fToC(56))], // white
      ["hsl(0, 0%, 100%)", wf.temp.getIntensity(fToC(80))], // white
    ]),
  },
  {
    id: "wind-light",
    name: "Wind light",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(143, 90%, 90%)", wf.wind.getIntensity(mphToMps(9.999))],
      ["hsl(42, 98%, 65%)", wf.wind.getIntensity(mphToMps(10))],
      ["hsl(4, 85%, 58%)", wf.wind.getIntensity(mphToMps(30))],
    ]),
  },
  {
    id: "wind-light-text",
    name: "Wind light text",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(143, 90%, 30%)", wf.wind.getIntensity(mphToMps(9.999))],
      ["hsl(20, 50%, 20%)", wf.wind.getIntensity(mphToMps(10))],
      ["hsl(0, 90%, 0%)", wf.wind.getIntensity(mphToMps(19.999))],
      ["hsl(0, 0%, 100%)", wf.wind.getIntensity(mphToMps(20))],
      ["hsl(0, 0%, 100%)", wf.wind.getIntensity(mphToMps(30))],
    ]),
  },
  {
    id: "wind-dark",
    name: "Wind dark",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(143, 90%, 15%)", wf.wind.getIntensity(mphToMps(9.999))],
      ["hsl(42, 98%, 65%)", wf.wind.getIntensity(mphToMps(10))],
      ["hsl(4, 85%, 58%)", wf.wind.getIntensity(mphToMps(30))],
    ]),
  },
  {
    id: "wind-dark-text",
    name: "Wind dark text",
    type: "weather",
    fn: culoriInterpolate([
      ["hsl(143, 90%, 80%)", wf.wind.getIntensity(mphToMps(9.999))],
      ["hsl(20, 50%, 20%)", wf.wind.getIntensity(mphToMps(10))],
      ["hsl(0, 90%, 0%)", wf.wind.getIntensity(mphToMps(19.999))],
      ["hsl(0, 0%, 100%)", wf.wind.getIntensity(mphToMps(20))],
      ["hsl(0, 0%, 100%)", wf.wind.getIntensity(mphToMps(30))],
    ]),
  }
);

// Built-in D3 color scales
// https://d3js.org/d3-scale-chromatic
ramps.push(
  {
    id: "viridis",
    name: "Viridis",
    type: "data",
    fn: (c) => rgb(interpolateViridis(c)),
  },
  {
    id: "turbo",
    name: "Turbo",
    type: "data",
    fn: (c) => rgb(interpolateTurbo(c)),
  },
  {
    id: "magma",
    name: "Magma",
    type: "data",
    fn: (c) => rgb(interpolateMagma(c)),
  },
  {
    id: "plasma",
    name: "Plasma",
    type: "data",
    fn: (c) => rgb(interpolatePlasma(c)),
  },
  {
    id: "warm",
    name: "Warm",
    type: "data",
    fn: (c) => rgb(interpolateWarm(c)),
  },
  {
    id: "cool",
    name: "Cool",
    type: "data",
    fn: (c) => rgb(interpolateCool(c)),
  },
  {
    id: "rainbow",
    name: "Rainbow",
    type: "data",
    fn: (c) => rgb(interpolateRainbow(c)),
  },
  {
    id: "sinebow",
    name: "Sinebow",
    type: "data",
    fn: (c) => rgb(interpolateSinebow(c)),
  },
  {
    id: "spectral",
    name: "Spectral",
    type: "data",
    fn: (c) => rgb(interpolateSpectral(c)),
  }
);

export const rgbaObjToCssString = ({
  r,
  g,
  b,
  alpha,
}: {
  r: number;
  g: number;
  b: number;
  alpha?: number;
}) => {
  const R = (r * 255).toFixed(0);
  const G = (g * 255).toFixed(0);
  const B = (b * 255).toFixed(0);
  const A = alpha === 0 ? 0 : (alpha || 1).toFixed(2);
  return `rgba(${R}, ${G}, ${B}, ${A})`;
};

// Generate stops for each ramp
const rampsWithStops = ramps.map((ramp) => {
  ramp.stops = {};

  for (const stop of standardStops) {
    const newStop = rgb(ramp.fn(stop / 1000));
    if (newStop === undefined) {
      console.warn("undefined color", ramp.id);
      return;
    }

    const r = (newStop.r * 255).toFixed(0);
    const g = (newStop.g * 255).toFixed(0);
    const b = (newStop.b * 255).toFixed(0);

    ramp.stops[stop] = {
      number: stop,
      obj: newStop,
      rgbaTriad: `${r}, ${g}, ${b}`,
      cssString: rgbaObjToCssString(newStop),
    };
  }
  return ramp;
});

// Export as array for ease of use
// Ie, colors[0]
export const colorsAsArray = rampsWithStops;

// Convert to object for ease of use
// Ie, colors.red.100
const colorsAsObject = colorsAsArray.reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {});

export default colorsAsObject;

// TODO
// - separate module?
// - require either isDarkMode or colorScale?
// - require either amount or intensity?
// - require precipType if factor is "precip"?
type GetDataColorsParams = {
  amount: number;
  factor: WeatherFactorId;
  precipType?: PrecipType;
  intensity?: number; // 0-1
  isDarkMode?: boolean;
  colorScale?: Ramp;
};

export function getDataColors({
  amount,
  factor = "precip",
  precipType = "rain", // TODO - different colors for snow?
  intensity,
  isDarkMode,
  colorScale,
}: GetDataColorsParams) {
  // Intensity represents how "strong" the value is on a scale between 0 and 1,
  // where 0 is the minimum value we expect of that weather factor and 1 is the maximum.
  // For example, if we expect wind speed to be between 0mph and 100mph,
  // a wind speed of 20mph would return an intensity of 0.2.
  if (!intensity && intensity !== 0) {
    const min =
      factor === "precip"
        ? wf.precip.min
        : factor === "temp"
        ? wf.temp.min
        : factor === "wind"
        ? wf.wind.min
        : 0;
    const max =
      factor === "precip"
        ? wf.precip.max
        : factor === "temp"
        ? wf.temp.max
        : factor === "wind"
        ? wf.wind.max
        : 10;
    const amountBetweenMinMax = Math.max(min, Math.min(max, amount));
    intensity = (amountBetweenMinMax - min) / (max - min);
  }

  const rampBackground =
    colorScale ||
    (factor === "temp"
      ? colorsAsObject["soil-temp"]
      : factor === "wind"
      ? isDarkMode
        ? colorsAsObject["wind-dark"]
        : colorsAsObject["wind-light"]
      : isDarkMode
      ? colorsAsObject["rain-dark"]
      : colorsAsObject["rain-light"]);
  const backgroundString = rgbaObjToCssString(
    rampBackground.fn(Math.min(intensity, 1))
  );

  const rampText =
    factor === "temp"
      ? colorsAsObject["soil-temp-text"]
      : factor === "wind"
      ? isDarkMode
        ? colorsAsObject["wind-dark-text"]
        : colorsAsObject["wind-light-text"]
      : isDarkMode
      ? colorsAsObject["rain-dark-text"]
      : colorsAsObject["rain-light-text"];
  const textString = rgbaObjToCssString(rampText.fn(Math.min(intensity, 1)));

  const backgroundColor =
    factor === "wind"
      ? backgroundString
      : !intensity
      ? "var(--background-primary)"
      : backgroundString;
  const textColor =
    factor === "wind"
      ? textString
      : !intensity
      ? "var(--text-primary)"
      : textString;

  return {
    backgroundColor,
    textColor,
    intensity,
  };
}
