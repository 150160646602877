import {
  CaretDownIcon,
  CaretSortIcon,
  CaretUpIcon,
  QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import Tooltip from "brand/components/Tooltip";

import styles from "./Table.module.css";

export function TH({
  sticky,
  centered,
  helperContent,
  sortable,
  ascending,
  descending,
  noWidth,
  fullWidth,
  onClick,
  children,
}: {
  sticky?: boolean;
  centered?: boolean;
  helperContent?: React.ReactNode;
  sortable?: boolean;
  ascending?: boolean;
  descending?: boolean;
  noWidth?: boolean;
  fullWidth?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}) {
  return (
    <th
      className={[
        sticky ? styles.tableHeaderSticky : "",
        sortable ? styles.tableHeaderSortable : "",
        ascending || descending ? styles.tableHeaderSorted : "",
      ].join(" ")}
      style={{ width: noWidth ? 0 : fullWidth ? "100%" : undefined }}
      onClick={onClick}
    >
      <div
        className={styles.helperWrapper}
        style={{
          justifyContent: centered ? "center" : undefined,
        }}
      >
        {children}
        {helperContent && (
          <Tooltip
            trigger={
              <button className={styles.helperIcon}>
                <QuestionMarkCircledIcon />
              </button>
            }
          >
            {helperContent}
          </Tooltip>
        )}
        {ascending ? (
          <div className={styles.sortIcon}>
            <CaretDownIcon />
          </div>
        ) : descending ? (
          <div className={styles.sortIcon}>
            <CaretUpIcon />
          </div>
        ) : sortable ? (
          <div
            className={styles.sortIcon}
            style={{
              width: ascending || descending ? "1rem" : "0",
            }}
          >
            <CaretSortIcon />
          </div>
        ) : null}
      </div>
    </th>
  );
}
