import { CaretLeftIcon, CaretRightIcon } from "@radix-ui/react-icons";
import { PrecipType } from "shared";

import styles from "./Calendar.module.css";
import { getDataColors } from "brand/scripts/colors";
import Button from "./Button";
import { Shimmer } from "./Loading";
import { PrecipTypeIconAnnotation } from "./PrecipTypeIcon";
import Tooltip from "./Tooltip";
import { useDarkMode } from "../hooks/useDarkMode";
import Blocker from "./Blocker";

type CalendarProps = {
  year: number;
  month: number;
  incrementMonth: (n: 1 | -1) => void;
  reset: () => void;
  days: DayProps[];
  error?: Error;
  isLoading?: boolean;
  onClickDay?: (day: DayProps) => void;
  thisMonthOnly?: boolean;
  onUpgrade?: () => void;
  fmtRain: (amount: number) => string;
  fmtSnowRange: (amount: number) => string;
  bonusContent?: React.ReactNode;
};

export type DayProps = {
  date?: Date;
  amount?: number;
  isLoading?: boolean;
  precipType?: PrecipType;
  monthName?: string;
  source?: "forecast" | "observation";
};

const Calendar = ({
  year,
  month,
  incrementMonth,
  reset,
  days: allDays,
  error,
  isLoading,
  onClickDay,
  thisMonthOnly,
  onUpgrade = () => {},
  fmtRain,
  fmtSnowRange,
  bonusContent,
}: CalendarProps) => {
  if (error) {
    return <div className="pad-6">Calendar couldn't load</div>;
  }

  const now = new Date();
  const isCurrentMonth = now.getMonth() === month && now.getFullYear() === year;
  const isFutureYear = now.getFullYear() > year;
  const isFutureMonth = isFutureYear || now.getMonth() < month;

  const monthName = new Date(year, month).toLocaleString("default", {
    month: "long",
  });

  // trim to only days in the specified month and year
  const days = allDays
    ?.filter((day) => {
      return day.date?.getMonth() === month && day.date?.getFullYear() === year;
    })
    .filter(
      (day) =>
        !thisMonthOnly ||
        (day.date?.getMonth() === now.getMonth() &&
          day.date?.getFullYear() === now.getFullYear())
    )
    .map((day) => {
      return {
        ...day,
        monthName,
        isLoading,
      } as DayProps;
    });

  // make sure we have a day for each day of the month
  // for ex, the API might not return an entry for a future day
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  for (let i = 1; i <= daysInMonth; i++) {
    const date = new Date(year, month, i);
    if (
      !days?.find((day) => day.date?.toDateString() === date.toDateString())
    ) {
      days?.push({ date, monthName, isLoading });
    }
  }

  // pad at the beginning and end with empty days
  const firstDay = days && days[0]?.date!.getDay();
  const lastDay = days && days[days.length - 1]?.date!.getDay();
  for (let i = 0; i < firstDay; i++) {
    days?.unshift({ isLoading });
  }
  for (let i = lastDay; i < 6; i++) {
    days?.push({ isLoading });
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Button tertiary onClick={() => incrementMonth(-1)}>
          <CaretLeftIcon />
        </Button>
        <div className="flex-col">
          <button onClick={reset}>
            <span className={styles.month}>{monthName}</span>
            <span className={styles.year}>{year}</span>
          </button>
          {bonusContent && <div>{bonusContent}</div>}
        </div>
        <Button tertiary onClick={() => incrementMonth(1)}>
          <CaretRightIcon />
        </Button>
      </div>
      <div className={styles.grid}>
        <div className={styles.weekdays}>
          <div className={styles.weekday}>Su</div>
          <div className={styles.weekday}>Mo</div>
          <div className={styles.weekday}>Tu</div>
          <div className={styles.weekday}>We</div>
          <div className={styles.weekday}>Th</div>
          <div className={styles.weekday}>Fr</div>
          <div className={styles.weekday}>Sa</div>
        </div>
        <div className={styles.days}>
          {days?.map((day, j) => {
            return (
              <CalendarDay
                key={j}
                day={day}
                onClickDay={onClickDay}
                fmtRain={fmtRain}
                fmtSnowRange={fmtSnowRange}
              />
            );
          })}
          {thisMonthOnly && !isCurrentMonth && !isFutureMonth && (
            <Blocker title="Get full rainfall history" onUpgrade={onUpgrade} />
          )}
        </div>
      </div>
    </div>
  );
};

export const CalendarDay = ({
  day,
  onClickDay,
  fmtRain,
  fmtSnowRange,
}: {
  day: DayProps;
  onClickDay?: (day: DayProps) => void;
  fmtRain: (amount: number) => string;
  fmtSnowRange: (amount: number) => string;
}) => {
  const { date, amount, isLoading, precipType, monthName, source } = day;

  const isToday = new Date().toDateString() === date?.toDateString();
  const isForecast = source === "forecast";
  const isFutureNoForecast = new Date() < date && !isForecast;

  const isDarkMode = useDarkMode();

  const { backgroundColor, textColor } = getDataColors({
    amount,
    // TODO - support wind etc here
    factor: "precip",
    precipType,
    isDarkMode,
  });

  const dayOfTheWeek = date?.toLocaleString("default", { weekday: "long" });

  const dayStyle = isToday
    ? styles.today
    : isFutureNoForecast
    ? styles.disabled
    : !day.date
    ? [styles.disabled]
    : "";

  const dayContent = (
    <div
      // unique key here to prevent automatic fill animations when changing months
      key={monthName + date?.getDate()}
      className={[styles.day, dayStyle].join(" ")}
      onClick={() => !isFutureNoForecast && onClickDay && onClickDay(day)}
      style={{
        background: backgroundColor,
        color: textColor,
        opacity: isToday
          ? 1
          : isLoading
          ? 0.3
          : isFutureNoForecast || isForecast
          ? 0.6
          : 1,
      }}
    >
      {isLoading && <Shimmer />}
      {isForecast && (
        <svg
          width="32"
          height="21"
          version="1.1"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            zIndex: 150,
            transform: "translate(-50%, -50%) scale(4)",
            opacity: isDarkMode ? 0.1 : 0.2,
          }}
        >
          <path
            d="M 6,-4 70,57"
            style={{
              stroke: "#ffffff",
              strokeWidth: 44,
              strokeDasharray: 1,
            }}
          />
        </svg>
      )}
      {amount > 0 && <PrecipTypeIconAnnotation precipType={precipType} />}
      <div className={styles.date}>{date ? date.getDate() : "・"}</div>
      <div className={styles.amount}>
        {!isLoading && amount >= 0 && !isFutureNoForecast ? (
          fmtRain(amount)
        ) : (
          <span style={{ opacity: 0 }}>-</span>
        )}
      </div>
    </div>
  );
  if (!date || isFutureNoForecast || isLoading) return dayContent;
  return (
    <Tooltip trigger={dayContent}>
      {dayOfTheWeek}, {monthName} {date.getDate()}
      <br />
      {amount === 0 ? (
        "No rain"
      ) : amount && !isFutureNoForecast ? (
        <>
          {precipType === "snow" ? (
            <div>
              {fmtSnowRange(amount)} of snow
              <br />({fmtRain(amount)} of rain equivalent)
            </div>
          ) : (
            <div>{fmtRain(amount)} of rain</div>
          )}
          ({source})
        </>
      ) : (
        ""
      )}
    </Tooltip>
  );
};

export default Calendar;
