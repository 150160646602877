// import mapboxgl from "mapbox-gl";
// import { useEffect, useRef } from "react";
// import { useDarkMode } from "brand/hooks/useDarkMode";

import { Link } from "react-router-dom";

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;
if (!MAPBOX_TOKEN) throw new Error("Missing Mapbox token");

const LocationMapThumb = ({
  coordinates,
}: {
  coordinates: [number, number];
}) => {
  return (
    <Link to={`/map?lng=${coordinates[0]}&lat=${coordinates[1]}&zoom=12`}>
      <img
        style={{ width: "100%", aspectRatio: "1/1", margin: "0 0 1rem" }}
        src={`https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/static/${coordinates[0]},${coordinates[1]},15,0,0/400x400?access_token=${MAPBOX_TOKEN}`}
        alt="map"
      />
    </Link>
  );

  // const mapContainer = useRef<HTMLDivElement>(null);
  // const mapRef = useRef<mapboxgl.Map>(null);
  // const isDarkMode = useDarkMode();

  // useEffect(() => {
  //   if (!mapContainer.current) return;
  //   mapRef.current = new mapboxgl.Map({
  //     container: mapContainer.current,
  //     style: "mapbox://styles/mapbox/satellite-streets-v12",
  //     center: coordinates,
  //     zoom: 13,
  //     interactive: false,
  //   });

  //   // TODO why is this necessary
  //   requestAnimationFrame(() => {
  //     // @ts-ignore no i just defined it
  //     mapRef.current.resize();
  //   });
  // }, [isDarkMode]);

  // return <div ref={mapContainer} style={{ height: "400px", width: "400px" }} />;
};

export default LocationMapThumb;
