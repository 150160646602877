// @ts-nocheck
// complicated since we have two maps
import mapboxgl from "mapbox-gl";
import React, { useEffect, useRef } from "react";

import "mapbox-gl/dist/mapbox-gl.css";

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;
if (!MAPBOX_TOKEN) throw new Error("Missing Mapbox token");
mapboxgl.accessToken = MAPBOX_TOKEN;

export const Map: React.FC = ({
  mapRef,
  layers,
  onClick,
}: {
  mapRef?: React.RefObject<mapboxgl.Map>;
  layers: mapboxgl.Layer[];
  onClick: (lngLat: mapboxgl.LngLatLike) => void;
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!mapContainer.current) return;

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-streets-v12",
      center: [-97.477, 39.899],
      zoom: 6,
    });

    mapRef.current.on("load", () => {
      layers.forEach((l) => {
        mapRef.current.addLayer(l);
      });
    });

    mapRef.current.on("click", (evt) => {
      onClick(evt.lngLat);
    });

    return () => {
      mapRef.current.remove();
      mapRef.current = undefined;
    };
  }, []);

  useEffect(() => {
    new ResizeObserver(() => {
      mapRef.current.resize();
    }).observe(mapContainer.current);
  });

  return (
    <div
      className="webkit-no-press-and-hold-to-copy"
      ref={mapContainer}
      style={{ height: "100%", width: "100%" }}
      data-test-id="map"
    />
  );
};
