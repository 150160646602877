import { PauseIcon, PlayIcon } from "@radix-ui/react-icons";
import { useEffect, useRef, useState } from "react";
import * as Slider from "@radix-ui/react-slider";
import { useResizeObserver } from "usehooks-ts";
import { scaleTime } from "d3-scale";
import { timeFormat } from "d3-time-format";
import { axisBottom } from "d3-axis";
import { select } from "d3-selection";

import styles from "./Timeline.module.css";
import Button from "./Button";

const scaleTextOffset = 14;

const durationHours = 6;
const now = new Date();
const later = new Date(now.getTime() + durationHours * 60 * 60 * 1000);

export default function Timeline({
  xAxisTimeFormat = "%I%p",
  start = now,
  end = later,
  onChange,
}: {
  // https://d3js.org/d3-time-format
  xAxisTimeFormat?: string;
  start?: Date;
  end?: Date;
  onChange?: (value: Date) => void;
}) {

  const [value, setValue] = useState<number[]>([0]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [scrubberIsFocused, setScrubberIsFocused] = useState(false);

  const height = 40;
  const chartRef = useRef<HTMLDivElement>(null);
  const axisRef = useRef<SVGGElement>(null);
  const { width } = useResizeObserver({
    ref: chartRef,
    box: "border-box",
  });

  // https://d3js.org/d3-time
  const scale = scaleTime().domain([start, end]).range([0, width]);

  // when playing, use requestAnimationFrame to update the value
  useEffect(() => {
    let rafId: number;
    if (isPlaying) {
      const update = () => {
        setValue((prev) => {
          const next = prev[0] + 1;
          if (next > width) return prev;
          return [next];
        });
        rafId = requestAnimationFrame(update);
      };
      update();
    }
    return () => cancelAnimationFrame(rafId);
  }, [isPlaying]);

  useEffect(() => {
    if (!scale?.invert) return;
    const toDate = scale.invert(value[0]);
    onChange && onChange(toDate);
  }, [value]);

  useEffect(() => {
    // clear old axis
    select(axisRef.current).selectAll("*").remove();
    select(axisRef.current)
      .attr("data-name", `axis`)
      .attr("width", width)
      .attr("height", height);

    let numTicks = durationHours;
    select(axisRef.current).call(
      axisBottom(scale).ticks(numTicks).tickFormat(timeFormat(xAxisTimeFormat))
    );

    // axis line style
    select(axisRef.current).select(".domain").attr("stroke", "transparent");

    // tick text style
    select(axisRef.current)
      .selectAll(".tick text")
      .attr("font-size", "var(--xs)")
      .attr("font-family", "inherit")
      .attr("fill", "var(--text-tertiary)")
      .attr("transform", `translate(0, ${scaleTextOffset})`)
      // lowercase AM/PM text
      .text((d) =>
        timeFormat(xAxisTimeFormat)(d).toLowerCase().replace("m", "")
      );

    // tick line style
    select(axisRef.current)
      .selectAll(".tick line")
      .attr("stroke", "var(--chart-line)")
      .attr("y2", scaleTextOffset);
  }, [width, height, scale, xAxisTimeFormat]);

  return (
    <form className={styles.wrapper}>
      <Button
        secondary
        onClick={() => {
          setIsPlaying(!isPlaying);
        }}
      >
        {isPlaying ? <PauseIcon /> : <PlayIcon />}
      </Button>
      <div style={{ flex: 1, position: "relative" }}>
        <Slider.Root
          className={styles.SliderRoot}
          value={value}
          onValueChange={(value) => {
            setValue(value);
          }}
          max={width}
          step={1}
        >
          <Slider.Track className={styles.SliderTrack}>
            <Slider.Range className={styles.SliderRange} />
          </Slider.Track>
          <Slider.Thumb
            className={styles.SliderThumb}
            aria-label="Time"
            onFocus={() => setScrubberIsFocused(true)}
            onBlur={() => setScrubberIsFocused(false)}
            // TODO - when dragging the slider, pause the animation
            // onMouseDown={() => setIsPlaying(false)}
          >
            <div
              className={[
                styles.SliderThumbValue,
                scrubberIsFocused || isPlaying
                  ? styles.SliderThumbValueVisible
                  : "",
              ].join(" ")}
            >
              {timeFormat("%I:%M%p")(scale.invert(value[0]))
                .toLowerCase()
                .replace("m", "")
                .replace(/^0/, "")}
            </div>
          </Slider.Thumb>
        </Slider.Root>
        {/* TODO - remove chart div wrapper */}
        <div className={styles.chartWrapper} ref={chartRef} style={{ height }}>
          <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            className={styles.svg}
          >
            <g ref={axisRef} />
          </svg>
        </div>
      </div>
    </form>
  );
}
