import mapboxgl from "mapbox-gl";
import { useEffect, useMemo, useRef, useState } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { createPortal } from "react-dom";
import { useQuery } from "@tanstack/react-query";
import { Helmet } from "react-helmet-async";
import { LayersIcon } from "@radix-ui/react-icons";

import {
  WeatherFactorId,
  coordinatesGeocoder,
  fmtRainM,
  fmtRainI,
  fmtSnowRangeM,
  fmtSnowRangeI,
  fmtSnowM,
  fmtSnowI,
  fmtTempM,
  fmtTempI,
  fmtWindM,
  fmtWindI,
  LocationFeature,
  useLocationLayers,
  MAX_FREE_LOCATIONS,
} from "shared";
import Layers, { MapLayer, mapLayers } from "brand/components/map/Layers";
import Legend from "brand/components/map/Legend";
import StatMarker from "brand/components/map/StatMarker";
import Preview from "brand/components/map/Preview";
import MarkerTypeToggle from "brand/components/map/MarkerTypeToggle";
import Crosshair from "brand/components/map/Crosshair";
import { useDarkMode } from "brand/hooks/useDarkMode";
import { mngr } from "../store";
import RawLayout from "../layouts/RawLayout";
// import { RainBadge } from "brand/components/Badge";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
// import AddLocation from "../components/AddLocation";
import { LockClosedIcon, PlusIcon } from "@radix-ui/react-icons";
import AddLocation from "../components/AddLocation";
import { Spinner } from "brand/components/Loading";
import { useMapStyles } from "brand/hooks/useMapStyles";
import { useMapPosition, Coords } from "brand/hooks/useMapPosition";
import { trackEvent } from "../tracking";
import useFeatureFlag from "brand/hooks/useFeatureFlag";
import Popover from "brand/components/Popover";
import Timeline from "brand/components/Timeline";

const WEATHER_API_URL = import.meta.env.VITE_APP_DATA_BASE_URL;
const MM_TO_INCH = 25.4;

const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_TOKEN;
if (!MAPBOX_TOKEN) throw new Error("Missing Mapbox token");
mapboxgl.accessToken = MAPBOX_TOKEN;

function formatPs(ps: number[], legendValuesPath: string): string[] {
  if (!legendValuesPath) return [];
  if (legendValuesPath.includes("sport-vsm-0-10cm-daily"))
    return ps.map((p) => `${Math.round(p * 100)}%`);
  if (legendValuesPath.includes("last-48"))
    return ps.map((p) => `${Math.round((p / MM_TO_INCH) * 10) / 10}"`);
  else return [];
}

function MapLegend({
  visible,
  darkMapColors,
  invertScale,
  legendValuesPath,
}: {
  visible: boolean;
  darkMapColors?: boolean;
  invertScale?: boolean;
  legendValuesPath?: string;
}) {
  const { data: colors, isLoading } = useQuery({
    queryKey: [legendValuesPath],
    queryFn: () =>
      fetch(`${WEATHER_API_URL}${legendValuesPath}`).then((r) => r.json()),
    staleTime: 60 * 1000 * 60 * 2, // 2 hours
    retry: true,
    enabled: !!legendValuesPath,
  });

  if (isLoading) return null;

  const ps = colors?.percentiles
    ? [
        colors["percentiles"][0],
        colors["percentiles"][5],
        colors["percentiles"][10],
      ]
    : [];

  const values = formatPs(ps, legendValuesPath);

  return (
    <div
      style={{
        position: "absolute",
        top: "10px",
        left: "305px",
        zIndex: "10",
      }}
    >
      <Legend
        visible={visible}
        values={values}
        darkMapColors={darkMapColors}
        invertScale={invertScale}
      />
    </div>
  );
}

function CrosshairMarker({
  location,
  onClick,
}: {
  location: [number, number];
  onClick: () => void;
}) {
  const mk = useMemo(() => {
    const el = document.createElement("div");
    const marker = new mapboxgl.Marker({ element: el, anchor: "center" })
      .setLngLat(location)
      .addTo(mngr.map as mapboxgl.Map);
    return marker;
  }, []);

  useEffect(() => {
    mk.setLngLat(location);
  }, [location, mk]);

  return createPortal(
    <div
      style={{
        width: "75px",
        height: "75px",
        cursor: "pointer",
      }}
      onClick={onClick}
    />,
    mk.getElement()
  );
}

function Marker({
  location,
  factor,
  showLocationPreview,
}: {
  location: LocationFeature;
  factor?: WeatherFactorId;
  showLocationPreview: (location: LocationFeature) => void;
}) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;
  const fmtSnowRange =
    preferredUnits === "metric" ? fmtSnowRangeM : fmtSnowRangeI;
  const fmtSnow = preferredUnits === "metric" ? fmtSnowM : fmtSnowI;
  const fmtTemp = preferredUnits === "metric" ? fmtTempM : fmtTempI;
  const fmtWind = preferredUnits === "metric" ? fmtWindM : fmtWindI;

  const { data, isLoading, error } = useLocationLayers(location);
  const { i: mapLoadProxy } = mngr.useMapLoad();
  const { flyTo } = useMapPosition();

  const mk = useMemo(() => {
    const el = document.createElement("div");
    const marker = new mapboxgl.Marker({ element: el, anchor: "bottom" })
      .setLngLat(location.geometry.coordinates)
      .addTo(mngr.map as mapboxgl.Map);
    return marker;
  }, [mapLoadProxy]);

  const amount =
    factor === "precip"
      ? +data?.last48?.properties?.precip
      : factor === "temp"
      ? +data?.soil?.properties?.soil_temperature_6cm
      : +data?.wind?.properties?.wind_speed_10m;
  const precipType = data?.last48?.properties?.precip_type;

  return createPortal(
    <StatMarker
      name={location.properties.name}
      factor={factor}
      precipType={precipType}
      noData={false}
      amount={amount}
      active={false}
      isLoading={isLoading}
      error={error}
      fmtRain={fmtRain}
      fmtSnow={fmtSnow}
      fmtSnowRange={fmtSnowRange}
      fmtTemp={fmtTemp}
      fmtWind={fmtWind}
      onClick={(evt) => {
        evt.stopPropagation();
        trackEvent("map_location_previewed");
        showLocationPreview(location);
        flyTo(location.geometry.coordinates as Coords);
      }}
    />,
    mk.getElement()
  );
}

function PreviewWrapper({ location }: { location: LocationFeature }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;
  const fmtSnowRange =
    preferredUnits === "metric" ? fmtSnowRangeM : fmtSnowRangeI;

  const {
    data: groups,
    loading: groupsLoading,
    error: groupsError,
  } = mngr.useGroups();
  const groupId = location.properties.groupId;

  const { isLoading, error, data } = useLocationLayers(location);

  const recentPrecipType = data?.recentRain?.properties?.precip_type || "rain";
  const recentAmount = isLoading
    ? "..."
    : recentPrecipType === "snow"
    ? fmtSnowRange(data?.recentRain?.properties?.precip)
    : fmtRain(data?.recentRain?.properties?.precip);
  const recentRainAgo = isLoading ? "" : data?.recentRain?.properties?.ago;

  return (
    <Preview
      error={error}
      linkUrl={`/location/${location.properties.id}`}
      title={location.properties.name}
      meta={
        groupsLoading ? (
          ""
        ) : groupsError ? (
          <div style={{ color: "var(--text-danger)" }}>
            Error loading groups
          </div>
        ) : (
          groups[groupId]?.name
        )
      }
      content={
        <>
          Last precip {recentAmount} {recentPrecipType} {recentRainAgo} ago
          {/* <RainBadge
            isLoading={isLoading}
            intensity={last48Inches / 1}
            amount={last48}
          />{" "}
          <span style={{ fontSize: "var(--s)" }}>past 48 hours</span> */}
        </>
      }
    />
  );
}

export default function Map() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const [locationToPreview, showLocationPreview] =
    useState<LocationFeature>(undefined);
  const [showLegend, setShowLegend] = useState(false);
  const [markerMode, setMarkerMode] = useState<WeatherFactorId>(
    "precip"
  );

  const { center, updatePositionState } = useMapPosition();

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  );
  const [isAdding, setIsAdding] = useState(params.adding === "true");
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const [layersModalOpen, setLayersModalOpen] = useState(false);

  const isDarkMode = useDarkMode();

  const { atLocationLimit } = mngr.useSubscription();

  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();

  const {
    darkMapColors,
    invertScale,
    baseLayer,
    setBaseLayer,
    rasterLayer,
    setRasterLayer,
    rasterLayerObj,
    currentStyle,
  } = useMapStyles({
    map: mngr.map,
    isAdding,
    defaultBaseLayer: "streets",
    defaultRasterLayer: null,
  });

  // the map listeners above should just work but they don't
  // we tried deleting it here but it randomly sometimes kept the map from having a center during add mode
  // https://github.com/getprecip/precip/commit/0c4f8e71b551213d074d056f8f0a9245b51fe6c1#diff-4c4ced8cce328e6d5dc91585089ca65a330c33120b56eff1e6101f8654f88dffL292
  useEffect(() => updatePositionState(), [isAdding]);

  useEffect(() => {
    if (mngr.map) return;

    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get("lng");
    const lat = urlParams.get("lat");
    const zoom = urlParams.get("zoom");

    mngr.map = new mapboxgl.Map({
      container: mapContainer.current,
      style: currentStyle,
      center: [lng ? +lng : -100, lat ? +lat : 40],
      zoom: zoom ? +zoom : 4,
    });

    mngr.map.on("load", () => {
      mngr.mapLoadProxy.i++;
    });

    mngr.map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        localGeocoder: coordinatesGeocoder,
        mapboxgl: mapboxgl,
        placeholder: "Search for an address",
        countries: "us",
        proximity: "ip",
        marker: false,
      }),
      "top-right"
    );

    mngr.map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    mngr.map.addControl(
      new mapboxgl.ScaleControl({
        maxWidth: 100,
        unit: "imperial",
      })
    );

    // this.container.className = `mapboxgl-ctrl mapboxgl-ctrl-group precip-mapboxgl-ctrl-group-horizontal`;
    // button.className = "precip-mapboxgl-ctrl-selected";
    // button.className = "precip-mapboxgl-ctrl-selected";

    // TODO why is this necessary
    requestAnimationFrame(() => {
      mngr.map?.resize();
    });

    return () => {
      mngr.map?.remove();
      mngr.map = undefined;
    };
  }, []);

  mngr.map?.on("click", () => {
    showLocationPreview(undefined);
  });

  const chooseLocation = () => {
    setIsAdding(false);
    setAddModalIsOpen(true);
  };

  const showSoilMoisture = useFeatureFlag("map_layer_soil_moisture");


  return (
    <RawLayout>
      <Helmet>
        <title>Precip | Map</title>
      </Helmet>

      {/* Markers and loading state */}
      {locationsLoading && (
        <div
          style={{
            position: "absolute",
            top: "30%",
            width: "100%",
            zIndex: "10",
          }}
        >
          <Spinner padded />
        </div>
      )}
      {locationsError && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10",
            color: "var(--text-danger)",
            background: "var(--background-primary)",
            padding: "1rem",
            borderRadius: "var(--brad-s)",
            textAlign: "center",
            pointerEvents: "none",
            opacity: 0.9,
            boxShadow: "var(--shadow-m)",
          }}
        >
          Sorry, we couldn't load your locations right now
          <div style={{ fontSize: "var(--s)" }}>
            Please try again in a few minutes
          </div>
        </div>
      )}
      <div>
        {!isAdding &&
          Object.values(locations || {}).map((l) => {
            return (
              <Marker
                key={l.properties.id}
                location={l}
                factor={markerMode}
                showLocationPreview={showLocationPreview}
              />
            );
          })}
      </div>

      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "260px",
          transform: isAdding ? "translateY(-10%) scaleY(0.9)" : "none",
          opacity: isAdding ? 0 : 1,
          transition: "all 0.1s ease-in-out",
          pointerEvents: isAdding ? "none" : "auto",
          zIndex: 200,
        }}
      >
        <Button
          map
          disabled={Boolean(locationsLoading) || Boolean(locationsError)}
          onClick={() => {
            if (atLocationLimit) {
              alert(
                `You have reached the limit of ${MAX_FREE_LOCATIONS} free location${
                  MAX_FREE_LOCATIONS === 1 ? "s" : ""
                }. Please contact us to learn about our unlimited plan.`
              );
              window.open("https://precip.ai/contact", "_blank");
            } else {
              showLocationPreview(undefined);
              setIsAdding(true);
            }
          }}
        >
          {atLocationLimit ? <LockClosedIcon /> : <PlusIcon />}
          Add
        </Button>
      </div>

      <div
        style={{
          position: "absolute",
          left: "10px",
          top: "10px",
          zIndex: 200,
        }}
      >
        <ButtonGroupWrapper>
          <Button
            map
            onClick={() => setBaseLayer("streets")}
            selected={baseLayer === "streets"}
          >
            Streets
          </Button>
          <Button
            map
            onClick={() => setBaseLayer("satellite")}
            selected={baseLayer === "satellite"}
          >
            Satellite
          </Button>
        </ButtonGroupWrapper>
      </div>

      <div
        style={{
          position: "absolute",
          left: "10px",
          top: "54px",
          transform: isAdding ? "translateY(-10%) scaleY(0.9)" : "none",
          opacity: isAdding ? 0 : 1,
          transition: "all 0.1s ease-in-out",
          pointerEvents: isAdding ? "none" : "auto",
          zIndex: 200,
        }}
      >
        <MarkerTypeToggle value={markerMode} onChange={setMarkerMode} />
      </div>

      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "185px",
          zIndex: 500,
        }}
      >
        <Popover
          isOpen={layersModalOpen}
          onOpenChange={setLayersModalOpen}
          arrow={false}
          side="bottom"
          close={false}
          align="start"
          noPadding
          minWidth={320}
          trigger={
            <Button map>
              <LayersIcon />
              Layers
            </Button>
          }
        >
          <div className="pad-1">
            <Layers
              currentLayer={rasterLayer}
              visible={[
                "last-48",
                ...(showSoilMoisture
                  ? [
                      "soil-moisture", // this is sport-vsm-0-10cm-daily
                      "last-12",
                      "last-24",
                      "last-168",  // 7 days
                      "td-year",
                      "td-month",
                      "td-year-pct",
                      "td-month-pct",
                      "hail-hourly",
                      "min2",
                      // TODO
                      // "wind-speed-hourly", // "hourly wind speed"
                      // "temperature-hourly", // "hourly temperature"
                      // "cloud-cover-hourly", // "cloud cover"
                      // "prism-normal-precip-daily", // "PRISM normal"
                    ]
                  : []),
              ]}
              onLayerChange={(layer?: MapLayer) => {
                setLayersModalOpen(false);
                setRasterLayer(layer?.id);
                setShowLegend(layer ? true : false);
                // TODO - save most recent layer for next time you open the map?
              }}
            />
          </div>
        </Popover>
      </div>

      {/*
        The crosshair in the middle is actually two elements.
        
        First, a div with a crosshair svg that never moves, positioned
        with css. It has pointer-events: none so it doesn't block clicks.
      */}
      <Crosshair hidden={!isAdding} darkMapColors={darkMapColors} />

      {/*
        Second, an invisible mapbox marker that can be tapped to add a
        new location. But unlike a normal div, it can also be dragged and the
        map will move behind it. However, it lags slightly behind when the map
        is dragged, so we use the fixed div above as the visual instead of this one.
      */}
      {center && <CrosshairMarker location={center} onClick={chooseLocation} />}

      <MapLegend
        visible={showLegend}
        darkMapColors={darkMapColors}
        invertScale={invertScale}
        legendValuesPath={
          // @ts-ignore
          mapLayers.find((l) => l.id === rasterLayer)?.legendUrl
        }
      />
      {!!rasterLayerObj?.timeRange?.start && (
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "540px",
            zIndex: "10",
            width: "400px",
          }}
        >
          <Timeline
            start={rasterLayerObj?.timeRange?.start}
            end={rasterLayerObj?.timeRange?.end}
            onChange={rasterLayerObj.updateTime}
            xAxisTimeFormat="%I:%M%p"
          />
        </div>
      )}

      <div
        className="flex-row align-center justify-between"
        style={{
          position: "absolute",
          bottom: "1rem",
          left: "50%",
          transform: !isAdding
            ? "translate(-50%, 10%) scaleY(0.9)"
            : "translate(-50%, 0)",
          opacity: !isAdding ? 0 : 1,
          transition: "all 0.1s ease-in-out",
          pointerEvents: !isAdding ? "none" : "auto",
          zIndex: 200,

          width: "100%",
          maxWidth: "500px",

          background: "var(--background-primary)",
          borderRadius: "var(--brad-s)",
          boxShadow: "var(--shadow-m)",
          padding: "1rem",
        }}
      >
        <div>
          <h4 className="margin-0">Drag map to your location</h4>
          {/* <div className="font-s">Line up the crosshair with your location</div> */}
        </div>
        <div>
          <Button tertiary onClick={() => setIsAdding(false)}>
            Cancel
          </Button>
          <Button primary onClick={chooseLocation}>
            Next
          </Button>
        </div>
      </div>

      <AddLocation
        isOpen={addModalIsOpen}
        setIsOpen={setAddModalIsOpen}
        geometry={{
          type: "Point",
          coordinates: center,
        }}
      />

      {!isAdding && locationToPreview && (
        <PreviewWrapper location={locationToPreview} />
      )}

      <div
        style={{
          position: "absolute",
          inset: 0,
          background: "var(--background-primary)",
          opacity: isDarkMode ? 0.6 : 0,
          mixBlendMode: "overlay",
          zIndex: 10,
          pointerEvents: "none",
        }}
      />

      <div
        ref={mapContainer}
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
        }}
      />
    </RawLayout>
  );
}
