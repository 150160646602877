// Layout helpers - just to debug padding issues

export default function Debug({
  width,
  height,
  top,
  right,
  bottom,
  left,
  hide,
}: {
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
  hide?: boolean;
}) {
  if (hide) return null;
  return (
    <g
      style={{
        pointerEvents: "none",
      }}
    >
      <rect fill="hsla(0, 100%, 50%, .2)" width={width} height={top} />
      <rect fill="hsla(300, 100%, 50%, .2)" width={left} height={height} />
      <rect
        fill="hsla(25, 100%, 50%, .2)"
        width={right}
        height={height}
        transform={`translate(${width - right}, 0)`}
      />
      <rect
        fill="hsla(200, 100%, 50%, .2)"
        width={width}
        height={bottom}
        transform={`translate(0, ${height - bottom})`}
      />
    </g>
  );
}
