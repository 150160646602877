import { useState } from "react";
import { Helmet } from "react-helmet-async";

import RawLayout from "../../layouts/RawLayout";
import Button from "brand/components/Button";

import {
  signInWithGoogle,
  signInWithApple,
  requestLinkForEmailLinkSignIn,
} from "../../Auth";
import styles from "./SignUp.module.css";
import { trackEvent } from "../../tracking";
// import Background from "brand/components/Background";
// import Card from "brand/components/Card";

export default function SignUp() {
  // TODO - check for auth and automatically redirect to dashboard if already signed in

  const [showSignInWithEmail, setShowSignInWithEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [isRequestingEmailLink, setIsRequestingEmailLink] = useState(false);

  return (
    <RawLayout hideNav>
      <Helmet>
        <title>Precip | Create account</title>
      </Helmet>

      <div className="flex-row align-stretch flex-1">
        <div className="flex-col align-center gap-2 flex-1">
          <div
            className="pad-4 flex-col gap-4 align-center justify-center flex-1"
            style={{
              width: "100%",
              maxWidth: "360px",
            }}
          >
            <h2>Create your account</h2>
            <div
              className="flex-col flex-center gap-2"
              style={{
                width: "100%",
              }}
            >
              <Button
                authProvider="google"
                secondary
                round
                fullWidth
                onClick={() => {
                  signInWithGoogle().then(() => {
                    trackEvent("user_signed_up");
                    window.location.href = "/";
                  });
                }}
              >
                Continue with Google
              </Button>
              <Button
                authProvider="apple"
                secondary
                round
                fullWidth
                onClick={() => {
                  signInWithApple().then(() => {
                    trackEvent("user_signed_up");
                    window.location.href = "/";
                  });
                }}
              >
                Continue with Apple
              </Button>
            </div>

            {!showSignInWithEmail ? (
              <Button
                tertiary
                round
                fullWidth
                onClick={() => {
                  setShowSignInWithEmail(true);
                }}
              >
                or sign up with email
              </Button>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setIsRequestingEmailLink(true);
                  requestLinkForEmailLinkSignIn({ email }).then(({ error }) => {
                    setIsRequestingEmailLink(false);
                    if (error) {
                      // Common errors: invalid email, invalid or expired OTP
                      // TODO - show in UI, not an alert
                      alert(
                        `Sorry, we could not get a sign in link for you right now. (${error.message} - ${error.code})`
                      );
                    } else {
                      alert(
                        `We've sent a link to ${email}. Please check your email to sign in.`
                      );
                    }
                  });
                }}
              >
                <hr />
                <label style={{ width: "100%", textAlign: "left" }}>
                  Your email
                </label>
                <input
                  autoFocus
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%", margin: "0 0 .5rem" }}
                />
                <Button
                  type="submit"
                  authProvider="email"
                  secondary
                  round
                  fullWidth
                  disabled={isRequestingEmailLink}
                >
                  Request email link
                </Button>
              </form>
            )}
            <p
              style={{
                fontSize: "var(--s)",
              }}
            >
              Already have an account? <a href="/sign-in">Sign in</a>
            </p>
          </div>
          <footer className={styles.footer}>
            &copy; {new Date().getFullYear()}{" "}
            <a href="https://precip.ai/" target="_blank">
              Precipitation Inc.
            </a>
          </footer>
        </div>
        {/* TODO - welcome illustration here */}
        {/* <div
          className="flex-1 flex-col align-center justify-center position-relative"
          style={{ width: "50%" }}
        >
          <div className="flex-col align-center justify-center">
            <Card primary>Test</Card>
          </div>

          <Background dots />
        </div> */}
      </div>
    </RawLayout>
  );
}
