import { forecastDays, keyBy, LocationFeature } from "shared";

const dtfmt = (d) => d.toISOString().slice(0, 10);

export type SortByOptions =
  | "name"
  | "group"
  | "rain_amount"
  | "last_rain"
  | number
  | "wind_speed"
  | "wind_timeline";

export default function sortLocations(
  locations: LocationFeature[],
  sortBy: SortByOptions,
  sortAscending: boolean,
  // @ts-ignore
  weatherData: any
) {
  return locations
    .sort((a, b) => {
      if (sortBy === "name") {
        if (sortAscending) {
          return a.properties.name.localeCompare(b.properties.name);
        } else {
          return b.properties.name.localeCompare(a.properties.name);
        }
      } else if (sortBy === "group") {
        if (sortAscending) {
          // empty groups at the bottom
          if (a.properties.groupId === "" || a.properties.groupId === null)
            return 1;
          if (b.properties.groupId === "" || b.properties.groupId === null)
            return -1;
          return (a.properties.groupId || "").localeCompare(
            b.properties.groupId || ""
          );
        } else {
          if (a.properties.groupId === "" || a.properties.groupId === null)
            return -1;
          if (b.properties.groupId === "" || b.properties.groupId === null)
            return 1;
          return (b.properties.groupId || "").localeCompare(
            a.properties.groupId || ""
          );
        }
      } else if (sortBy === "last_rain") {
        const aWeather = weatherData[a.properties.id];
        const bWeather = weatherData[b.properties.id];
        if (!sortAscending) {
          return (
            (aWeather?.recentRain?.properties?.precip || 0) -
            (bWeather?.recentRain?.properties?.precip || 0)
          );
        } else {
          return (
            (bWeather?.recentRain?.properties?.precip || 0) -
            (aWeather?.recentRain?.properties?.precip || 0)
          );
        }
      } else if (sortBy === "rain_amount") {
        const aWeather = weatherData[a.properties.id];
        const bWeather = weatherData[b.properties.id];
        if (!sortAscending) {
          return (
            (aWeather?.last48?.properties?.precip || 0) -
            (bWeather?.last48?.properties?.precip || 0)
          );
        } else {
          return (
            (bWeather?.last48?.properties?.precip || 0) -
            (aWeather?.last48?.properties?.precip || 0)
          );
        }
      } else if (
        typeof sortBy === "number" &&
        sortBy >= 0 &&
        forecastDays()[sortBy]
      ) {
        const dtstr = dtfmt(forecastDays()[sortBy].date);

        const aWeather = weatherData[a.properties.id];
        const bWeather = weatherData[b.properties.id];

        const byDayA = keyBy(aWeather?.next16?.properties?.days, (d) =>
          d["startTime"].slice(0, 10)
        );
        const byDayB = keyBy(bWeather?.next16?.properties?.days, (d) =>
          d["startTime"].slice(0, 10)
        );

        if (!sortAscending) {
          return (
            (byDayA?.[dtstr]?.precip || 0) - (byDayB?.[dtstr]?.precip || 0)
          );
        } else {
          return (
            (byDayB?.[dtstr]?.precip || 0) - (byDayA?.[dtstr]?.precip || 0)
          );
        }
      } else if (sortBy === "wind_speed") {
        const aWeather = weatherData[a.properties.id];
        const bWeather = weatherData[b.properties.id];
        if (!sortAscending) {
          return (
            (aWeather?.wind?.properties?.wind_speed_10m || 0) -
            (bWeather?.wind?.properties?.wind_speed_10m || 0)
          );
        } else {
          return (
            (bWeather?.wind?.properties?.wind_speed_10m || 0) -
            (aWeather?.wind?.properties?.wind_speed_10m || 0)
          );
        }
      }
    })
    .sort((a, b) => {
      const pinnedA = a.properties.isPinned;
      const pinnedB = b.properties.isPinned;
      if (pinnedA && !pinnedB) return -1;
      if (!pinnedA && pinnedB) return 1;
      return 0;
    });
}
