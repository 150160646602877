// @ts-ignore
import * as Lerc from "lerc";
import mapboxgl, { AnySourceData } from "mapbox-gl";
import { scaleLinear } from "d3-scale";
import * as webglColorize from "./webglColorize";
import colors from "brand/scripts/colors";
import { range } from "d3-array";
import { MapLayer } from "brand/components/map/Layers";

const WEATHER_API_URL = import.meta.env.VITE_APP_DATA_BASE_URL;

/*

  layer API:
  * set color scale {
    min, max, name, // eventually stops
  }
  * set date range / chosen date
  * set available date range
  * get stats (eg quantiles)

*/

const renderer = new webglColorize.Renderer();
renderer.setColorScale(makeColorScaleImg());

function makeColorScaleImg() {
  // https://www.weather.gov/lox/rainrate
  // const rainrate = [0.05, 0.25, 0.33, 0.5, 0.75, 1, 1.5].map((mm) => mm * 25.4);

  // const percentiles_48hr = [
  //   0.29999995, 0.60000014, 1.0999999, 1.9000001, 3.0, 4.5999999, 6.80000019,
  //   10.10000038, 15.10000038, 24.10000038, 59.60000229,
  // ].map((p) => p / 2);

  // https://mrms.nssl.noaa.gov/qvs/product_viewer/
  const mrms_viewer = [
    0.01, 0.05, 0.1, 0.15, 0.2, 0.4, 0.6, 0.8, 1, 1.25, 1.5, 1.75, 2, 2.5, 3,
    3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 8,
  ];

  const stops = mrms_viewer;
  const colorFunc0to1 = colors["map-precip"].fn; // maps a value 0-1 to a color

  // this range includes the top and the bottom.
  const scale = scaleLinear()
    .domain(stops)
    .range(range(stops.length).map((v) => v / (stops.length - 1)));

  const min = stops[0];
  const max = stops[stops.length - 1];
  const opacityScale = scaleLinear().domain([0, 1]).range([0.5, 0.8]);

  // this is scaled to 0-255 -> colors
  const colorScaleArr = new Uint8ClampedArray(256 * 4);
  for (let i = 0; i < 256; i++) {
    const value = min + (i * (max - min)) / 255;
    const scaled = scale(value);
    const { r, g, b, alpha: opacity } = colorFunc0to1(scaled);
    colorScaleArr[i * 4] = r * 255;
    colorScaleArr[i * 4 + 1] = g * 255;
    colorScaleArr[i * 4 + 2] = b * 255;
    colorScaleArr[i * 4 + 3] = (opacity || opacityScale(scaled)) * 255;
  }
  return {
    arr: colorScaleArr,
    min,
    max,
  };
}

export function makeCanvasGL({ height, width, pixels, mask }, timeIndex) {
  if (timeIndex >= pixels.length) {
    console.log("TOO MUCH", timeIndex, pixels);
    return;
  }

  if (renderer.canvas.width !== width || renderer.canvas.height !== height)
    console.log(
      "SIZE MISMATCH",
      width,
      height,
      renderer.canvas.width,
      renderer.canvas.height
    );

  renderer.render(pixels[timeIndex], {
    width,
    height,
  });
  // how much faster would it be to skip offscreen?, this can be `HTMLCanvasElement`, `HTMLImageElement`, `ImageData`, `ImageBitmap` or object with `width`, `height`, and `data`
  return renderer.canvas.transferToImageBitmap();
}

async function loadTile(tile, layerId, startTs, endTs, signal) {
  const arrayBuffer = await fetch(
    `${WEATHER_API_URL}/api/v1/map/${layerId}/ImageServer/tile/${tile.z}/${tile.y}/${tile.x}?time=${startTs},${endTs}&format=lerc`,
    { signal }
  ).then((r) => r.arrayBuffer());

  return Lerc.decode(arrayBuffer, {
    // returnInterleaved: true,
  });
}

// implements mapboxgl.CustomSourceInterface<ImageData>
class ImageServerSource {
  id = "precip-custom-source";
  // @ts-ignore
  type = "custom";
  // @ts-ignore
  dataType = "image";

  // TODO check these
  tileSize = 256;
  minzoom = 7;
  maxzoom = 7;

  cache = {};
  timeIndex = 0;

  layerId: string;
  startTs: number;
  endTs: number;

  constructor(layerId, startTs, endTs) {
    this.layerId = layerId;
    this.startTs = startTs;
    this.endTs = endTs;

    /* @vite-ignore */
    const wasmBinaryFile = new URL( "/node_modules/lerc/lerc-wasm.wasm", import.meta.url ).toString();
    Lerc.load({ locateFile: () => wasmBinaryFile });
  }

  // @ts-ignore
  async loadTile(tile: any, { signal }: { signal: mapboxgl.AbortSignal }) {
    let pixelBlock;
    if (!this.cache[`${tile.z}/${tile.y}/${tile.x}`]) {
      // pixelBlock = await loadTile(
      //   tile,
      //   this.layerId,
      //   this.startTs,
      //   this.endTs,
      //   signal
      // );
      const promise = loadTile(tile, this.layerId, this.startTs, this.endTs, signal);
      this.cache[`${tile.z}/${tile.y}/${tile.x}`] = {
        status: "loading",
        promise: promise,
      };
      promise.then((data) => { 
        this.cache[`${tile.z}/${tile.y}/${tile.x}`] = {
          status: "loaded",
          data: data,
        };
      });
    }
    // else {
    //   pixelBlock = this.cache[`${tile.z}/${tile.y}/${tile.x}`];
    // }

    // TODO can you await promise multiple times?
    if (this.cache[`${tile.z}/${tile.y}/${tile.x}`].status === "loading") {
      pixelBlock = await this.cache[`${tile.z}/${tile.y}/${tile.x}`].promise;
      // this.cache[`${tile.z}/${tile.y}/${tile.x}`] = {
      //   status: "loaded",
      //   promise: pixelBlock,
      // };
    } else {
      pixelBlock = this.cache[`${tile.z}/${tile.y}/${tile.x}`].data;
    }


    // TODO separate the loading, creation of canvas, rewriting time
    const { height, width, pixels, mask, statistics } = pixelBlock;
    // console.log("loadTile", `${this.timeIndex}/${tile.x}/${tile.y}/${tile.z}`, )
    const imageData = makeCanvasGL(
      { height, width, pixels, mask },
      this.timeIndex
    );

    return imageData;
  }
}

// const { startTime, endTime, startTs, endTs } = makeTimeParams();
// Peer dependencies
// import dateFns from 'https://cdn.jsdelivr.net/npm/date-fns@3.6.0/+esm'
// import reactDateRange from 'https://cdn.jsdelivr.net/npm/react-date-range@2.0.1/+esm';
// import {Calendar} from 'https://cdn.jsdelivr.net/npm/react-date-range@2.0.1/+esm';
// http://localhost:3002/demo-map-slider?startTime=2024-06-28T05:00:15.186Z&endTime=2024-06-28T06:00:15.186Z&layer=min2

export function addLayerRasterAnimated(map: mapboxgl.Map, layerObj: MapLayer) {
  const { start, end } = layerObj?.getDefaultTimeRange();

  // TODO move these into the same class, then fix the Timeline component in Map.tsx to use it
  layerObj.timeRange = { start, end };
  layerObj.updateTime = (value: Date) => {
    const startTime = layerObj.timeRange.start;
    let counter = 0;
    // TODO use class interval here
    if (layerObj.id === "hourly") {
      counter = Math.floor(
        (value.getTime() - startTime.getTime()) / (1000 * 60 * 60)
      );
    } else if (layerObj.id === "min2") {
      counter = Math.floor(
        (value.getTime() - startTime.getTime()) / (1000 * 60 * 2)
      );
    }

    // @ts-ignore
    const cstmSrc = mngr.map.getSource("precip-custom-source")?._implementation;
    if (!cstmSrc) return;
    if (cstmSrc.timeIndex === counter) return;

    cstmSrc.timeIndex = counter;
    cstmSrc.update();
  };

  const startTs = start?.getTime();
  const endTs = end?.getTime();
  const layerId = layerObj.id;
  const cstmSrc = new ImageServerSource(layerId, startTs, endTs);

  if (map.getSource("precip-custom-source")) return;
  map.addSource("precip-custom-source", cstmSrc as AnySourceData);
  map.addLayer({
    id: layerObj.id,
    type: "raster",
    source: "precip-custom-source",
    paint: {
      "raster-fade-duration": 0,
    },
  });
  // TODO map.setPaintProperty("satellite", "raster-brightness-max", 0.5);
}

export function removeLayerRasterAnimated(
  map: mapboxgl.Map,
  layerObj: MapLayer
) {
  map.removeLayer(layerObj.id);
  map.removeSource("precip-custom-source");
}
