import { mngr } from "../../store";
import { UnitsSystem, unitsSystems } from "shared";
import { set as setLocalStorage } from "../../LocalStorage";

export default function Units() {
  const { preferredUnits } = mngr.useUnits();

  return (
    <>
      <h3>Units</h3>
      <label>Preferred units</label>
      <select
        value={preferredUnits || "imperial"}
        onChange={(e) => {
          const choice = e.target.value as UnitsSystem;
          setLocalStorage("preferred_units_system", choice);
          mngr.setPreferredUnits(choice);
        }}
      >
        {Object.keys(unitsSystems).map((u) => {
          const system = unitsSystems[u];
          return (
            <option key={u} value={u}>
              {system.label}
            </option>
          );
        })}
      </select>
    </>
  );
}
