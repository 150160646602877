type Redirects = {
  from: string;
  to: string;
};

const redirects: Redirects[] = [
  {
    from: "/signup",
    to: "/sign-in",
  },
  {
    from: "/signin",
    to: "/sign-in",
  },
  {
    from: "/login",
    to: "/log-in",
  },
  {
    from: "/logout",
    to: "/log-out",
  },
];

export default redirects;
