import Card from "brand/components/Card";
import BlockChart from "brand/components/chart/BlockChart";

import {
  useLocationDaily,
  fmtRainUnitM,
  fmtRainUnitI,
  mmToIn,
  inToMM,
} from "shared";
import { Spinner } from "brand/components/Loading";
import { useDarkMode } from "brand/hooks/useDarkMode";
import colors from "brand/scripts/colors";
import { ErrorState } from "brand/components/EmptyState";
import { mngr } from "../store";

export default function LocationPageDaily({ location }) {
  const isDarkMode = useDarkMode();

  const { preferredUnits } = mngr.useUnits();
  const fmtRainUnit = preferredUnits === "metric" ? fmtRainUnitM : fmtRainUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : inToMM;

  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location);

  const dataFormattedDaily = dataDaily?.properties?.days?.map(
    ({ startTime, precip, source }) => {
      return {
        value: toConvertedUnits(precip),
        date: new Date(startTime),
        source,
      };
    }
  );

  return (
    <Card>
      <h4>Daily precipitation</h4>
      {isLoadingDaily ? (
        <Spinner padded />
      ) : errorDaily ? (
        <ErrorState error={errorDaily} />
      ) : (
        <BlockChart
          xAxisTimeFormat="%a %b %d %Y"
          unitFormatter={fmtRainUnit}
          toOriginalUnits={toOriginalUnits}
          data={dataFormattedDaily.filter((d) => d.source === "observation")}
          colorScale={isDarkMode ? colors["rain-dark"] : colors["rain-light"]}
        />
      )}
    </Card>
  );
}
