import { useEffect } from "react";

import Button from "brand/components/Button";
// import Card from "brand/components/Card";
import DropdownMenu, { DropdownMenuItem } from "brand/components/DropdownMenu";
import tableStyles from "brand/components/Table.module.css";
import Avatar from "brand/components/Avatar";
import Select from "brand/components/Select";
import { TrashIcon } from "@radix-ui/react-icons";
import { mngr } from "../../store";
import { MemberBackend } from "shared";
import { Spinner } from "brand/components/Loading";
import EmptyState, { ErrorState } from "brand/components/EmptyState";
import { trackEvent } from "../../tracking";

// function Invitations() {
//   const [isInviting, setIsInviting] = useState(false);
//   const loading = false;
//   return (
//     <>
//       <h3>Invitations</h3>
//       {loading ? (
//         <Spinner padded />
//       ) : false ? (
//         <EmptyState text="No outstanding invitations" />
//       ) : (
//         <table
//           className={`${tableStyles.table} ${tableStyles.padded} ${tableStyles.lines}`}
//         >
//           <thead>
//             <tr>
//               <th>Email</th>
//               <th>Role</th>
//               <th>Sent</th>
//               <th style={{ width: 0 }} />
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>baby@blue.com</td>
//               <td>Admin</td>
//               <td>January 15, 2024</td>
//               <td>
//                 <DropdownMenu label="Member actions">
//                   <DropdownMenuItem
//                     destructive
//                     onSelect={() =>
//                       alert("Invitation for baby@blue.com has been canceled")
//                     }
//                   >
//                     <TrashIcon />
//                     Cancel invitation
//                   </DropdownMenuItem>
//                 </DropdownMenu>
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       )}
//       <br />
//       {!isInviting && (
//         <Button secondary onClick={() => setIsInviting(true)}>
//           Invite member
//         </Button>
//       )}
//       {isInviting && (
//         <Card>
//           <form
//             onSubmit={(e) => {
//               e.preventDefault();
//               setIsInviting(false);
//               alert("We sent your invitation to jane@doe.com");
//             }}
//           >
//             <label htmlFor="invitation-email">Email</label>
//             <input type="email" id="invitation-email" />
//             <label htmlFor="invitation-role">Role</label>

//             <Select
//               value={`admin`}
//               id="invitation-role"
//               options={[
//                 { value: "admin", label: "Admin" },
//                 {
//                   value: "member",
//                   label: "Member",
//                 },
//               ]}
//               onChange={() => alert("Change member role")}
//             />

//             <br />
//             <br />
//             <Button primary type="submit">
//               Invite
//             </Button>
//             <Button tertiary onClick={() => setIsInviting(false)}>
//               Cancel
//             </Button>
//           </form>
//         </Card>
//       )}
//     </>
//   );
// }

function Members() {
  const {
    data: membersObj,
    loading: membersLoading,
    error: membersError,
  } = mngr.useMembers();
  const orgId = mngr.useOrgId();
  useEffect(() => {
    mngr.fetchMembers();
  }, [orgId]);

  const members = Object.values(membersObj || {});
  const { data: org, loading: orgLoading, error: orgError } = mngr.useOrg();
  const orgName = org?.orgName;

  const isLoading = membersLoading || orgLoading;
  const error = membersError || orgError;

  if (error) {
    return (
      <ErrorState
        title={`Sorry, we couldn't load this organization's members right now`}
        subtext={`Please try again in a minute`}
      />
    );
  }
  return (
    <>
      <h3>Members</h3>
      {isLoading ? (
        <Spinner padded />
      ) : members.length === 0 ? (
        <EmptyState text="No members yet" />
      ) : (
        <table
          className={`${tableStyles.table} ${tableStyles.padded} ${tableStyles.lines}`}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              {/* <th>Joined</th> */}
              <th>Role</th>
              <th style={{ width: 0 }} />
            </tr>
          </thead>
          <tbody>
            {members.map(
              ({ id, userEmail, createdAt, role, userDisplayName }) => {
                // TODO - bring back dates when we have invites and real join dates.
                // Ie, show date they actually joined, not the date the "invite" was sent.
                // const createdAtDate = new Date(createdAt);
                // const createdAtString = createdAtDate.toLocaleDateString();

                return (
                  <tr key={id} className="sentry-mask">
                    {/* TODO get name */}
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        <Avatar name={userDisplayName} />
                        {userDisplayName}
                      </div>
                    </td>

                    <td>{userEmail}</td>
                    {/* <td>{createdAtString}</td> */}
                    <td>
                      <Select
                        value={role}
                        options={["admin", "editor", "viewer"].map((r) => ({
                          value: r,
                          label: r[0]?.toUpperCase() + r.slice(1),
                        }))}
                        onChange={(v) => {
                          trackEvent("member_access_changed");
                          mngr.updateMemberRole(id, v as MemberBackend["role"]);
                        }}
                      />
                    </td>
                    <td>
                      <DropdownMenu label="Member actions" align="end">
                        <DropdownMenuItem
                          destructive
                          disabled={userEmail === mngr.backendUser?.user?.email}
                          onSelect={() => {
                            if (
                              window.confirm(
                                `Are you sure you want to remove ${userEmail} from ${orgName}? They will no longer have access to any of your locations.`
                              )
                            ) {
                              trackEvent("member_removed");
                              mngr.deleteMember(id);
                            }
                          }}
                        >
                          <TrashIcon />
                          Remove
                        </DropdownMenuItem>
                      </DropdownMenu>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      )}
      <br />

      <Button
        secondary
        onClick={async () => {
          const email = prompt("Enter the email of the person you want to add");
          if (!email || email === "") return;
          trackEvent("member_added");
          const newMember = await mngr.addMember(email, "admin");
          if (newMember) {
            alert(
              `Member added! Next time they log in as ${email}, they'll be able to access the ${orgName} account.`
            );
          }
        }}
      >
        Add member
      </Button>

      <br />
      <br />

      {/* <Invitations /> */}
    </>
  );
}

export default Members;
