import { useEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { timeFormat } from "d3-time-format";
import { scaleLinear, scaleBand } from "d3-scale";
import { select } from "d3-selection";

import colors, {
  rgbaObjToCssString,
  getDataColors,
} from "brand/scripts/colors";
import NowLine from "./NowLine";
import ChartTooltip from "./ChartTooltip";
import XAxis from "./XAxis";
import Debug from "./Debug";
import YAxis from "./YAxis";
import { isNil } from "shared";
import { Spinner } from "../Loading";

type DataType = {
  date: Date;
  value: number;
  source: "observation" | "forecast";
}[];

type ChartProps = {
  xAxisLabel?: string;
  yAxisLabel?: string;

  // force y axis to show 0% to 100% for ex, even when the data only goes to 40%
  yAxisBounds?: [number, number];
  yUpperBoundMinimum?: number;

  // https://d3js.org/d3-time-format
  xAxisTimeFormat?: string;
  xAxisTickFrequency?: string;

  nowLine?: boolean;
  showRules?: boolean;
  noPad?: boolean;
  unitFormatter?: (value: number) => string;
  toOriginalUnits: (value: number) => number;
  data: DataType;
  colorScale?: any;
  height?: number;
  spark?: boolean;
  fullHeightMode?: boolean;
  hideTooltip?: boolean;
  isLoading?: boolean;
};

export default function BarChart({
  xAxisLabel = "",
  yAxisLabel = "",
  yAxisBounds,
  yUpperBoundMinimum,
  showRules,
  noPad,
  xAxisTimeFormat = "%m/%d/%Y",
  xAxisTickFrequency,
  nowLine = false,
  unitFormatter = (number: number) => String(number.toFixed(2)),
  toOriginalUnits = (number: number) => number,
  data = [],
  colorScale = colors["blue"],
  height = 300,
  spark,
  fullHeightMode,
  hideTooltip = false,
  isLoading = false,
}: ChartProps) {
  if (spark) height = 30;

  const chartRef = useRef<HTMLDivElement>(null);
  const plotRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const barRef = useRef<SVGGElement>(null);

  const { width: chartWidth = 200, height: chartHeight = height } =
    useResizeObserver({
      ref: chartRef,
      box: "border-box",
    });

  const [tooltipContent, setTooltipContent] = useState({
    top: 0,
    left: 0,
    isVisible: false,
    content: <></>,
  });

  const Y_AXIS_WIDTH = spark ? 0 : !yAxisLabel ? 26 : 40;
  const X_AXIS_HEIGHT = spark ? 0 : 40;
  const PAD_TOP = spark ? 0 : 20;
  const PAD_RIGHT = spark || noPad ? 2 : 20;
  const now = new Date();

  const colorPrimary = rgbaObjToCssString(colorScale.fn(0.6));
  const colorSecondary = rgbaObjToCssString(colorScale.fn(0.3));

  const items = data
    ?.reduce((acc, item) => {
      return [
        ...acc,
        {
          date: item.date,
          source: item.source,
          value: item.value,
        },
      ];
    }, [] as DataType)
    .filter((d) => !isNil(d.value));

  // https://observablehq.com/@d3/d3-scaleband
  // https://d3js.org/d3-scale/band
  // https://observablehq.com/@bchoatejr/date-scaleband
  const xScale = scaleBand()
    // @ts-ignore
    .domain(items?.map((d) => d.date))
    .range([0, chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)])
    .padding(spark ? 0 : 0.25);
  // .rangeRound([0, chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)]);

  const yMax = Math.max(...items?.map((d) => d.value), yUpperBoundMinimum || 0);

  const yScale = scaleLinear()
    .domain(yAxisBounds || [0, yMax * 1.05])
    .range([chartHeight - (X_AXIS_HEIGHT + PAD_TOP), 0]);

  useEffect(() => {
    select(barRef.current).selectAll("*").remove();
    select(barRef.current)
      .append("g")
      .attr("transform", `translate(${Y_AXIS_WIDTH}, ${PAD_TOP})`)
      .selectAll("rect")
      .data(items)
      .join("rect")
      // @ts-ignore
      .attr("x", (d) => xScale(d.date))
      .attr("y", (d) => (fullHeightMode ? 0 : yScale(d.value)))
      .attr("height", (d) => {
        // don't try on first render when chartHeight is 0,
        // we'll get a bunch of negative heights and console errors
        if (chartHeight === 0) return 0;
        if (fullHeightMode) return chartHeight - X_AXIS_HEIGHT - PAD_TOP;
        return chartHeight - X_AXIS_HEIGHT - PAD_TOP - yScale(d.value);
      })
      .attr("width", xScale.bandwidth())
      .attr("stroke", "var(--background-primary)")
      .attr("stroke-width", spark ? 1 : 0)
      .attr("fill", (d) => {
        if (!fullHeightMode) {
          return d.source === "forecast" ? colorSecondary : colorPrimary;
        }
        const { backgroundColor } = getDataColors({
          amount: toOriginalUnits(d.value),
          // TODO - pass factor into chart component
          factor: "wind",
          colorScale,
        });
        return backgroundColor;
      })
      .on("mouseover", (event) => {
        setTooltipContent({ ...tooltipContent, isVisible: !hideTooltip });
        select(event.target).attr("opacity", hideTooltip ? 1 : 0.5);
      })
      .on("mousemove", (event, d) => {
        const { layerX, layerY } = event;
        // @ts-ignore
        const date = timeFormat(xAxisTimeFormat)(d.date);
        // @ts-ignore
        const value = d?.value ?? 0;

        setTooltipContent({
          top: layerY,
          left: layerX,
          content: (
            <>
              <div
                style={{
                  fontSize: "var(--xs)",
                  color: "var(--text-secondary)",
                }}
              >
                {date}
              </div>
              <div
                style={{
                  fontSize: "var(--s)",
                  color: "var(--text-primary)",
                }}
              >
                {unitFormatter(value)}
              </div>
            </>
          ),
          isVisible: !hideTooltip,
        });
      })
      .on("mouseout", (event) => {
        select(event.target).attr("opacity", 1);
        setTooltipContent({ ...tooltipContent, isVisible: false });
      });
  }, [unitFormatter, chartWidth, chartHeight, X_AXIS_HEIGHT, items]);

  const closestItemToNow = items?.reduce((acc, item) => {
    if (item.date > now) return acc;
    if (!acc) return item;
    if (
      now.getTime() - item.date.getTime() <
      now.getTime() - acc.date.getTime()
    )
      return item;
    return acc;
  }, null);
  // @ts-ignore
  const nowDistance = xScale(closestItemToNow?.date) + Y_AXIS_WIDTH;

  return (
    <div ref={chartRef} style={{ height, width: "100%", position: "relative" }}>
      <ChartTooltip
        top={tooltipContent.top}
        height={chartHeight - X_AXIS_HEIGHT - PAD_TOP}
        left={tooltipContent.left}
        maxDistance={chartWidth}
        isVisible={tooltipContent.isVisible}
        content={tooltipContent.content}
        dotValue={null}
        dotColor={colorPrimary}
        hideLine
        boxy
      />

      <div
        ref={plotRef}
        style={{
          position: "absolute",
          top: PAD_TOP,
          left: Y_AXIS_WIDTH,
          right: PAD_RIGHT,
          bottom: X_AXIS_HEIGHT,
          zIndex: 300,

          // only until we have real bar chart tooltips,
          // then we should remove the crappy title tag hovers,
          // remove this pointerEvents style, and do it for real
          pointerEvents: "none",
        }}
      />

      {isLoading && (
        <div
          className="position-absolute"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner padded />
        </div>
      )}

      <svg
        ref={svgRef}
        width={chartWidth}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        style={{
          position: "relative",
          zIndex: 100,
        }}
      >
        {/* Careful with ordering SVG elements, it determines z-index! */}

        {!spark && (
          <YAxis
            xPos={0}
            yPos={PAD_TOP}
            width={Y_AXIS_WIDTH}
            height={chartHeight - X_AXIS_HEIGHT}
            scale={yScale}
            label={yAxisLabel}
            showRules={!isLoading && showRules}
            fullWidth={chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)}
          />
        )}

        <g ref={barRef} />

        {!spark && (
          <XAxis
            xPos={Y_AXIS_WIDTH}
            yPos={chartHeight - X_AXIS_HEIGHT}
            width={chartWidth}
            height={X_AXIS_HEIGHT}
            scale={xScale}
            format={xAxisTimeFormat}
            label={xAxisLabel}
            tickFrequency={xAxisTickFrequency}
          />
        )}

        {nowLine && (
          <NowLine
            nowDistance={nowDistance}
            maxDistance={chartWidth}
            height={chartHeight - X_AXIS_HEIGHT}
          />
        )}

        <Debug
          width={chartWidth}
          height={chartHeight}
          top={PAD_TOP}
          right={PAD_RIGHT}
          bottom={X_AXIS_HEIGHT}
          left={Y_AXIS_WIDTH}
          hide
        />
      </svg>
    </div>
  );
}
