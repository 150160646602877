import * as RadixPopover from "@radix-ui/react-popover";
import { Cross2Icon } from "@radix-ui/react-icons";

import styles from "./Popover.module.css";

export default function Popover({
  isOpen,
  onOpenChange,
  trigger,
  side = "top",
  align = "center",
  close = true,
  arrow = true,
  autoWidth = false,
  minWidth,
  noPadding = false,
  children,
}: {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  trigger: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  align?: "start" | "center" | "end";
  close?: boolean;
  arrow?: boolean;
  autoWidth?: boolean;
  minWidth?: number;
  noPadding?: boolean;
  children: React.ReactNode;
}) {
  return (
    <RadixPopover.Root open={isOpen} onOpenChange={onOpenChange}>
      <RadixPopover.Trigger asChild>{trigger}</RadixPopover.Trigger>
      <RadixPopover.Portal>
        <RadixPopover.Content
          style={{
            width: !autoWidth && "260px",
            padding: noPadding && 0,
            minWidth,
          }}
          className={styles.PopoverContent}
          sideOffset={5}
          side={side}
          align={align}
        >
          {close && (
            <RadixPopover.Close
              className={styles.PopoverClose}
              aria-label="Close"
            >
              <Cross2Icon />
            </RadixPopover.Close>
          )}
          {arrow && <RadixPopover.Arrow className={styles.PopoverArrow} />}
          {children}
        </RadixPopover.Content>
      </RadixPopover.Portal>
    </RadixPopover.Root>
  );
}
