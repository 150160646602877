import { useState } from "react";
import { DownloadIcon } from "@radix-ui/react-icons";

import { LocationFeature } from "shared";
import Button from "brand/components/Button";
import { DateRangePicker } from "brand/components/DatePicker";
import Modal from "brand/components/Modal";
import Select from "brand/components/Select";

const WEATHER_API_URL = import.meta.env.VITE_APP_DATA_BASE_URL;

export default function Export({ location }: { location: LocationFeature }) {
  const formats = [
    { label: "CSV", value: "csv" },
    { label: "JSON", value: "json" },
    { label: "GeoJSON", value: "geojson" },
  ];
  const [currentFormat, setCurrentFormat] = useState(formats[0]);
  const [isOpen, setIsOpen] = useState(false);

  const today: Date = new Date();
  const oneYearAgo: Date = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
  const [startDate, setStartDate] = useState<Date>(oneYearAgo);
  const [endDate, setEndDate] = useState<Date>(today);

  return (
    <>
      <Button secondary onClick={() => setIsOpen(true)} disabled={!location}>
        <DownloadIcon />
        Export
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        size="small"
        title="Data export"
        description="Download weather data and use it in other tools"
      >
        <form
          className="flex-col gap-6"
          onSubmit={() => {
            const start = startDate.toISOString().split("T")[0]; // 2024-01-04
            const end = endDate.toISOString().split("T")[0]; // 2024-02-04
            const [longitude, latitude] = location?.geometry?.coordinates || [
              0, 0,
            ];
            const url =
              `${WEATHER_API_URL}/api/v1/daily?` +
              new URLSearchParams({
                longitude: String(longitude),
                latitude: String(latitude),
                start,
                end,
                format: currentFormat.value,
                // TODO - move key to env vars or whatever
                apiKey: "zpka_0b7a88ccf2254a3dab9a4fb7039b6b45_361969c9",
              });
            window.open(url, "_blank");
          }}
        >
          <div>
            <label>Format</label>
            <Select
              value={currentFormat.value}
              fullWidth
              onChange={(value) => {
                setCurrentFormat(formats.find((f) => f.value === value));
              }}
              options={formats}
            />
          </div>
          <div className="flex-col">
            <label>Date range</label>
            <DateRangePicker
              value1={startDate}
              value2={endDate}
              onChange1={setStartDate}
              onChange2={setEndDate}
            />
          </div>
          <div className="flex-row justify-end align-center pad-0 gap-2">
            <Button secondary onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button primary type="submit">
              <DownloadIcon />
              Download {currentFormat.label}
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
}
