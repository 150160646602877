import { useEffect, useRef } from "react";

import { timeDay } from "d3-time";
import { timeFormat } from "d3-time-format";
import { axisBottom } from "d3-axis";
import { select } from "d3-selection";
import { scaleTime } from "d3-scale";

export default function XAxis({
  xPos,
  yPos,
  width,
  height,
  scale,
  format,
  label,
  tickFrequency,
}: {
  xPos: number;
  yPos: number;
  width: number;
  height: number;
  scale: any;
  format: string;
  label: string;
  tickFrequency?: string;
}) {
  const groupRef = useRef<SVGGElement>(null);
  const axisRef = useRef<SVGGElement>(null);
  const labelRef = useRef<SVGGElement>(null);
  const isBarChart = scale.bandwidth;

  useEffect(() => {
    // position group
    select(groupRef.current)
      .attr("data-name", `group`)
      .attr("transform", `translate(${xPos},${yPos})`)
      .attr("width", width)
      .attr("height", height);

    // clear old axis
    select(axisRef.current).selectAll("*").remove();
    select(axisRef.current).attr("data-name", `axis`);

    // create ticks
    // https://webkid.io/blog/responsive-chart-usability-d3/#prevent-overlapping-of-x-axis-labels
    if (tickFrequency === "daily" && isBarChart) {
      // band scales produce ticks for each bar, so rejig
      const newScale = scaleTime().range(scale.range()).domain(scale.domain());
      const tickValues = newScale.ticks(timeDay);
      select(axisRef.current).call(
        axisBottom(scale).tickValues(tickValues).tickFormat(timeFormat(format))
      );
    } else if (tickFrequency === "daily") {
      select(axisRef.current).call(
        axisBottom(scale).ticks(timeDay).tickFormat(timeFormat(format))
      );
    } else if (isBarChart) {
      const numTicks = scale.domain().length;
      const xModulo =
        numTicks > 100 ? 30 : numTicks > 50 ? 10 : numTicks > 20 ? 5 : 2;
      const tickValues = scale.domain().filter((_, i) => i % xModulo === 0);
      select(axisRef.current).call(
        axisBottom(scale).tickValues(tickValues).tickFormat(timeFormat(format))
      );
    } else {
      const ticks = scale.ticks();
      let numTicks = ticks.length > 20 ? 12 : ticks.length;
      if (width < 600) {
        numTicks = ticks.length > 11 ? 6 : ticks.length;
      }
      select(axisRef.current).call(
        axisBottom(scale).ticks(numTicks).tickFormat(timeFormat(format))
      );
    }

    // axis line style
    select(axisRef.current)
      .select(".domain")
      .attr("stroke", "var(--chart-axis)");

    // tick text style
    select(axisRef.current)
      .selectAll(".tick text")
      .attr("font-size", "var(--xs)")
      .attr("font-family", "inherit");

    // tick line style
    select(axisRef.current)
      .selectAll(".tick line")
      .attr("stroke", "var(--chart-axis)");

    // clear old label
    select(labelRef.current).selectAll("*").remove();
    select(labelRef.current).attr("data-name", `label`);

    // create text label
    select(labelRef.current)
      .append("text")
      .attr("x", width / 2)
      .attr("y", 36)
      .style("text-anchor", "middle")
      .text(label)
      .attr("font-size", "var(--xs)")
      .attr("font-family", "inherit")
      .attr("fill", "var(--chart-axis)");
  }, [xPos, yPos, width, height, scale, format]);

  return (
    <g ref={groupRef}>
      <g ref={axisRef} />
      <g ref={labelRef} />
    </g>
  );
}
