import { useNavigate, useParams } from "react-router-dom";
import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";
import { ThermometerSimple, Wind } from "@phosphor-icons/react";
import { Helmet } from "react-helmet-async";

import DropdownMenu, { DropdownMenuItem } from "brand/components/DropdownMenu";
import PageLayout from "../layouts/PageLayout";
import LocationMapThumb from "../components/LocationMapThumb";
import { mngr } from "../store";
import {
  useLocationLayers,
  fmtWindM,
  fmtWindI,
  fmtTempM,
  fmtTempI,
} from "shared";
import Breadcrumbs from "brand/components/Breadcrumbs";
import ErrorPage from "./ErrorPage";
import { Placeholder } from "brand/components/Loading";
import styles from "./Location.module.css";
import NoAuthPage from "./NoAuthPage";
import Export from "../components/Export";
import { trackEvent } from "../tracking";
import LocationPageCalendar from "./LocationPageCalendar";
// import LocationPageNormals from "./LocationPageNormals";
import LocationPageWind from "./LocationPageWind";
import LocationPageTemp from "./LocationPageTemp";
import LocationPageRecent from "./LocationPageRecent";
import LocationPageHistory from "./LocationPageHistory";
import LocationPageDaily from "./LocationPageDaily";
import LocationPageHourly from "./LocationPageHourly";
import LocationPageStats from "./LocationPageStats";
import useFeatureFlag from "brand/hooks/useFeatureFlag";

export default function LocationWrapped() {
  const { locationId } = useParams();
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();
  const location = locations?.[locationId];
  if (locationsError) return <ErrorPage error={locationsError} />;
  if (!location) return <NoAuthPage />;
  return <Location location={location} locationsLoading={locationsLoading} />;
}

function Location({ location, locationsLoading }) {
  const navigate = useNavigate();

  const { preferredUnits } = mngr.useUnits();
  const fmtWind = preferredUnits === "metric" ? fmtWindM : fmtWindI;
  const fmtTemp = preferredUnits === "metric" ? fmtTempM : fmtTempI;

  const {
    data: layerData,
    // TODO
    // isLoading: isLoadingLayer,
    // error: errorLayer,
  } = useLocationLayers(location);

  const name = location?.properties?.name;
  const group = mngr.groups?.[location?.properties?.groupId]?.name;

  const pageName = locationsLoading ? "..." : name || "Unnamed location";

  const showWindTemp = useFeatureFlag("wind_temp_charts");

  return (
    <PageLayout>
      <Helmet>
        <title>Precip | {pageName}</title>
      </Helmet>

      <div className={styles.columns}>
        <div>
          <div
            className="flex-col border-bottom w100"
            style={{
              padding: "2vh 0 1rem",
              margin: "0 0 2rem",
            }}
          >
            <Breadcrumbs
              pages={[
                { name: "Locations", url: "/locations" },
                { name: pageName },
              ]}
            />
            <div className="flex-row gap-4 justify-between align-end">
              <div className="flex-row gap-4 align-base">
                <h1
                  style={{
                    margin: 0,
                    lineHeight: 1,
                  }}
                >
                  {locationsLoading ? (
                    <Placeholder
                      style={{
                        width: "12rem",
                        height: "2rem",
                      }}
                    />
                  ) : (
                    name || (
                      <span style={{ color: "var(--text-disabled)" }}>
                        Unnamed location
                      </span>
                    )
                  )}
                </h1>
                <div
                  className="flex-row gap-3 align-base"
                  style={{ lineHeight: 1, fontSize: "var(--s)" }}
                >
                  {group && (
                    <span
                      style={{
                        color: "var(--text-tertiary)",
                        margin: "0 .5rem 0 0",
                      }}
                    >
                      {group}
                    </span>
                  )}
                  <div className="flex-row gap-1 align-base">
                    <Wind />
                    {fmtWind(layerData?.wind?.properties?.wind_speed_10m)} wind
                  </div>
                  <div className="flex-row gap-1 align-base">
                    <ThermometerSimple />
                    {fmtTemp(
                      layerData?.soil?.properties?.soil_temperature_6cm
                    )}{" "}
                    ground temp
                  </div>
                </div>
              </div>
              <div>
                <DropdownMenu label="Location options" align="end">
                  <DropdownMenuItem
                    onSelect={() => {
                      const name = prompt(
                        "Enter a new name for this location",
                        location.properties.name
                      );
                      if (!name || name === "") return;
                      trackEvent("location_renamed");
                      mngr.changeLocationName(location.properties.id, name);
                    }}
                  >
                    <Pencil1Icon />
                    Edit name
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    destructive
                    onSelect={() => {
                      if (
                        confirm(
                          "Are you sure you want to delete this location?"
                        )
                      ) {
                        trackEvent("location_removed");
                        mngr.deleteLocation(location.properties.id);
                        // TODO - fix?
                        navigate("/");
                      }
                    }}
                  >
                    <TrashIcon />
                    Delete location
                  </DropdownMenuItem>
                </DropdownMenu>{" "}
                <Export location={location} />
              </div>
            </div>
          </div>

          <LocationPageStats location={location} />
          <LocationPageHourly location={location} />
          {showWindTemp && (
            <>
              <LocationPageWind location={location} />
              <LocationPageTemp location={location} />
              <LocationPageDaily location={location} />
            </>
          )}
          <LocationPageRecent location={location} />
          <LocationPageHistory location={location} />
        </div>
        <div>
          <LocationMapThumb coordinates={location.geometry.coordinates} />
          <LocationPageCalendar location={location} />
          {/* <LocationPageNormals location={location} /> */}
        </div>
      </div>
    </PageLayout>
  );
}
