import { useQuery } from "@tanstack/react-query";
import { LocationFeature } from "./state-manager";
import { PrecipType, mapValues } from "shared";

// This is not the url of the database,
// it's the base url of the data API
// TODO - rename to something more clear probably
// TODO - move back to live data source
export const WEATHER_API_URL =
  // @ts-ignore
  import.meta.env?.VITE_APP_DATA_BASE_URL || "https://tiles.precip.ai";
export const STAGE_WEATHER_API_URL =
  "https://precip-api-dev-kxe64thdqq-uc.a.run.app";

// TODO - breaks the location list page for some reason though
// TODO - make this take a type for the response (DayProperties etc)
// https://stackoverflow.com/a/54166010/1061063

export function usePrecipData({
  host = WEATHER_API_URL,
  endpoint,
  lon,
  lat,
  start,
  end,
  model,
  enabled = true,
}: // foreCastToday,
{
  host?: string;
  endpoint: Endpoint;
  lon?: string | number;
  lat?: string | number;
  start?: Date;
  end?: Date;
  model?: Model | Model[];
  enabled?: boolean;
  // foreCastToday?: boolean;
}) {
  const startParam = start?.toISOString();
  const endParam = end?.toISOString();

  // Don't use the actual ISO string for the key,
  // the seconds update too often and nothing gets cached.
  // Limit the keys to only the year, month, and day.
  const startKey = start?.toDateString();
  const endKey = end?.toDateString();

  return useQuery({
    queryKey: [endpoint, lon, lat, startKey, endKey, model],
    queryFn: () => {
      if (!lon || !lat) {
        return { type: "FeatureCollection", features: [] };
      }
      let url = `${host}/api/v1/${endpoint}?longitude=${lon}&latitude=${lat}`;
      if (start) url += `&start=${startParam}`;
      if (end) url += `&end=${endParam}`;
      if (model) {
        if (Array.isArray(model)) {
          url += `&model=${model.join(",")}`;
        } else {
          url += `&model=${model}`;
        }
      }
      // if (foreCastToday) url += `&foreCastToday=${foreCastToday}`;
      // default format=geojson and timezone=local
      return fetch(url).then((res) => res.json());
    },
    staleTime: 60 * 1000 * 10, // 10 minutes
    retry: 4,
    enabled: !!endpoint && !!enabled,
  });
}

/*
 * TYPES
 */

export interface LocationData {
  next16?: LocationFeature<{
    days: {
      time: string; // [];
      precipitation_sum: number; // [];
      precipitation_probability_max: number; // [];
    }[];
  }>;
  recentRain?: LocationFeature<{
    precip: number;
    // TODO - need in API?
    precip_type: PrecipType;
    ago: string;
    endTime: string;
  }>;
  last24?: LocationFeature<{
    precip: number;
    // TODO - need in API?
    precip_type: PrecipType;
  }>;
  last48?: LocationFeature<{
    precip: number;
    // TODO - need in API?
    precip_type: PrecipType;
  }>;
  last168?: LocationFeature<{
    precip: number;
    // TODO - need in API?
    precip_type: PrecipType;
  }>;
  soil?: LocationFeature<{
    soil_temperature_0cm: number;
    soil_temperature_6cm: number;
    soil_moisture_0_to_1cm: number;
    soil_moisture_1_to_3cm: number;
    soil_moisture_3_to_9cm: number;
  }>;
  wind?: LocationFeature<{
    wind_speed_10m: number;
    wind_gusts_10m: number;
  }>;
  hourly?: LocationFeature<{
    time: string[];
    precip: number[];
  }>;
}

export interface LocationLayers {
  isLoading: boolean;
  isLoadingByLayer: Record<string, boolean>;
  data?: LocationData;
  error: Error | undefined;
}

export interface LocationsLayers {
  isLoading: boolean;
  isLoadingByLayer: Record<string, boolean>;
  data: Record<string, LocationData>;
  error: Error | undefined;
}

type DayProperties = {
  days: {
    startTime: string; // "2023-02-16T12:00:00+0000",
    precip: number; // 12.5,
    precip_probability?: number; // null,
    precip_type?: "snow" | "rain"; // null,
    source?: "observation" | "forecast"; // "observation",
    temperature_min?: number; // null,
    temperature_max?: number; // null,
    wind_speed_max?: number; // null,
    wind_speed_gust_max?: number; // null
  }[];
};

export type Endpoint =
  | "current"
  | `last-12`
  | `last-24`
  | `last-48`
  | `last-168`
  | `td-year`
  | `td-month`
  | `td-year-pct` // year to date as percent of normal (US only)
  | `td-month-pct` // month to date as percent of normal (US only)
  | "recent-rain"
  | "hourly"
  | "hourly"
  | "daily";

export type ApiResultKey =
  | "last12"
  | "last24"
  | "last48"
  | "last168"
  | "tdYear"
  | "tdMonth"
  | "tdYearPct"
  | "tdMonthPct"
  | "recentRain"
  | "hourly"
  | "hourly"
  | "daily";

type Model =
  | "last-24"
  | "last-48"
  | "last-78"
  | "soil"
  | "wind"
  | "recent-rain"
  | "temperature"
  | "wind-speed"
  | "wind-direction"
  | "wind-speed-gust"
  | "cloud-cover"
  | "prism-normal-precip"
  | "sport-vsm-0-10cm";

/*
 * MULTIPLE LOCATIONS
 */

export function useLocationsLayers(): LocationsLayers {
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
    // TODO - don't pull mngr from the global namespace
    // @ts-ignore
  } = mngr.useLocations();
  const ll = Object.values(locations || {}) as LocationFeature[];
  const lon = ll
    .map((l: LocationFeature) => l.geometry.coordinates[0])
    .join(",");
  const lat = ll
    .map((l: LocationFeature) => l.geometry.coordinates[1])
    .join(",");

  const {
    data: last48,
    isLoading: last48Loading,
    error: last48Error,
  } = usePrecipData({ endpoint: "last-48", lon, lat });

  const {
    data: soil,
    isLoading: soilLoading,
    error: soilError,
  } = usePrecipData({ endpoint: "current", lon, lat, model: "soil" });

  const {
    data: wind,
    isLoading: windLoading,
    error: windError,
  } = usePrecipData({ endpoint: "current", lon, lat, model: "wind" });

  const {
    data: recentRain,
    isLoading: recentRainLoading,
    error: recentRainError,
  } = usePrecipData({ endpoint: "recent-rain", lon, lat });

  const start = new Date();
  // don't get forecasts for today until we fix the today API
  start.setDate(start.getDate() + 1);

  const end = new Date();
  end.setDate(end.getDate() + 12);

  const {
    data: next16,
    isLoading: next16Loading,
    error: next16Error,
  } = usePrecipData({
    endpoint: "daily",
    lon,
    lat,
    start,
    end,
    // foreCastToday: true,
  });

  return {
    isLoading:
      locationsLoading ||
      last48Loading ||
      next16Loading ||
      recentRainLoading ||
      soilLoading ||
      windLoading,
    isLoadingByLayer: {
      next16: next16Loading,
      recentRain: recentRainLoading,
      last48: last48Loading,
      soil: soilLoading,
      wind: windLoading,
    },
    data: mapValues(locations, (location: LocationFeature) => {
      const idx = ll
        .map((l: LocationFeature) => l.properties.id)
        .indexOf(location.properties.id);
      return {
        next16: next16?.features?.[idx], // time, precipitation_sum and precipitation_probability_max are arrays
        recentRain: recentRain?.features?.[idx], // precip and ago
        last48: last48?.features?.[idx], // precip
        soil: soil?.features?.[idx], // soil_temperature_0cm soil_temperature_6cm soil_moisture_0_to_1cm soil_moisture_1_to_3cm soil_moisture_3_to_9cm
        wind: wind?.features?.[idx], // wind_speed_10m wind_gusts_10m
      };
    }),
    error:
      last48Error ||
      soilError ||
      windError ||
      recentRainError ||
      next16Error ||
      locationsError,
  };
}

export function useLocationsLayersLast(
  endpointId: Endpoint,
  apiResultKey: ApiResultKey
): LocationsLayers {
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
    // TODO - don't pull mngr from the global namespace
    // @ts-ignore
  } = mngr.useLocations();
  const ll = Object.values(locations || {});
  const lon = ll.map((l: any) => l.geometry.coordinates[0]).join(",");
  const lat = ll.map((l: any) => l.geometry.coordinates[1]).join(",");

  const {
    data: lastData,
    isLoading: lastLoading,
    error: lastError,
  } = usePrecipData({
    host: WEATHER_API_URL,
    endpoint: endpointId,
    lon,
    lat,
  });

  return {
    isLoading: locationsLoading || lastLoading,
    isLoadingByLayer: {
      [apiResultKey]: lastLoading,
    },
    data: mapValues(locations, (location: LocationFeature) => {
      const idx = ll
        .map((l: any) => l.properties.id)
        .indexOf(location.properties.id);
      return {
        [apiResultKey]: lastData?.features?.[idx],
      };
    }),
    error: lastError || locationsError,
  };
}

export function useLocationsLayersRecent(): LocationsLayers {
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
    // TODO - don't pull mngr from the global namespace
    // @ts-ignore
  } = mngr.useLocations();
  const ll = Object.values(locations || {});
  const lon = ll.map((l: any) => l.geometry.coordinates[0]).join(",");
  const lat = ll.map((l: any) => l.geometry.coordinates[1]).join(",");

  const {
    data: recentRain,
    isLoading: recentRainLoading,
    error: recentRainError,
  } = usePrecipData({ endpoint: "recent-rain", lon, lat });

  return {
    isLoading: locationsLoading || recentRainLoading,
    isLoadingByLayer: {
      recentRain: recentRainLoading,
    },
    data: mapValues(locations, (location: LocationFeature) => {
      const idx = ll
        .map((l: any) => l.properties.id)
        .indexOf(location.properties.id);
      return {
        recentRain: recentRain?.features?.[idx], // precip
      };
    }),
    error: recentRainError || locationsError,
  };
}

/*
 * SINGLE LOCATION
 */

export function useLocationDaily(
  location: LocationFeature,
  startParam?: Date,
  endParam?: Date,
  model?: Model
) {
  const [lon, lat] = location.geometry.coordinates;
  const oneYearAgo = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
  const sixteenDaysFromNow = new Date(Date.now() + 16 * 24 * 60 * 60 * 1000);

  const start = startParam || oneYearAgo;
  const end = endParam || sixteenDaysFromNow;
  const q = usePrecipData({ endpoint: "daily", lon, lat, start, end, model });
  return {
    ...q,
    data: q.isLoading || q.isError ? undefined : q.data?.features?.[0],
    start,
    end,
  };
}

export function useLocationLayers(location: LocationFeature): LocationLayers {
  const {
    isLoading,
    isLoadingByLayer,
    data: layersData,
    error,
  } = useLocationsLayers();
  const id = location?.properties?.id;
  const data = id ? layersData?.[id] : undefined;
  return {
    isLoading,
    isLoadingByLayer,
    data,
    error,
  };
}

export function useLocationLayersLast48(
  location: LocationFeature
): LocationLayers {
  const {
    isLoading,
    isLoadingByLayer,
    data: layersData,
    error,
  } = useLocationsLayersLast("last-48", "last48");
  const id = location?.properties?.id;
  const data = id ? layersData?.[id] : undefined;
  return {
    isLoading,
    isLoadingByLayer,
    data,
    error,
  };
}

export function useLocationHourly(
  location: LocationFeature,
  startParam?: Date,
  endParam?: Date,
  enabled?: boolean
) {
  const [lon, lat] = location.geometry.coordinates;
  const fortyEightHoursAgo = new Date(Date.now() - 48 * 60 * 60 * 1000);
  const fortyEightHoursFromNow = new Date(Date.now() + 48 * 60 * 60 * 1000);
  const start = startParam || fortyEightHoursAgo;
  const end = endParam || fortyEightHoursFromNow;
  const q = usePrecipData({
    endpoint: "hourly",
    lon,
    lat,
    start,
    end,
    model: ["wind-speed", "temperature"],
    enabled,
  });
  return {
    ...q,
    data: q.isLoading || q.isError ? undefined : q.data?.features?.[0],
    start,
    end,
  };
}

export function useRecentRain(
  location?: LocationFeature,
  coords?: [number, number]
) {
  const [lon, lat] = location
    ? location?.geometry?.coordinates
    : coords || [undefined, undefined];
  const q = usePrecipData({
    endpoint: "recent-rain",
    lon,
    lat,
  });
  // we could alternatively get data[location.properties.id] from useLocationsLayersRecent()
  return {
    ...q,
    data: q.isLoading || q.isError ? undefined : q.data?.features?.[0],
  };
}
