import { createContext, useContext, useState } from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

import styles from "brand/components/Toast.module.css";
import Button from "./Button";

type Toast = {
  title: string;
  description?: string;
  actionText?: string;
  isOpen?: boolean;
};

const Context = createContext({
  addToast: (t: Toast) => {},
});

const ToastItem = ({
  title,
  description = "",
  actionText = "Close",
  isOpen,
  setIsOpen,
}: {
  title: string;
  description?: string;
  actionText?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  return (
    <ToastPrimitive.Root
      className={styles.root}
      open={isOpen}
      onOpenChange={setIsOpen}
    >
      <ToastPrimitive.Title className={styles.title}>
        {title}
      </ToastPrimitive.Title>
      <ToastPrimitive.Description className={styles.description}>
        {description || ""}
      </ToastPrimitive.Description>
      <ToastPrimitive.Action className={styles.action} altText="Close">
        <Button tertiary>{actionText}</Button>
      </ToastPrimitive.Action>
    </ToastPrimitive.Root>
  );
};

export default ({ children }: { children: React.ReactNode }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addToast = (toast: Toast) => {
    setToasts((prev) => [...prev, toast]);
  };

  const updateToastOpen = (toastIndex: number, isOpen: boolean) => {
    setToasts((prev) => {
      const newToasts = [...prev];
      newToasts[toastIndex] = { ...newToasts[toastIndex], isOpen };
      return newToasts;
    });
  };

  return (
    <Context.Provider value={{ addToast }}>
      <ToastPrimitive.Provider swipeDirection="right">
        {children}
        {toasts.map((toast, i) => (
          <ToastItem
            key={i}
            isOpen={toast.isOpen}
            setIsOpen={(isOpen) => updateToastOpen(i, isOpen)}
            {...toast}
          />
        ))}
        <ToastPrimitive.Viewport className={styles.viewport} />
      </ToastPrimitive.Provider>
    </Context.Provider>
  );
};

export const useToast = () => useContext(Context);
