import { useState } from "react";
import { useMediaQuery } from "usehooks-ts";

import { Spinner } from "brand/components/Loading";
import Tooltip from "brand/components/Tooltip";
import { TH } from "brand/components/Table";
import tableStyles from "brand/components/Table.module.css";
import { WeatherFactorId, forecastDays, useLocationsLayers } from "shared";
import EmptyState, { ErrorState } from "brand/components/EmptyState";

import { mngr } from "../store";
import sortLocations, { SortByOptions } from "./sortLocations";
import LocationRow from "./LocationTableRow";

export default function LocationTable({
  filterQuery,
  mode,
}: {
  filterQuery: string;
  mode: WeatherFactorId;
}) {
  const [sortBy, setSortBy] = useState<SortByOptions>("name");
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  // CSS is dumb
  // You can't have sticky headers inside a container that scrolls
  // And we need our table to scroll on mobile
  // https://stackoverflow.com/a/70772702
  // So, we make the headers sticky on desktop, not on mobile
  // And we make the table scroll on mobile, not on desktop
  // Alternate solution for later: use JS to force the sticky
  // (if we need sticky headers on mobile)
  const isMobile = useMediaQuery("(max-width: 900px)");

  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();
  const {
    data: weatherData,
    isLoadingByLayer: isLoadingWeatherData,
    error: weatherDataError,
  } = useLocationsLayers();

  const filteredLocations = Object.values(locations || {}).filter((l) => {
    return (
      l.properties.name?.toLowerCase().includes(filterQuery.toLowerCase()) ||
      l.properties.id.includes(filterQuery)
    );
  });

  const sortedLocations = sortLocations(
    filteredLocations,
    sortBy,
    sortAscending,
    weatherData
  );

  function sort(name: SortByOptions) {
    if (name === sortBy) {
      setSortAscending(!sortAscending);
    } else {
      setSortBy(name);
      setSortAscending(true);
    }
  }

  return (
    <>
      {locationsError ? (
        <ErrorState
          title={`Sorry, we couldn't load your locations right now`}
          subtext={`Please try again in a minute`}
        />
      ) : locationsLoading ? (
        <Spinner padded />
      ) : !Object.values(locations || {}).length ? (
        <EmptyState text="Add a location to get started" />
      ) : (
        <div
          className={tableStyles.tableWrapper}
          style={{
            overflow: isMobile ? "auto" : "visible",
          }}
        >
          <table
            className={`${tableStyles.table} ${tableStyles.tableGrayHeader}`}
          >
            <thead>
              <tr className={tableStyles.tableHeaderRowSticky}>
                <TH
                  sticky={!isMobile}
                  sortable
                  ascending={sortBy === "name" && sortAscending}
                  descending={sortBy === "name" && !sortAscending}
                  onClick={() => sort("name")}
                >
                  Location
                </TH>
                <TH
                  sticky={!isMobile}
                  sortable
                  ascending={sortBy === "group" && sortAscending}
                  descending={sortBy === "group" && !sortAscending}
                  onClick={() => sort("group")}
                >
                  Group
                </TH>
                {mode === "wind" ? (
                  <>
                    <TH
                      sticky={!isMobile}
                      sortable
                      ascending={sortBy === "wind_speed" && sortAscending}
                      descending={sortBy === "wind_speed" && !sortAscending}
                      onClick={() => sort("wind_speed")}
                      helperContent={
                        <>
                          <p>Average wind speed by hour</p>
                          <p>
                            <a
                              href="https://precip.ai/our-precipitation-data"
                              target="_blank"
                            >
                              More details...
                            </a>
                          </p>
                        </>
                      }
                    >
                      Current wind
                    </TH>
                    <TH
                      sticky={!isMobile}
                      sortable
                      fullWidth
                      // ascending={sortBy === "wind_timeline" && sortAscending}
                      // descending={sortBy === "wind_timeline" && !sortAscending}
                      // onClick={() => sort("wind_timeline")}
                      helperContent={
                        <>
                          <p>Average wind speed by hour</p>
                          <p>
                            <a
                              href="https://precip.ai/our-precipitation-data"
                              target="_blank"
                            >
                              More details...
                            </a>
                          </p>
                        </>
                      }
                    >
                      Wind speed (next 48 hours)
                    </TH>
                  </>
                ) : (
                  <>
                    <TH
                      sticky={!isMobile}
                      sortable
                      ascending={sortBy === "rain_amount" && sortAscending}
                      descending={sortBy === "rain_amount" && !sortAscending}
                      onClick={() => sort("rain_amount")}
                      helperContent={
                        <>
                          <p>
                            Total rainfall amount for the most recent 48 hour
                            period.
                          </p>
                          <p>
                            Data is delayed 20-80 minutes and is updated hourly.
                          </p>
                          <p>
                            <a
                              href="https://precip.ai/our-precipitation-data"
                              target="_blank"
                            >
                              More details...
                            </a>
                          </p>
                        </>
                      }
                    >
                      Past 48h
                    </TH>
                    <TH
                      sticky={!isMobile}
                      sortable
                      ascending={sortBy === "last_rain" && sortAscending}
                      descending={sortBy === "last_rain" && !sortAscending}
                      onClick={() => sort("last_rain")}
                      helperContent={
                        <>
                          <p>
                            Time since the most recent precipitation event ended
                          </p>
                          <p>
                            An event is any period of consecutive precipitation
                            with no more than one hour with less than 0.02
                            inches rain-equivalent.
                          </p>
                          <p>
                            <a
                              href="https://precip.ai/our-precipitation-data"
                              target="_blank"
                            >
                              More details...
                            </a>
                          </p>
                        </>
                      }
                    >
                      Last precip
                    </TH>
                    {forecastDays().map((d, di) => (
                      <TH
                        key={di}
                        centered
                        sticky={!isMobile}
                        sortable
                        ascending={sortBy === di && sortAscending}
                        descending={sortBy === di && !sortAscending}
                        onClick={() => sort(di)}
                      >
                        <Tooltip
                          delayDuration={20}
                          trigger={
                            <div style={{ textAlign: "center" }}>
                              <div
                                style={{
                                  lineHeight: "1",
                                  fontSize: "var(--xs)",
                                  padding: "0 0 .25rem",
                                }}
                              >
                                {d.dayName}
                              </div>
                              <div
                                style={{
                                  lineHeight: "1",
                                  fontSize: "var(--m)",
                                  fontWeight: "400",
                                }}
                              >
                                {d.monthString || ""} {d.dateOfMonth}
                              </div>
                            </div>
                          }
                        >
                          {d.tooltipContent}
                        </Tooltip>
                      </TH>
                    ))}
                  </>
                )}
                <TH sticky={!isMobile} noWidth>
                  {" "}
                </TH>
              </tr>
            </thead>
            <tbody data-testid="LocationsTable-tbody">
              {sortedLocations.map((l) => {
                return (
                  <LocationRow
                    key={l.properties.id}
                    l={l}
                    data={weatherData[l.properties.id]}
                    isLoadingByLayer={isLoadingWeatherData}
                    error={weatherDataError}
                    mode={mode}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}
