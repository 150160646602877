// STAT MARKER
// This component is a specific kind of mapbox map marker that shows a single value,
// ie rain amount, snow amount, wind speed, etc.
// If you want to show multiple values or other arbitrary data, use the other marker components

import {
  CrossCircledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";

import styles from "./StatMarker.module.css";
import Tooltip from "../Tooltip";
import { Placeholder, Shimmer } from "../Loading";
import { getDataColors } from "brand/scripts/colors";
import { useDarkMode } from "../../hooks/useDarkMode";
import WindDirection from "../WindDirection";
import { PrecipType, WeatherFactorId } from "shared";
import { PrecipTypeIcon } from "../PrecipTypeIcon";
// import { IonSpinner } from "@ionic/react";
import { Raindrops, Snowfall } from "../Raindrops";

// TODO - multiple kinds of props for different situations
// For example, if we're loading, there should be no amount; etc
interface StatMarkerProps {
  name?: string;
  factor: WeatherFactorId;
  precipType?: PrecipType;
  noData?: boolean;
  amount?: number;
  active?: boolean;
  isLoading?: boolean;
  windDegrees?: number;
  warning?: string;
  error?: Error;
  hideTooltip?: boolean;
  onClick?: (evt: any) => void;
  fmtRain?: (amount: number) => string;
  fmtSnow?: (amount: number) => string;
  fmtSnowRange?: (amount: number) => string;
  fmtTemp?: (amount: number) => string;
  fmtWind?: (amount: number) => string;
}

function StatMarker({
  name = "Unnamed location",
  factor,
  precipType,
  noData = false,
  amount,
  active = false,
  isLoading = false,
  windDegrees,
  warning,
  error,
  hideTooltip = false,
  onClick,
  fmtRain,
  fmtSnow,
  fmtSnowRange,
  fmtTemp,
  fmtWind,
}: StatMarkerProps) {
  const isDarkMode = useDarkMode();

  const amountFormatted =
    factor === "precip" && precipType === "snow"
      ? fmtSnow(amount)
      : factor === "precip"
      ? fmtRain(amount)
      : factor === "temp"
      ? fmtTemp(amount)
      : factor === "wind"
      ? fmtWind(amount)
      : amount + "";

  const fullAmountFormatted =
    factor === "precip" && precipType === "snow"
      ? fmtSnowRange(amount)
      : amountFormatted;

  let { backgroundColor, textColor, intensity } = getDataColors({
    amount,
    factor,
    precipType,
    isDarkMode,
  });

  if (isLoading) {
    backgroundColor = "var(--background-secondary)";
    textColor = "var(--text-secondary)";
  }

  if (error) {
    backgroundColor = "var(--background-danger)";
    textColor = "var(--text-danger)";
  }

  const tooltipContent = (
    <>
      {name || "Unnamed location"}
      <br />
      {fullAmountFormatted} {precipType || ""}{" "}
      {factor === "wind"
        ? "current wind speed"
        : factor === "temp"
        ? "current soil temperature"
        : "in the past 48 hours"}
    </>
  );

  const markerInner = (
    <div
      className={styles.inner}
      // Dark mode markers use transparent backgrounds
      // so we need to set a background on the background
      style={{ background: isDarkMode && "var(--background-primary)" }}
    >
      <div
        className={styles.background}
        style={{
          color: textColor,
          backgroundColor: backgroundColor,
        }}
      >
        {active && factor === "precip" && precipType === "rain" && (
          <Raindrops color={intensity && intensity < 1 ? "blue" : "white"} />
        )}
        {active && factor === "precip" && precipType === "snow" && (
          <Snowfall color={intensity && intensity < 1 ? "blue" : "white"} />
        )}
        <Shimmer isLoading={isLoading} />
        {noData ? (
          <div className={styles.content}>
            <ExclamationTriangleIcon />
            <span className="flex-row gap-2 align-base">
              No data
              <Tooltip
                trigger={
                  <a
                    href="/TODO"
                    style={{
                      fontSize: "var(--xs)",
                      color: "inherit",
                      textDecorationStyle: "dotted",
                    }}
                  >
                    Why?
                  </a>
                }
              >
                This location is not reporting data.
                {/* Click to learn more. */}
              </Tooltip>
            </span>
          </div>
        ) : error ? (
          <div className={styles.content}>
            <CrossCircledIcon />
            Error
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.Value}>
              {isLoading ? (
                <Placeholder
                  style={{
                    width: "2rem",
                    height: ".9rem",
                    margin: "0 0 3px",
                  }}
                />
              ) : (
                <div className={styles.Number}>{amountFormatted}</div>
              )}
            </div>
            {windDegrees &&
              windDegrees !== 0 &&
              factor === "wind" &&
              !isLoading && <WindDirection degrees={windDegrees} size={8} />}
            {amount > 0 && factor === "precip" && precipType === "snow" && (
              <div
                style={{
                  width: "12px",
                  height: "12px",
                }}
              >
                <PrecipTypeIcon precipType={precipType} size={12} />
              </div>
            )}
            {warning && <div className={styles.yellowIcon}>!</div>}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={styles.MarkerWrapper}
      onClick={(evt) => onClick && onClick(evt)}
    >
      {tooltipContent && !hideTooltip ? (
        <Tooltip hideArrow delayDuration={0} trigger={markerInner}>
          {tooltipContent}
          {warning && (
            <>
              <br />
              <br />
              {warning} Click to learn more.
            </>
          )}
        </Tooltip>
      ) : (
        markerInner
      )}

      {/* make sure translucent background colors still appear in the arrow */}
      <div
        className={styles.PopupArrow}
        style={{
          borderTop: `var(--arrow-height) solid var(--background-primary)`,
        }}
      />
      <div
        className={styles.PopupArrow}
        style={{
          borderTop: `var(--arrow-height) solid ${backgroundColor}`,
        }}
      />
    </div>
  );
}

export default StatMarker;
