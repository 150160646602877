import Card from "brand/components/Card";
import Calendar from "brand/components/Calendar";

import {
  fmtRainM,
  fmtRainI,
  fmtSnowRangeM,
  fmtSnowRangeI,
  useLocationDaily,
} from "shared";
import { useState } from "react";
import { ErrorState } from "brand/components/EmptyState";
import { mngr } from "../store";

export default function LocationPageCalendar({ location }) {
  const [monthDt, setMonthDt] = useState(new Date());
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;
  const fmtSnowRange =
    preferredUnits === "metric" ? fmtSnowRangeM : fmtSnowRangeI;

  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location);

  return (
    <Card>
      {errorDaily ? (
        <ErrorState error={errorDaily} />
      ) : (
        <Calendar
          isLoading={isLoadingDaily}
          year={monthDt.getFullYear()}
          month={monthDt.getMonth()}
          incrementMonth={(i) => {
            setMonthDt(new Date(monthDt.getFullYear(), monthDt.getMonth() + i));
          }}
          reset={() => {
            setMonthDt(new Date());
          }}
          fmtRain={fmtRain}
          fmtSnowRange={fmtSnowRange}
          days={dataDaily?.properties?.days?.map(
            ({ startTime, precip, source, precip_type }) => {
              return {
                amount: precip,
                date: new Date(startTime),
                source,
                precipType: precip_type,
              };
            }
          )}
        />
      )}
    </Card>
  );
}
