import { PrecipType } from "shared";
import styles from "./PrecipTypeIcon.module.css";

export const PrecipTypeIcon = ({
  precipType = "rain",
  size = 18,
}: {
  precipType?: PrecipType;
  size?: number;
}) => {
  if (precipType === "snow") {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          width: size,
          height: size,
          flex: "1 0 auto",
        }}
      >
        <path
          d="M13.7632 6.25L4.23687 11.75M9 3.5L9 14.5M4.23685 6.25L13.7631 11.75"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    );
  }
  // if (precipType === "hail") {
  //   return (
  //     <svg
  //       width="18"
  //       height="18"
  //       viewBox="0 0 18 18"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <circle
  //         cx="11.247"
  //         cy="12.7507"
  //         r="2.5"
  //         transform="rotate(36.9041 11.247 12.7507)"
  //         fill="currentColor"
  //       />
  //       <circle
  //         cx="4.84987"
  //         cy="7.94692"
  //         r="2.5"
  //         transform="rotate(36.9041 4.84987 7.94692)"
  //         fill="currentColor"
  //       />
  //       <circle
  //         cx="11.9516"
  //         cy="5.15116"
  //         r="2.5"
  //         transform="rotate(36.9041 11.9516 5.15116)"
  //         fill="currentColor"
  //       />
  //     </svg>
  //   );
  // }
  return "";
};

export const PrecipTypeIconAnnotation = ({
  precipType = "rain",
}: {
  precipType?: PrecipType;
}) => {
  return (
    <div className={styles.wrapper}>
      <PrecipTypeIcon precipType={precipType} />
    </div>
  );
};
