import { useNavigate } from "react-router-dom";
import { LockClosedIcon, PlusIcon } from "@radix-ui/react-icons";
import { Drop, Wind } from "@phosphor-icons/react";

import { MAX_FREE_LOCATIONS, WeatherFactorId } from "shared";
import { mngr } from "../store";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
import useFeatureFlag from "brand/hooks/useFeatureFlag";

export default function LocationHeader({
  filterQuery,
  setFilterQuery,
  mode,
  setMode,
}: {
  filterQuery: string;
  setFilterQuery: (query: string) => void;
  mode: WeatherFactorId;
  setMode: (mode: WeatherFactorId) => void;
}) {
  const navigate = useNavigate();

  const { loading: userLoading, error: userError } = mngr.useUser();

  const { atLocationLimit } = mngr.useSubscription();

  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();

  const noLocationsYet =
    !locationsLoading &&
    Object.keys(locations || {}).length === 0 &&
    !locationsError;

  const showWindTemp = useFeatureFlag("wind_on_locations_list");

  return (
    <div
      className="flex-row gap-2 justify-between align-center"
      style={{
        margin: "1.5rem 0",
      }}
    >
      <div className="flex-row gap-6 align-center">
        <h1 style={{ margin: 0 }}>Locations</h1>
        {showWindTemp && (
          <ButtonGroupWrapper>
            <Button
              secondary
              selected={mode === "precip"}
              onClick={() => setMode("precip")}
            >
              <Drop />
              Rain
            </Button>
            <Button
              secondary
              selected={mode === "wind"}
              onClick={() => setMode("wind")}
            >
              <Wind />
              Wind
            </Button>
          </ButtonGroupWrapper>
        )}
      </div>
      <div className="flex-row gap-4 justify-end align-center">
        <input
          type="search"
          value={filterQuery}
          placeholder="Filter locations"
          onChange={(e) => setFilterQuery(e.target.value)}
          disabled={locationsLoading || Boolean(locationsError)}
        />
        {/* Filter dropdowns */}
        {/* Import */}
        <Button
          secondary={!noLocationsYet}
          primary={noLocationsYet}
          disabled={
            locationsLoading ||
            userLoading ||
            Boolean(userError) ||
            Boolean(locationsError)
          }
          onClick={() => {
            if (atLocationLimit) {
              alert(
                `You have reached the limit of ${MAX_FREE_LOCATIONS} free location${
                  MAX_FREE_LOCATIONS === 1 ? "s" : ""
                }. Please contact us to learn about our unlimited plan.`
              );
              window.open("https://precip.ai/contact", "_blank");
            } else {
              navigate("/map?adding=true");
            }
          }}
        >
          {atLocationLimit ? <LockClosedIcon /> : <PlusIcon />}
          Add location
        </Button>
      </div>
    </div>
  );
}
