import * as Tabs from "@radix-ui/react-tabs";

import styles from "./VerticalNav.module.css";

type Item = {
  id: string;
  label: string;
  content: React.ReactNode;
};

export default function ({ items, label }: { items: Item[]; label: string }) {
  return (
    <Tabs.Root className={styles.TabsRoot} defaultValue={items[0].id}>
      <Tabs.List className={styles.TabsList} aria-label={label}>
        {items.map((item) => (
          <Tabs.Trigger
            key={item.id}
            className={styles.TabsTrigger}
            value={item.id}
          >
            {item.label}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {items.map((item) => (
        <Tabs.Content
          key={item.id}
          className={styles.TabsContent}
          value={item.id}
        >
          {item.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
}
