import { useEffect, useState } from "react";

import styles from "./Logo.module.css";
import LogoSvg from "../assets/logos/black.svg";

export default function Logo({
  spinIn,
  size = 30,
}: {
  spinIn?: boolean;
  size?: number;
}) {
  const [mounted, setMounted] = useState(!spinIn);
  useEffect(() => {
    setTimeout(() => {
      setMounted(true);
    }, 500);
  }, []);

  return (
    <div
      className={[
        styles.wrapper,
        mounted ? styles.visible : styles.hidden,
        "invert-for-dark-mode",
      ].join(" ")}
      style={{
        width: size,
        height: size,
      }}
    >
      <img className={styles.logo} src={LogoSvg} alt="Precip logo" />
    </div>
  );
}
