import { useState } from "react";

import Card from "brand/components/Card";
import LineChart from "brand/components/chart/LineChart";
import {
  cToF,
  fToC,
  fmtTempUnitM,
  fmtTempUnitI,
  useLocationHourly,
  chartTimeRangeOptions,
  ChartTimeRangeOption,
} from "shared";
import { Spinner } from "brand/components/Loading";
import { ErrorState } from "brand/components/EmptyState";
import colors from "brand/scripts/colors";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
import { mngr } from "../store";

export default function LocationPageTemp({ location }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtTempUnit = preferredUnits === "metric" ? fmtTempUnitM : fmtTempUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : cToF;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : fToC;
  const tempUnit = preferredUnits === "metric" ? "°C" : "°F";

  const [timeRange, setTimeRange] = useState<ChartTimeRangeOption>(
    chartTimeRangeOptions[chartTimeRangeOptions.length - 1]
  );

  const {
    data: dataHourly,
    isLoading: isLoadingHourly,
    error: errorHourly,
  } = useLocationHourly(location, timeRange.start, timeRange.end);

  const dataFormatted = dataHourly?.properties?.hours?.map((hour) => {
    const { startTime, temperature, source } = hour;
    return {
      value: toConvertedUnits(temperature),
      date: new Date(startTime),
      source,
    };
  });

  console.log("----------TODO------------");

  console.log("requested rate range:", timeRange.start, timeRange.end);

  const minDataDate = dataFormatted?.reduce((acc, d) => {
    if (d.date < acc) return d.date;
    return acc;
  }, new Date());
  const maxDataDate = dataFormatted?.reduce((acc, d) => {
    if (d.date > acc) return d.date;
    return acc;
  }, new Date());
  console.log("data date range:", minDataDate, maxDataDate);

  console.log(
    // @ts-ignore
    "start delta in hours:" + (timeRange.start - minDataDate) / (1000 * 60 * 60)
  );
  console.log(
    // @ts-ignore
    "end delta in hours:" + (maxDataDate - timeRange.end) / (1000 * 60 * 60)
  );

  const startString = timeRange.start.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });
  const endString = timeRange.end.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });

  return (
    <Card>
      <div
        className="flex-row gap-4 justify-between align-center"
        style={{ marginBottom: "1rem" }}
      >
        <h4>
          Recent air temperature{" "}
          <span className="color-tertiary font-xs weight-normal">
            {startString} - {endString}
          </span>
        </h4>
        <ButtonGroupWrapper>
          {chartTimeRangeOptions.map((t) => (
            <Button
              key={t.label}
              secondary
              selected={timeRange.label === t.label}
              onClick={() => setTimeRange(t)}
            >
              {t.label}
            </Button>
          ))}
        </ButtonGroupWrapper>
      </div>
      {isLoadingHourly ? (
        <Spinner padded />
      ) : errorHourly ? (
        <ErrorState error={errorHourly} />
      ) : (
        <LineChart
          yAxisLabel={`Air temperature (${tempUnit})`}
          yUpperBoundMinimum={80}
          xAxisTimeFormat="%a %I%p"
          xAxisTickFrequency="daily"
          hoverTimeFormat={"%a %I%p"}
          nowLine={true}
          unitFormatter={fmtTempUnit}
          toConvertedUnits={toConvertedUnits}
          toOriginalUnits={toOriginalUnits}
          showRules
          cumulative={false}
          gradient={true}
          weatherFactor={"temp"}
          colorScale={colors["air-temp"]}
          data={dataFormatted}
        />
      )}
    </Card>
  );
}
