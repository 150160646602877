import { useEffect, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { differenceInWeeks, startOfWeek, endOfWeek } from "date-fns";
import { select } from "d3-selection";
import { timeFormat } from "d3-time-format";

import colors, { getDataColors } from "brand/scripts/colors";
import Debug from "./Debug";
import ChartTooltip from "./ChartTooltip";
import { Spinner } from "../Loading";

type DataType = {
  date: Date;
  value: number;
  source: "observation" | "forecast";
}[];

type ChartProps = {
  // https://d3js.org/d3-time-format
  xAxisTimeFormat?: string;

  unitFormatter?: (value: number) => string;
  toOriginalUnits?: (value: number) => number;
  data: DataType;
  colorScale?: any;
  height?: number;
  hideTooltip?: boolean;
  isLoading?: boolean;
};

const weeksBetween = (startDate: Date, endDate: Date) => {
  const startOfWeekDate = startOfWeek(startDate);
  const endOfWeekDate = endOfWeek(endDate);
  return differenceInWeeks(endOfWeekDate, startOfWeekDate);
};

export default function BlockChart({
  xAxisTimeFormat = "%m/%d/%Y",
  unitFormatter = (number: number) => String(number.toFixed(2)),
  toOriginalUnits = (number: number) => number,
  data = [],
  colorScale = colors["blue"],
  height = 160,
  hideTooltip = false,
  isLoading = false,
}: ChartProps) {
  const chartRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);
  const boxRef = useRef<SVGGElement>(null);

  const { width: chartWidth = 200, height: chartHeight = height } =
    useResizeObserver({
      ref: chartRef,
      box: "border-box",
    });

  const Y_AXIS_WIDTH = 10;
  const X_AXIS_HEIGHT = 10;
  const PAD_TOP = 10;
  const PAD_RIGHT = 10;
  const SQUARE_SIZE = 14;

  height = SQUARE_SIZE * 7 + PAD_TOP + X_AXIS_HEIGHT;

  const [tooltipContent, setTooltipContent] = useState({
    top: 0,
    left: 0,
    isVisible: false,
    content: <></>,
  });

  const items = data?.map((item) => {
    return {
      date: item.date,
      source: item.source,
      value: item.value,
    };
  }) as DataType;

  const firstDate = items?.[0].date;
  const lastDate = items?.[items.length - 1].date;
  const dataBlocksWidth =
    weeksBetween(firstDate, lastDate) * SQUARE_SIZE + SQUARE_SIZE * 2;
  const leftStart = chartWidth - dataBlocksWidth;

  useEffect(() => {
    select(boxRef.current).selectAll("*").remove();
    select(boxRef.current)
      .append("g")
      .attr("transform", `translate(${Y_AXIS_WIDTH}, ${PAD_TOP})`)
      .selectAll("rect")
      .data(items)
      .join("rect")
      .attr("rx", 2)
      .attr("x", (d) => {
        const fromLeft = weeksBetween(firstDate, d.date) * SQUARE_SIZE;
        return leftStart + fromLeft;
      })
      .attr("y", (d) => d.date.getDay() * SQUARE_SIZE)
      .attr("width", SQUARE_SIZE)
      .attr("height", SQUARE_SIZE)
      .attr("fill", (d) => {
        if (d.value === 0) return "var(--background-secondary)";
        const { backgroundColor } = getDataColors({
          // TODO - adjust all rain colors to have darker min intensity?
          amount: toOriginalUnits(d.value) + 5,
          factor: "precip",
          colorScale,
        });
        return backgroundColor;
      })
      .attr("stroke", "var(--background-primary)")
      .attr("stroke-width", 2);
  }, [unitFormatter, chartWidth, chartHeight, X_AXIS_HEIGHT]);

  useEffect(() => {
    select(boxRef.current)
      .selectAll("rect")
      .on("mouseover", (event) => {
        setTooltipContent({ ...tooltipContent, isVisible: !hideTooltip });
        select(event.target).attr("opacity", hideTooltip ? 1 : 0.5);
      })
      .on("mousemove", (event, d) => {
        const { layerX, layerY } = event;
        // @ts-ignore
        const date = timeFormat(xAxisTimeFormat)(d.date);
        // @ts-ignore
        const value = d?.value ?? 0;

        setTooltipContent({
          top: layerY,
          // top: PAD_TOP - 24 - layerY,
          left: layerX,
          content: (
            <>
              <div
                style={{
                  fontSize: "var(--xs)",
                  color: "var(--text-secondary)",
                }}
              >
                {date}
              </div>
              <div
                style={{
                  fontSize: "var(--s)",
                  color: "var(--text-primary)",
                }}
              >
                {unitFormatter(value)}
              </div>
            </>
          ),
          isVisible: !hideTooltip,
        });
      })
      .on("mouseout", (event) => {
        select(event.target).attr("opacity", 1);
        setTooltipContent({ ...tooltipContent, isVisible: false });
      });
  }, [unitFormatter, chartWidth, chartHeight, X_AXIS_HEIGHT]);

  return (
    <div ref={chartRef} style={{ height, width: "100%", position: "relative" }}>
      <ChartTooltip
        top={tooltipContent.top}
        height={chartHeight - X_AXIS_HEIGHT - PAD_TOP}
        left={tooltipContent.left}
        maxDistance={chartWidth}
        isVisible={tooltipContent.isVisible}
        content={tooltipContent.content}
        hideLine
        boxy
      />

      {isLoading && (
        <div
          className="position-absolute"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner padded />
        </div>
      )}

      <svg
        ref={svgRef}
        width={chartWidth}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        style={{
          position: "relative",
          zIndex: 100,
        }}
      >
        <g ref={boxRef} />

        <Debug
          width={chartWidth}
          height={chartHeight}
          top={PAD_TOP}
          right={PAD_RIGHT}
          bottom={X_AXIS_HEIGHT}
          left={Y_AXIS_WIDTH}
          hide
        />
      </svg>
    </div>
  );
}
