export function mapValues<T, U>(
  obj: Record<string, T>,
  iteratee: (value: T) => U
): Record<string, U> {
  const result: Record<string, U> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      result[key] = iteratee(obj[key]);
    }
  }
  return result;
}

type KeyFunc = (item: any) => string;

export const keyBy = (array: any[], key: string | KeyFunc) => {
  const result = {};
  const isFunc = typeof key === "function";
  if (!array) return result;
  array.forEach((item) => {
    if (isFunc) result[key(item)] = item;
    else result[item[key]] = item;
  });
  return result;
};
