import styles from "./Breadcrumbs.module.css";

export default function Breadcrumbs({
  pages,
}: {
  pages: { name: string; url?: string }[];
}) {
  return (
    <div className={styles.wrapper}>
      {pages.map((page, i) => {
        return (
          <span key={i}>
            {page.url ? (
              <a className={styles.item} href={page.url}>
                {page.name}
              </a>
            ) : (
              <span className={styles.item}>{page.name}</span>
            )}
            {i < pages.length - 1 && (
              <span className={styles.separator}>&rsaquo;</span>
            )}
          </span>
        );
      })}
    </div>
  );
}
