// @ts-nocheck

// basics https://webgl2fundamentals.org/webgl/lessons/webgl-image-processing.html
// import * as webglUtils from "./webglUtils";

const vertexShaderSource = `#version 300 es

in vec4 a_position;
in vec2 a_texcoord;
 
 
// a varying to pass the texture coordinates to the fragment shader
out vec2 v_texcoord;
 
void main() {
  gl_Position = a_position;
 
  // Pass the texcoord to the fragment shader.
  v_texcoord = a_texcoord;
}
`;

const fragmentShaderSource = `#version 300 es

// fragment shaders don't have a default precision so we need
// to pick one. highp is a good default. It means "high precision"
precision highp float;

float value;
vec2 colorCoords;
 
// Passed in from the vertex shader.
in vec2 v_texcoord;

uniform float dataMin;
uniform float dataMax;
 
// The texture.
uniform sampler2D colorMap;
uniform sampler2D data;
 
out vec4 outColor;
 
void main() {
   value = texture(data, vec2(v_texcoord.x, 1.0 - v_texcoord.y)).r;
   value = (value - dataMin) / (dataMax - dataMin);

  // Debugging lines
  // if (value < 0.0 || value > 1.0) {
  //     outColor = vec4(1.0, 0.0, 0.0, 1.0); // red color for out-of-bounds values
  //     return;
  // }

  // value = clamp(value, 0.0, 1.0);
  // outColor = vec4(value, 0.0, 0.0, 1.0);

   colorCoords = vec2(clamp(value, 0.0, 1.0), 0.5);
   outColor = texture(colorMap, colorCoords);

    if (value < 0.0) {
        outColor.a = 0.0; // transparent below range
    }
}
`;

function createProgram(gl, vertexShaderSource, fragmentShaderSource) {
  const program = gl.createProgram();
  gl.attachShader(
    program,
    createShader(gl, gl.VERTEX_SHADER, vertexShaderSource)
  );
  gl.attachShader(
    program,
    createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource)
  );
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    throw gl.getProgramInfoLog(program);
  }
  return program;
}

function createAndSetupTexture(gl) {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  return texture;
}

function createShader(gl, type, sourceCode) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, sourceCode.trim());
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    throw gl.getShaderInfoLog(shader);
  }
  return shader;
}

function setColor(gl, colorScaleArr) {
  gl.texImage2D(
    gl.TEXTURE_2D,
    0, //level,
    gl.RGBA, //internalFormat,
    256, // width,
    1, // height,
    0, // border,
    gl.RGBA, // srcFormat,
    gl.UNSIGNED_BYTE, //srcType,
    colorScaleArr // pixel new Uint8Array
  );
}


function updateData(gl, dataArr, { width, height }) {
  gl.texSubImage2D(
    gl.TEXTURE_2D, // target,
    0, // level,
    0, // xoffset,
    0, // yoffset,
    width,// width,
    height,// height,
    gl.RED, // format,
    gl.FLOAT, // type,
    dataArr, // offset
  )
}

function setData(gl, dataArr, { width, height }) {
  // TODO use glTexSubImage2D faster? OR Framebuffer Object
  // texSubImage2D(target, level, xoffset, yoffset, width, height, format, type, offset)
  // texImage2D(target, level, internalformat, width, height, border, format, type, offset)

  // can use this with dataArr replaced by 0 below, another option is createFramebuffer, bindFramebuffer and framebufferTexture2D
  // const pbo = gl.createBuffer();
  // gl.bindBuffer(gl.PIXEL_UNPACK_BUFFER, pbo);
  // gl.bufferData(gl.PIXEL_UNPACK_BUFFER, dataArr, gl.STATIC_DRAW);

  // copyTexSubImage2D

  // TODO compressedTexImage2D?? framebufferTexture2D
  gl.texImage2D(
    gl.TEXTURE_2D,
    0, // level,
    gl.R32F, // internalFormat,
    width, // width,
    height, // height,
    0, // border,
    gl.RED, // srcFormat,
    gl.FLOAT, // srcType,
    dataArr
  );


}

// function update(
//   dataArr,
//   colorScaleArr,
//   gl,
//   { width, height, min, max },
//   { dataTexture, colorTexture }
// ) {
//   gl.activeTexture(gl.TEXTURE0);
//   // Bind the texture to texture unit 0
//   gl.bindTexture(gl.TEXTURE_2D, colorTexture);
//   setColor(gl, colorScaleArr);

//   gl.activeTexture(gl.TEXTURE0 + 1);
//   gl.bindTexture(gl.TEXTURE_2D, dataTexture);
//   setData(gl, dataArr, { width, height });
// }

function setupTexCoord(gl, program) {
  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texcoord");
  // provide texture coordinates for the rectangle.
  const texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 1.0, 1.0, 0.0, 0.0, 1.0,
    ]),
    gl.STATIC_DRAW
  );

  // Turn on the attribute
  gl.enableVertexAttribArray(texCoordAttributeLocation);
  // Tell the attribute how to get data out of texCoordBuffer (ARRAY_BUFFER)
  gl.vertexAttribPointer(
    texCoordAttributeLocation,
    2, // size = 2 components per iteration
    gl.FLOAT, // type = the data is 32bit floats
    false, // normalize = don't normalize the data
    0, // stride = 0 = move forward size * sizeof(type) each iteration to get the next position
    0 // offset = start at the beginning of the buffer
  );
}

function setupPosition(gl, program) {
  const vertices = [
    [-1, -1],
    [1, -1],
    [-1, 1],
    [1, 1],
  ];
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array(vertices.flat()),
    gl.STATIC_DRAW
  );

  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");

  // const vertexPosition = gl.getAttribLocation(program, "vertexPosition");
  gl.enableVertexAttribArray(positionAttributeLocation);
  gl.vertexAttribPointer(
    positionAttributeLocation,
    vertices[0].length,
    gl.FLOAT,
    false,
    0,
    0
  );
  return vertices;
}


type ColorScale = {
  arr: Uint8ClampedArray; // 256 * 4
  min: number;
  max: number;
};


function hasOffscreenCanvas() {
  // return typeof OffscreenCanvas !== "undefined";
  if (HTMLCanvasElement.prototype.transferControlToOffscreen) {
    return true;
  } else {
    return false;
  }
}

function hasWebGL2() {
  if (typeof WebGL2RenderingContext !== "undefined") {
    return true;
  } else {
    return false;
  }
}


export class Renderer {
  colorScale: ColorScale;

  canvas: OffscreenCanvas;
  context: WebGL2RenderingContext;

  width = 512;
  height = 512;

  constructor() {

    if (!hasOffscreenCanvas() || !hasWebGL2()) {
      console.log("🚨 WebGL2 not supported");
      return;
    }

    this.canvas = new OffscreenCanvas(this.width, this.height);
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.context = this.canvas.getContext("webgl2", { willReadFrequently: true });
    if (!this.context) {
      // for ios 16.4 apparently (typeof WebGL2RenderingContext !== "undefined") return true but context still null
      console.log("🚨 WebGL2 not supported");
      return;
    }
    this.context.getExtension("OES_texture_float");
    this.context.getExtension("EXT_color_buffer_float");
    this.context.getExtension("EXT_float_blend");

    this.texturesSet = false; // has the image texture been set once
    this.program = createProgram(this.context, vertexShaderSource, fragmentShaderSource);
    this.context.useProgram(this.program);

    this.colorTexture = createAndSetupTexture(this.context);
    this.dataTexture = createAndSetupTexture(this.context);

    setupTexCoord(this.context, this.program);
    this.vertices = setupPosition(this.context, this.program);
  }

  setColorScale(colorScale: ColorScale) {
    this.colorScale = colorScale;
    if (!this.context) {
      console.log("🚨 WebGL2 not supported");
      return;
    }
    this.context.bindTexture(this.context.TEXTURE_2D, this.colorTexture);
    setColor(this.context, this.colorScale.arr);
  }

  render(dataArr, { width, height }) {
    this.context.viewport(0, 0, width, height);

    this.context.bindTexture(this.context.TEXTURE_2D, this.dataTexture);
    
    if (!this.texturesSet) {
      setData(this.context, dataArr, { width, height });
      this.texturesSet = true;
    }
    else updateData(this.context, dataArr, { width, height });

    const colorMapLocation = this.context.getUniformLocation(this.program, "colorMap");
    // Tell the shader we bound the texture to texture unit 0
    this.context.uniform1i(colorMapLocation, 0);
    // Tell WebGL we want to affect texture unit 0
    this.context.activeTexture(this.context.TEXTURE0);
    // Bind the texture to texture unit 0
    this.context.bindTexture(this.context.TEXTURE_2D, this.colorTexture);

    const dataLocation = this.context.getUniformLocation(this.program, "data");
    this.context.uniform1i(dataLocation, 1);
    this.context.activeTexture(this.context.TEXTURE0 + 1);
    this.context.bindTexture(this.context.TEXTURE_2D, this.dataTexture);

    const minLocation = this.context.getUniformLocation(this.program, "dataMin");
    this.context.uniform1f(minLocation, this.colorScale.min);
    const maxLocation = this.context.getUniformLocation(this.program, "dataMax");
    this.context.uniform1f(maxLocation, this.colorScale.max);

    this.context.drawArrays(this.context.TRIANGLE_STRIP, 0, this.vertices.length);
  }
}
