import { fToC, inToMM, mphToMps } from "./units";

type WeatherFactor = {
  id: string;
  label: string;
  min: number; // the minimum metric value we choose for the bottom of the color scale
  max: number; // the maximum metric value we choose for the top of the color scale

  // This function takes a real-world values (ie a temperature) and scales it between 0 and 1000,
  // which can then be used to get a color on a ramp.
  // For example, say the minimum rain you expect is 0mm and the maximum is 100mm,
  // you could use a value like 12mm to get 0.12 intensity, which then can be mapped to
  // a corresponding color on a ramp.
  getIntensity: (value: number) => number;
};

const weatherFactors: Partial<WeatherFactor>[] = [
  {
    id: "precip",
    label: "Precip",
    min: inToMM(0),
    max: inToMM(15),
  },
  {
    id: "temp",
    label: "Temp",
    min: fToC(-15),
    max: fToC(110),
  },
  {
    id: "wind",
    label: "Wind",
    min: mphToMps(0),
    max: mphToMps(50),
  },
];

for (const factor of weatherFactors) {
  factor.getIntensity = (value: number) => {
    const min = factor.min;
    const max = factor.max;
    const amountClamped = Math.max(min, Math.min(max, value));
    return (amountClamped - min) / (max - min);
  };
}

export default weatherFactors as WeatherFactor[];

export const weatherFactorsObj = weatherFactors.reduce((acc, curr) => {
  acc[curr.id] = curr as WeatherFactor;
  return acc;
}, {} as Record<WeatherFactorId, WeatherFactor>);

export type WeatherFactorId = (typeof weatherFactors)[number]["id"];
