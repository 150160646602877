// Device-based storage using localStorage
// Similar to /src/Preferences.tsx in the mobile app

const PREFIX = "precip:";

// Add new keys here.
// But don't remove keys! At least move them to the list of deprecated keys below.
// All of these are in a user's browser somewhere.
const validKeys = [
  "most_recent_org_id",
  "has_seen_welcome_screen",
  "has_fired_first_app_load_on_device_event",
  "email_for_sign_in_with_email_link",
  "preferred_units_system",
] as const;

// Deprecated keys that are no longer used
[
  // "example_deprecated_key",
  // "another_example_deprecated_key",
  // "one_more_example_deprecated_key",
  // "another_example_deprecated_key",
];

// Derive a union type from the array's values
type LocalStorageKey = (typeof validKeys)[number];
type LocalStorageValue = string | null | undefined | number | boolean | object;

export function get(key: LocalStorageKey) {
  if (!validKeys.includes(key)) {
    throw new Error(`Warning: ${key} is not a valid localStorage key.`);
  }
  const fullKey = PREFIX + key;
  const stringContent = localStorage.getItem(fullKey);
  if (stringContent === "undefined") return null;
  if (!stringContent) return null;
  return JSON.parse(stringContent);
}

export function set(key: LocalStorageKey, value: LocalStorageValue) {
  if (!validKeys.includes(key)) {
    throw new Error(`Warning: ${key} is not a valid key type.`);
  }
  const fullKey = PREFIX + key;
  const stringContent = JSON.stringify(value);
  return localStorage.setItem(fullKey, stringContent);
}

export function remove(key: LocalStorageKey) {
  if (!validKeys.includes(key)) {
    throw new Error(`Warning: ${key} is not a valid key type.`);
  }
  const fullKey = PREFIX + key;
  return localStorage.removeItem(fullKey);
}
