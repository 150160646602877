// DON'T DELETE!!
// Integration tests use this page to sign into the app
// (since they can't really use SSO)
// Match to SignInTest.tsx

import { useState } from "react";
import { signUpWithEmailAndPassword_TESTING_ONLY } from "../../Auth";
import { Helmet } from "react-helmet-async";

import ErrorPage from "../ErrorPage";
import Button from "brand/components/Button";

export default function SignUpTest() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isWorking, setIsWorking] = useState(false);

  return (
    <>
      <Helmet>
        <title>Precip | TEST sign up</title>
      </Helmet>
      <ErrorPage />
      <form
        // style={{
        //   position: "relative",
        //   left: "9999px",
        // }}
        onSubmit={async (e) => {
          e.preventDefault();
          setIsWorking(true);
          await signUpWithEmailAndPassword_TESTING_ONLY(email, password);
          setIsWorking(false);
          window.location.replace("/");
        }}
      >
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={isWorking}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={isWorking}
        />
        <Button secondary type="submit" disabled={isWorking}>
          Sign Up
        </Button>
      </form>
    </>
  );
}
