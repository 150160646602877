import { Question } from "@phosphor-icons/react";
import { CheckIcon, LockClosedIcon } from "@radix-ui/react-icons";

import { useDarkMode } from "../../hooks/useDarkMode";
import styles from "./Layers.module.css";
import RainfallLight from "../../assets/layers/cumulative-rain-light.png";
import RainfallDark from "../../assets/layers/cumulative-rain-dark.png";
import SoilMoistureLight from "../../assets/layers/soil-moisture-light.png";
import SoilMoistureDark from "../../assets/layers/soil-moisture-dark.png";
import {
  fmtPercent,
  usePrecipData,
  UnitsSystem,
  fmtRainM,
  fmtRainI,
} from "shared";
import useDebounce from "../../hooks/useDebounce";
import { WEATHER_API_URL } from "shared";

// Constants
const EXPLORER_DEBOUNCE_MS = 200;

// Types

export type MapLayer = {
  id: string;
  title: string;
  sub: string;
  url: string;
  icon: {
    light: any;
    dark: any;
  };
  legendValues: string[];
  explorerLabel?: string;
  moreInfo?: {
    title: string;
    description: React.ReactNode;
  };
  //
  layerType?: 'raster-animated' | 'raster';
  timeRange?: { start: Date; end: Date };
  getDefaultTimeRange?: () => { start: Date; end: Date };
  updateTime?: (t: Date) => void;
};
export type MapLayerKey = MapLayer["id"];

function Blurb() {
  return (
    <>
      <p>Precip amounts are updated about every 15 minutes.</p>
      <p>
        Snow and sleet amounts are shown as their rain equivalents (the amount
        rain that would have fallen were it not frozen).
      </p>
      <p>
        Precip's precipitation data is based on models that combine ground-based
        doppler radar data calibrated with network-enabled hardware rain gauges
        and geographical data to create the most accurate possible precipitation
        estimates.
      </p>
    </>
  );
}

// List of map layers
// Add new layers here!
// TODO - get from backend? Remove JSX and move to /shared?
export const mapLayers: MapLayer[] = [
  {
    id: "last-12",
    title: "Cumulative rainfall",
    sub: "past 12 hours",
    url: `${WEATHER_API_URL}/last-12/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-12${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "past 12 hours",
    moreInfo: {
      title: "12 hour cumulative precipitation",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall in the most recent 12
            hours.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "last-24",
    title: "Cumulative rainfall",
    sub: "past 24 hours",
    url: `${WEATHER_API_URL}/last-24/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-24${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "past 24 hours",
    moreInfo: {
      title: "24 hour cumulative precipitation",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall in the most recent 24
            hours.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "last-48",
    title: "Cumulative rainfall",
    sub: "past 48 hours",
    url: `${WEATHER_API_URL}/last-48/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-48${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "past 48 hours",
    moreInfo: {
      title: "48 hour cumulative precipitation",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall in the most recent 48
            hours.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "last-168",
    title: "Cumulative rainfall",
    sub: "past 7 days",
    url: `${WEATHER_API_URL}/last-168/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-168${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "past 168 hours",
    moreInfo: {
      title: "7 day cumulative precipitation",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall in the most recent 7 days.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "td-year",
    title: "Cumulative rainfall",
    sub: "year-to-date",
    url: `${WEATHER_API_URL}/td-year/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-72${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "Since January 1",
    moreInfo: {
      title: "Year-to-date cumulative precipitation",
      description: (
        <>
          <p>This layer shows the total amount of rainfall so far this year.</p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "td-month",
    title: "Cumulative rainfall",
    sub: "month-to-date",
    url: `${WEATHER_API_URL}/td-month/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-72${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "Since January 1",
    moreInfo: {
      title: "Month-to-date cumulative precipitation",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall so far this month.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "td-year-pct",
    title: "Cumulative rainfall percentage",
    sub: "year-to-date",
    url: `${WEATHER_API_URL}/td-year-pct/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-72${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "Since January 1",
    moreInfo: {
      title: "Year-to-date cumulative precipitation anomaly.",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall so far this year as a
            percentage of the 30 year mean.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "td-month-pct",
    title: "Cumulative rainfall percentage",
    sub: "month-to-date",
    url: `${WEATHER_API_URL}/td-month-pct/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    // TODO - bring back dark and light versions of the same tiles?
    // `https://storage.googleapis.com/precip-tiles-public/last-72${
    //   invertScale ? "" : "-inverse"
    // }/{z}/{x}/{y}.png`,
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "Since January 1",
    moreInfo: {
      title: "Month-to-date cumulative precipitation anomaly.",
      description: (
        <>
          <p>
            This layer shows the total amount of rainfall so far this month as a
            percentage of the 30 year mean.
          </p>
          <Blurb />
        </>
      ),
    },
  },
  {
    id: "soil-moisture",
    title: "Current soil moisture",
    sub: "Top 10cm",
    url: `${WEATHER_API_URL}/sport-vsm-0-10cm-daily/ImageServer/tile/{z}/{y}/{x}?time=${
      new Date().getTime() - 24 * 60 * 60 * 1000
    },${new Date().getTime()}`,
    legendValues: ["Drier", "Wetter"],
    icon: {
      light: SoilMoistureLight,
      dark: SoilMoistureDark,
    },
    explorerLabel: "current soil moisture",
    moreInfo: {
      title: "Current soil moisture",
      description: (
        <>
          <p>
            This layer shows the average soil moisture content for the top 10
            centimeters of soil averaged over the past 24 hours.
          </p>
          <p>
            Soil moisture percentage indicates the average water percentage of
            an area of ground by volume. For example, a soil moisture level of
            30% indicates that 30% of the space inside the given area is water.
          </p>
          <p>Soil moisture is updated every 24 hours.</p>
          <p>
            Precip's soil moisture data is based on NASA's SPoRT-LiS soil
            moisture model.
          </p>
        </>
      ),
    },
  },
  {
    id: "min2",
    title: "Currently raining",
    sub: "Animated live rain intensity",
    url: `${WEATHER_API_URL}/api/v1/map/min2/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Less rain", "More rain"],
    icon: {
      light: SoilMoistureLight,
      dark: SoilMoistureDark,
    },
    explorerLabel: "rain intensity",
    moreInfo: {
      title: "Currently raining",
      description: (
        <>
          <p>This layer shows current rainfall intensity.</p>
          <p>Current rain is updated about every 2 minutes.</p>
        </>
      ),
    },
    layerType: 'raster-animated',
    // TODO
    // timeRange: {start, end}
    // timeStep: {minutes: 2},
    // updateTime: (t) => {}
    getDefaultTimeRange: () => {
      const now = new Date();
      // round to previous 2 minute interval
      const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), Math.floor(now.getMinutes() / 2) * 2, 0);
      const start = new Date(end.getFullYear(), end.getMonth(), end.getDate(), end.getHours() - 1, 0, 0);
      return {
        start, end
      }
    },
  },
  {
    id: "hail-hourly",
    title: "Max hail size",
    sub: "hourly",
    url: `${WEATHER_API_URL}/hail-hourly/ImageServer/tile/{z}/{y}/{x}`,
    legendValues: ["Smaller hail", "Larger hail"],
    icon: {
      light: RainfallLight,
      dark: RainfallDark,
    },
    explorerLabel: "hail size",
    moreInfo: {
      title: "max hourly hail size",
      description: (
        <>
          <p>
            This layer shows the maximum hail size in the hour.
          </p>
          <Blurb />
        </>
      ),
    },
  },
];

// Construct a backend query based on the layer
// Originally I did these as a function that lived in each layer object,
// but when the layer is null there's nothing to call so we have react errors
// complaining about hooks being called conditionally.
// This way, there's always a hook to call (but sometimes it's just disabled).
// Also it may be better if we eventually host the list of available layers on the backend
// later since we'll have to generate it dynamically anyway in that case.
export const useMapExplorerData = (
  coords?: [number, number] | undefined,
  layer?: MapLayer,
  unitsSystem?: UnitsSystem
) => {
  const supportedLayer = true; // ["last-48", "soil-moisture"].includes(layer?.id);
  const isPrecipLayer = ["last-12", "last-24", "last-48", "last-168"].includes(
    layer?.id
  );

  const debouncedCoords = useDebounce(coords, EXPLORER_DEBOUNCE_MS);
  const precipData: any = {
    endpoint: isPrecipLayer ? layer?.id : "daily",
    lon: debouncedCoords?.[0],
    lat: debouncedCoords?.[1],
    enabled: !!layer?.id && !!debouncedCoords && supportedLayer,
  };
  if (layer?.id === "soil-moisture") {
    precipData.model = "sport-vsm-0-10cm";
    precipData.start = new Date(Date.now() - 24 * 60 * 60 * 1000);
    precipData.end = new Date();
  }
  let { isLoading, error, data } = usePrecipData(precipData);
  if (layer?.id === "soil-moisture") {
    data = fmtPercent(
      data?.features?.[0].properties?.days?.[0]?.["sport-vsm-0-10cm"] * 100
    );
  } else if (isPrecipLayer) {
    const fmtRain = unitsSystem === "metric" ? fmtRainM : fmtRainI;
    data = fmtRain(data?.features?.[0].properties?.precip);
  }
  return {
    isLoading,
    error: !supportedLayer
      ? new Error("Layer not supported")
      : !layer
      ? new Error("No layer")
      : error,
    data,
  };
};

// Component to list layers and toggle on/off
// TODO - let them set layers which are pay gated

export default function Layers({
  currentLayer,
  visible = mapLayers.map((l) => l.id),
  disabled = [],
  onMoreInfo,
  requiresUpgrade = [],
  onUpgradeIntent,
  onLayerChange,
}: {
  currentLayer: MapLayerKey | null;
  visible?: MapLayerKey[];
  disabled?: MapLayerKey[];
  onMoreInfo?: (layer: MapLayer) => void;
  requiresUpgrade?: MapLayerKey[];
  onUpgradeIntent?: () => void;
  onLayerChange: (layer: MapLayer) => void;
}) {
  return (
    <div className={styles.list}>
      {mapLayers.map((layer) => {
        if (!visible.includes(layer.id)) return null;
        const isOn = currentLayer === layer.id;
        return (
          <LayerListItem
            key={layer.id}
            disabled={disabled.includes(layer.id)}
            requiresUpgrade={requiresUpgrade.includes(layer.id)}
            onUpgradeIntent={onUpgradeIntent}
            onMoreInfo={onMoreInfo}
            layer={layer}
            isOn={isOn}
            toggle={() => (isOn ? onLayerChange(null) : onLayerChange(layer))}
          />
        );
      })}
    </div>
  );
}

const LayerListItem = ({
  layer,
  isOn,
  toggle,
  disabled,
  onMoreInfo,
  requiresUpgrade,
  onUpgradeIntent,
}: {
  layer: MapLayer;
  isOn: boolean;
  toggle: () => void;
  disabled?: boolean;
  onMoreInfo?: (layer: MapLayer) => void;
  requiresUpgrade?: boolean;
  onUpgradeIntent?: () => void;
}) => {
  const isDarkMode = useDarkMode();

  return (
    <button
      className={
        "flex-row gap-4 justify-between align-center text-left " + styles.item
      }
      onClick={() => (requiresUpgrade ? onUpgradeIntent() : toggle())}
      disabled={disabled}
    >
      <div className="flex-row gap-4 justify-start align-center">
        <div
          className={[
            "no-shrink",
            styles.icon,
            isOn ? styles.selected : "",
            requiresUpgrade ? styles.requiresUpgrade : "",
          ].join(" ")}
        >
          <div className={styles.check}>
            <CheckIcon />
          </div>
          <div className={styles.lock}>
            <LockClosedIcon />
          </div>
          <img
            className={[styles.image, isOn ? styles.active : ""].join(" ")}
            src={isDarkMode ? layer.icon.dark : layer.icon.light}
          />
        </div>
        <div className="flex-col align-start">
          <div className={styles.title}>{layer.title}</div>
          <div className={styles.sub}>{layer.sub}</div>
        </div>
      </div>
      {layer?.moreInfo && (
        <div
          className="pad-4 no-shrink"
          style={{
            opacity: 0.3,
            marginRight: "-0.5rem",
          }}
          onClick={(e) => {
            e.stopPropagation();
            onMoreInfo && onMoreInfo(layer);
          }}
        >
          <Question size={22} />
        </div>
      )}
    </button>
  );
};
