// @ts-nocheck

import { Helmet } from "react-helmet-async";

import PageLayout from "../layouts/PageLayout";
import Comparer from "brand/components/map/Comparer";
import { Badge } from "brand/components/Badge";
import { useResizeObserver } from "usehooks-ts";
import { Map } from "./CompareMap";
import syncMaps from "./CompareMap/syncMaps";
import { useEffect, useRef, useState, useCallback } from "react";
import GeoSearch from "./CompareMap/GeoSearch";
import Marker from "./CompareMap/Marker";
import MarkerInner from "./CompareMap/MarkerInner";
import LocationMarkerInner from "./CompareMap/LocationMarkerInner";
import { DateRangePicker } from "brand/components/DatePicker";

import { mngr } from "../store";
import GroupedBar from './CompareMap/GroupedBar';

const WEATHER_API_URL = import.meta.env.VITE_APP_DATA_BASE_URL;

export async function getSamples(
  lngLat: mapboxgl.LngLatLike,
  layer: { startTime: Date; endTime: Date; name: string, label: string },
  signal?: AbortSignal
): Promise<{ date: Date; value: number; source: string }[]> {
  const { lng, lat } = lngLat;
  // const mosaicRule = {
  //   "mosaicMethod": "esriMosaicNone",
  //   "mosaicOperation": "MT_SUM",
  // };
  // &mosaicRule=${mosaicRule}
  return fetch(
    `${WEATHER_API_URL}/api/v1/map/${
      layer.name
    }/ImageServer/getSamples?time=${layer.startTime.getTime()},${layer.endTime.getTime()}&geometryType=esriGeometryMultipoint&geometry={"points":[[${lng},${lat}]]}`,
    {
      signal,
    }
  )
    .then((r) => r.json())
    .then((d) => {
      // just the one point so we take [0]
      return d.samples.map(v => {
        return {
          date: new Date(v.startTime),
          value: v.value,
          source: layer.label
        }
      });
    })
    .catch((e) => {
      console.log("error", e);
    });
}

let controllerGlobal;
let signalGlobal;

async function getBothSamples(
  lngLat: mapboxgl.LngLatLike,
  layer: { startTime: Date; endTime: Date }
): Promise<[number, number]> {
  if (controllerGlobal) controllerGlobal.abort({});
  controllerGlobal = new AbortController();
  signalGlobal = controllerGlobal.signal;

  const [leftValue, rightValue] = await Promise.all([
    getSamples(lngLat, { ...layer, name: layer.leftName, label: layer.leftLabel }, signalGlobal),
    getSamples(lngLat, { ...layer, name: layer.rightName, label: layer.rightLabel }, signalGlobal),
  ]);
  return [leftValue, rightValue];
}

function makeSource(name, layer) {
  return {
    type: "raster",
    tiles: [
      `${WEATHER_API_URL}/api/v1/map/${name}/ImageServer/tile/{z}/{y}/{x}?time=${layer.startTime.getTime()},${layer.endTime.getTime()}`,
    ],
    minzoom: 4,
    maxzoom: 7,
  };
}

function stationCircles(layer) {
  return {
    id: "stations",
    type: "circle",
    source: {
      // type: "geojson",
      // data: `${WEATHER_API_URL}/api/v1/compare/stations.geojson`,
      type: "vector",
      tiles: [
        `${WEATHER_API_URL}/api/v1/compare/stations/{z}/{y}/{x}.mvt?model=daily,cpc-daily&time=${layer.startTime.getTime()},${layer.endTime.getTime()}`,
      ],
      minzoom: 4,
      maxzoom: 4,
    },
    "source-layer": "geojsonLayer",
    paint: {
      "circle-radius": 5,
      "circle-color": "blue",
    },
  };
}

function sum(arr, accessor = d => d) {
  if (!arr) return NaN;
  return arr.filter((d) => !isNaN(accessor(d))).reduce((acc, d) => acc + accessor(d), 0);
}

// function MAE(arr, k) {
//   if (arr.length === 0) return "... ";
//   return (
//     sum(arr.map((d) => Math.abs(d["station"] - d[k]))) / arr.length
//   ).toFixed(2);
// }

// function RMSE(arr, k) {
//   if (arr.length === 0) return "... ";
//   return Math.sqrt(
//     sum(arr.map((d) => Math.pow(d["station"] - d[k], 2))) / arr.length
//   ).toFixed(2);
// }

export default function Compare() {
  const [stations, setStations] = useState([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } = useResizeObserver({
    ref: containerRef,
    box: "border-box",
  });

  const comparerRef = useRef(null);

  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();

  const now = new Date();
  const roundedTo12UTC = new Date(now.getTime() - (now.getTime() % (24 * 60 * 60 * 1000)) - 12 * 60 * 60 * 1000 );
  const sevenDaysAgo = new Date(roundedTo12UTC.getTime() - 7 * 24 * 60 * 60 * 1000);
  // round to midday UTC
  const sixDaysAgo = new Date(roundedTo12UTC.getTime() - 6 * 24 * 60 * 60 * 1000);
  // const fiveDaysAgo = new Date(roundedTo12UTC.getTime() - 5 * 24 * 60 * 60 * 1000);
  const minDate = new Date("2024-01-01");
  const maxDate = sixDaysAgo;
  const [layer, setLayer] = useState({
    startTime: sevenDaysAgo,
    endTime: sixDaysAgo,
    leftName: "era5land",
    leftLabel: "ERA5-Land",
    // leftName: "cpc-daily",
    rightName: "daily",
    rightLabel: "Precip",
  });
  const layerRef = useRef(layer);

  const [query, setQuery] = useState({
    leftValue: [],
    rightValue: [],
    loading: false,
    lngLat: undefined,
    // pos: undefined,
  });

  const { preferredUnits } = mngr.useUnits();


  const mapLeftRef = useRef();
  const mapRightRef = useRef();
  // window.mapLeft = mapLeftRef.current;
  // window.mapRight = mapRightRef.current;

  useEffect(() => {
    // TODO use
    syncMaps(mapLeftRef.current, mapRightRef.current);
    mapLeftRef.current.on("sourcedata", (e) => {
      if (e.sourceId === "stations") {
        const features = mapLeftRef.current.querySourceFeatures("stations", {
          sourceLayer: "geojsonLayer",
        });
        setStations(features.map((f) => f.properties));
      }
    });
  }, []);

  const onClick = useCallback(
    async (lngLat: mapboxgl.LngLatLike) => {
      setQuery({
        leftValue: [],
        rightValue: [],
        loading: true,
        lngLat,
        // pos: undefined,
      });
      const [leftValue, rightValue] = await getBothSamples(lngLat, layerRef.current);
      // const pos = mapLeftRef.current.project(lngLat);
      setQuery({
        leftValue,
        rightValue,
        loading: false,
        lngLat,
        // pos,
      });
    },
    []
  );

  // const onMove = useCallback(() => {
  //   setQuery((q) => ({
  //     ...q,
  //     pos: q.lngLat ? mapLeftRef.current.project(q.lngLat) : undefined,
  //   }));
  // }, []);

  useEffect(() => {
    console.log("set tiles");
    if (
      !mapLeftRef?.current?.getSource(layer.leftName) ||
      !mapRightRef?.current?.getSource(layer.rightName)
    )
      return;
    mapLeftRef.current
      ?.getSource(layer.leftName)
      .setTiles(makeSource(layer.leftName, layer).tiles);
    mapRightRef.current
      ?.getSource(layer.rightName)
      .setTiles(makeSource(layer.rightName, layer).tiles);
    
    layerRef.current = layer;
  }, [layer.startTime, layer.endTime]);

  return (
    <PageLayout>
      <Helmet>
        <title>Precip | Compare</title>
      </Helmet>
      <div
        className="flex-row gap-2 justify-between align-center"
        style={{
          margin: "1.5rem 0",
        }}
      >
        <div className="flex-row gap-6 align-center">
          <h1 style={{ margin: 0 }}>
            Data quality explorer
            <span style={{ display: "inline-block", width: ".75rem" }} />
            <Badge type="solid">Beta</Badge>
          </h1>
        </div>
        <div className="flex-row gap-4 justify-end align-center">
          <DateRangePicker
            value1={layer.startTime}
            value2={layer.endTime}
            min1={minDate}
            max2={maxDate}
            onChange1={(date) => setLayer((l) => ({ ...l, startTime: date }))}
            onChange2={(date) => setLayer((l) => ({ ...l, endTime: date }))}
            align="end"
          />
          <GeoSearch mapRef={mapLeftRef} />
        </div>
      </div>

      {/* location markers */}
      {Object.values(locations).map((l) => (
        <Marker
          key={`l-${l.properties.id}`}
          mapRef={mapLeftRef}
          location={l}
          isLoading={locationsLoading}
          error={locationsError}
          child={LocationMarkerInner}
          name={l.properties.name}
          onClick={(evt) => {
            evt.stopPropagation();
            const [lng, lat] = l.geometry.coordinates;
            onClick({ lng, lat });
          }}
        />
      ))}
      {Object.values(locations).map((l) => (
        <Marker
          key={`r-${l.properties.id}`}
          mapRef={mapRightRef}
          location={l}
          isLoading={locationsLoading}
          error={locationsError}
          child={LocationMarkerInner}
          name={l.properties.name}
          onClick={(evt) => {
            evt.stopPropagation();
            const [lng, lat] = l.geometry.coordinates;
            onClick({ lng, lat });
          }}
        />
      ))}

      {/* click marker */}

      {comparerRef.current && query.lngLat && (
        <>
          <Marker
            mapRef={mapLeftRef}
            location={{
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [query.lngLat.lng, query.lngLat.lat],
              },
              // properties: { name: "" },
            }}
            child={MarkerInner}
            leftName={layer.leftLabel}
            leftValue={sum(query.leftValue, d => d.value)}
            rightName={layer.rightLabel}
            rightValue={sum(query.rightValue, d => d.value)}
            isLoading={query.loading}
          ></Marker>
          <Marker
            mapRef={mapRightRef}
            location={{
              type: "Feature",
              geometry: {
                type: "Point",
                coordinates: [query.lngLat.lng, query.lngLat.lat],
              },
              // properties: { name: "" },
            }}
            child={MarkerInner}
            leftName={layer.leftLabel}
            leftValue={sum(query.leftValue, d => d.value)}
            rightName={layer.rightLabel}
            rightValue={sum(query.rightValue, d => d.value)}
            isLoading={query.loading}
          ></Marker>
        </>
      )}

      <Comparer
        containerRef={comparerRef}
        cell1={
          <div
            style={{
              width: containerWidth,
              height: containerHeight,
              background: "var(--active-primary)",
            }}
          >
            <Map
              mapRef={mapLeftRef}
              onClick={onClick}
              layers={[
                {
                  id: layer.leftName,
                  type: "raster",
                  source: makeSource(layer.leftName, layer),
                  paint: {
                    "raster-opacity": 0.7,
                  },
                },
                // stationCircles(layer),
              ]}
            />
            {/* <img
              style={{ width: "100%" }}
              src="https://opidesign.net/wp-content/uploads/landscape-architecture-fun-facts-outside-productions-blog.jpg"
            /> */}
          </div>
        }
        cell2={
          <div
            ref={containerRef}
            style={{
              width: "100%",
              height: "100%",
              background: "var(--active-secondary)",
            }}
          >
            <Map
              mapRef={mapRightRef}
              onClick={onClick}
              layers={[
                {
                  id: layer.rightName,
                  type: "raster",
                  source: makeSource(layer.rightName, layer),
                  paint: {
                    "raster-opacity": 0.7,
                  },
                },
                // stationCircles(layer),
              ]}
            />
            {/* <img
              style={{ width: "100%", filter: "invert(1)" }}
              src="https://opidesign.net/wp-content/uploads/landscape-architecture-fun-facts-outside-productions-blog.jpg"
            /> */}
          </div>
        }
      />
      <div className="flex-row gap-8 justify-between"  style={{'paddingTop': '1rem'}}>
        <h2>ERA5-Land</h2>
        {/* <div>v</div> */}
        <h2>Precip</h2>
      </div>
      <div>
        Daily precipitation accumulation comparison. All values are in {preferredUnits === "metric" ? "mm" : "in"}.
        The map shows the total accumulation for the date range. The plot shows daily data for the clicked location.
      </div>
      <div className="flex-row gap-8 justify-between">
      {((query.leftValue?.length > 0) &&  (query.rightValue?.length > 0)) ? 
          <GroupedBar 
            data={[...query.leftValue, ...query.rightValue]}
            height={200}
          /> : <div style={{height: '200px'}}>Click the map to see the daily data.</div>}
      </div>
      
      {/* <div className="flex-row gap-8 justify-between">
        <div className="flex-1">
          <h2 style={{ marginBottom: 0 }}>{layer.leftLabel}</h2>
          <br />
          <table className={tableStyles.linedEdges}>
            <tbody>
              <tr>
                <th>MAE</th>
                <td>
                  <Stat number={MAE(stations, "cpc-daily")} unit={"mm"} />
                </td>
              </tr>
              <tr>
                <th>RMSE</th>
                <td>
                  <Stat number={RMSE(stations, "cpc-daily")} unit={"mm"} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex-1">
          <h2 style={{ marginBottom: 0 }}>{layer.rightLabel}</h2>
          <br />
         <table className={tableStyles.linedEdges}>
            <tbody>
              <tr>
                <th>MAE</th>
                <td>
                  <Stat number={MAE(stations, "daily")} unit={"mm"} />
                </td>
              </tr>
              <tr>
                <th>RMSE</th>
                <td>
                  <Stat number={RMSE(stations, "daily")} unit={"mm"} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex-1">
        <br />
        <br />
        <table className={tableStyles.linedEdges}>
          <tbody>
            <tr>
              <th>Gauge Count</th>
              <td>
                <Stat number={stations.length} unit={""} />
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}




    </PageLayout>
  );
}
