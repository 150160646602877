import mapboxgl from "mapbox-gl";
import { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { LocationFeature } from "shared";

export default function Marker({
  mapRef,
  location,
  // child(props) below instead of {children} to ensure rerender of marker on prop change
  child,
  ...props
}: {
  mapRef: React.RefObject<mapboxgl.Map>;
  location: LocationFeature;
  child: React.FunctionComponent;
}) {
  const mk = useMemo(() => {
    const el = document.createElement("div");
    const marker = new mapboxgl.Marker({ element: el, anchor: "bottom" })
      .setLngLat(location.geometry.coordinates)
      .addTo(mapRef.current as mapboxgl.Map);
    return marker;
  }, []);

  useEffect(() => {
    mk.setLngLat(location.geometry.coordinates);
  }, [location.geometry.coordinates]);

  return createPortal(<>{child(props)}</>, mk.getElement());
}
