import {
  ArrowRightIcon,
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@radix-ui/react-icons";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css"; // default

import "../css/react-day-picker.css"; // our css var overrides
import styles from "./DatePicker.module.css"; // styles not related to react-day-picker
import Popover from "./Popover";
import Button from "./Button";
import { useState } from "react";
import { addMonths, format, set } from "date-fns";

const dateFormat = "MM/dd/yyyy";

// Uses react-day-picker
// Docs: https://react-day-picker.js.org/

export default function DatePicker({
  value,
  onChange,
  disabled,
  min1 = new Date("Jan 1, 2020"),
  max2 = new Date(),
  align = "start",
  side = "bottom",
}: {
  value: Date;
  onChange: (date: Date) => void;
  disabled?: boolean;
  min1?: Date;
  max2?: Date;
  align?: "start" | "center" | "end";
  side?: "top" | "bottom" | "left" | "right";
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      autoWidth
      close={false}
      arrow={false}
      side={side}
      align={align}
      trigger={
        <Button secondary>
          <CalendarIcon />
          {format(value, dateFormat)}
        </Button>
      }
    >
      {/* TODO - input here too? */}

      <div className="position-relative">
        {/* This actually comes with react-day-picker,
      but goes away when you enable the "dropdown" mode */}
        {/* TODO - component-ize this */}
        <div
          className="flex-row gap-1"
          style={{
            position: "absolute",
            top: "-0.25rem",
            right: "-0.25rem",
          }}
        >
          <Button onClick={() => onChange(addMonths(value, -1))} small tertiary>
            <ChevronLeftIcon />
          </Button>
          <Button onClick={() => onChange(addMonths(value, 1))} small tertiary>
            <ChevronRightIcon />
          </Button>
        </div>

        <DayPicker
          mode="single"
          captionLayout="dropdown"
          selected={value} // selected day
          month={value} // month that's shown, whether or not a day in that month is selected
          onSelect={onChange}
          disabled={disabled}
          fromDate={min1}
          toDate={max2}
          fromYear={min1.getFullYear()} // required for captionLayout="dropdown"
          toYear={max2.getFullYear()} // required for captionLayout="dropdown"
          required // A date must be selected
          fixedWeeks // prevents layout jolts
          showOutsideDays // required for fixedWeeks
        />
      </div>
    </Popover>
  );
}

/* There is a "range" mode of react-day-picker, but it wasn't that nice IMO.
It made it harder to add inputs for both dates.
Instead we just use two single-mode pickers. */
export function DateRangePicker({
  value1,
  value2,
  onChange1,
  onChange2,
  min1 = new Date("Jan 1, 2020"),
  max2 = new Date(),
  disabled,
  align = "start",
  side = "bottom",
}: {
  value1: Date;
  value2: Date;
  onChange1: (date: Date) => void;
  onChange2: (date: Date) => void;
  min1?: Date;
  max2?: Date;
  disabled?: boolean;
  align?: "start" | "center" | "end";
  side?: "top" | "bottom" | "left" | "right";
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [date1, setDate1] = useState(value1);
  const [date2, setDate2] = useState(value2);
  const [input1, setInput1] = useState(format(value1, dateFormat));
  const [input2, setInput2] = useState(format(value2, dateFormat));

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      autoWidth
      close={false}
      arrow={false}
      side={side}
      align={align}
      trigger={
        <Button secondary>
          <CalendarIcon />
          {format(value1, dateFormat)}
          <ArrowRightIcon />
          {format(value2, dateFormat)}
        </Button>
      }
    >
      <div className="flex-row gap-8">
        <div className="flex-col gap-4 position-relative">
          <div className="flex-col pad-2">
            <label>From</label>
            <input
              type="text"
              value={input1}
              onChange={(e) => {
                const value = e.target.value;
                setInput1(value);
                const newDate = new Date(value);
                if (isNaN(newDate.getTime())) return;
                // cannot be in the middle ages
                if (newDate.getFullYear() < min1.getFullYear() - 100) return;
                setDate1(newDate);
              }}
            />
          </div>

          <div className="position-relative">
            {/* This actually comes with react-day-picker,
          but goes away when you enable the "dropdown" mode */}
            {/* TODO - component-ize this */}
            <div
              className="flex-row gap-1"
              style={{
                position: "absolute",
                top: "-0.25rem",
                right: "-0.25rem",
              }}
            >
              <Button
                onClick={() => {
                  const newDate = addMonths(date1, -1);
                  setDate1(newDate);
                  setInput1(format(newDate, dateFormat));
                  onChange1(newDate);
                }}
                small
                tertiary
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                onClick={() => {
                  const newDate = addMonths(date1, 1);
                  setDate1(newDate);
                  setInput1(format(newDate, dateFormat));
                  onChange1(newDate);
                }}
                small
                tertiary
              >
                <ChevronRightIcon />
              </Button>
            </div>

            <DayPicker
              mode="single"
              captionLayout="dropdown"
              selected={date1} // selected day
              month={date1} // month that's shown, whether or not a day in that month is selected
              onMonthChange={(date: Date) => {
                setDate1(date);
                setInput1(format(date, dateFormat));
                onChange1(date);
              }}
              disabled={disabled}
              fromDate={min1}
              toDate={max2}
              fromYear={min1.getFullYear()} // required for captionLayout="dropdown"
              toYear={max2.getFullYear()} // required for captionLayout="dropdown"
              onSelect={(date: Date) => {
                setDate1(date);
                setInput1(format(date, dateFormat));
                onChange1(date);
              }}
              required // A date must be selected
              fixedWeeks // prevents layout jolts
              showOutsideDays // required for fixedWeeks
            />
          </div>
        </div>
        <div className="flex-col gap-4 position-relative">
          <div className="flex-col pad-2">
            <label>To</label>
            <input
              type="text"
              value={input2}
              onChange={(e) => {
                const value = e.target.value;
                setInput2(value);
                const newDate = new Date(value);
                if (isNaN(newDate.getTime())) return;
                // cannot be in the middle ages
                if (newDate.getFullYear() < min1.getFullYear() - 100) return;
                setDate2(newDate);
              }}
            />
          </div>

          <div className="position-relative">
            {/* This actually comes with react-day-picker,
          but goes away when you enable the "dropdown" mode */}
            {/* TODO - component-ize this */}
            <div
              className="flex-row gap-1"
              style={{
                position: "absolute",
                top: "-0.25rem",
                right: "-0.25rem",
              }}
            >
              <Button
                onClick={() => {
                  setDate2(addMonths(date2, -1));
                  setInput2(format(addMonths(date2, -1), dateFormat));
                  onChange2(addMonths(date2, -1));
                }}
                small
                tertiary
              >
                <ChevronLeftIcon />
              </Button>
              <Button
                onClick={() => {
                  setDate2(addMonths(date2, 1));
                  setInput2(format(addMonths(date2, 1), dateFormat));
                  onChange2(addMonths(date2, 1));
                }}
                small
                tertiary
              >
                <ChevronRightIcon />
              </Button>
            </div>

            <DayPicker
              mode="single"
              captionLayout="dropdown"
              selected={date2} // selected day
              month={date2} // month that's shown, whether or not a day in that month is selected
              onMonthChange={(date: Date) => {
                setDate2(date);
                setInput2(format(date, dateFormat));
                onChange2(date);
              }}
              disabled={disabled}
              fromDate={min1}
              toDate={max2}
              fromYear={min1.getFullYear()} // required for captionLayout="dropdown"
              toYear={max2.getFullYear()} // required for captionLayout="dropdown"
              onSelect={(date: Date) => {
                setDate2(date);
                setInput2(format(date, dateFormat));
                onChange2(date);
              }}
              required // A date must be selected
              fixedWeeks // prevents layout jolts
              showOutsideDays // required for fixedWeeks
            />
          </div>
        </div>
      </div>
    </Popover>
  );
}

// TODO - upgrade or remove entirely
export function DateTimePicker({
  value,
  onChange,
  disabled,
}: {
  value: string;
  onChange: (date: string) => void;
  disabled?: boolean;
}) {
  return (
    <>
      <input
        className={styles.datePicker}
        type="datetime-local"
        disabled={disabled}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </>
  );
}
