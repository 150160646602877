const RIGHT_OFFSET = 3;
const LEFT_OFFSET = 30;
const FLIP_THRESHOLD = 80;

export default function Now({
  nowDistance,
  maxDistance,
  height,
}: {
  nowDistance: number;
  maxDistance: number;
  height: number;
}) {
  const now = new Date();
  const flip = nowDistance > maxDistance - FLIP_THRESHOLD;

  return (
    <g>
      <text
        x={flip ? nowDistance - LEFT_OFFSET : nowDistance + RIGHT_OFFSET}
        y={10}
        fill="var(--chart-axis)"
        fontSize="var(--xs)"
        style={{
          cursor: "default",
        }}
      >
        Now
        <title>{now.toLocaleTimeString()}</title>
      </text>
      <line
        x1={nowDistance}
        x2={nowDistance}
        y1={0}
        y2={height}
        stroke="var(--chart-axis)"
        strokeWidth="1"
        strokeDasharray="2"
      />
    </g>
  );
}
