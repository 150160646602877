import { useState } from "react";
import { Helmet } from "react-helmet-async";

import PageLayout from "../layouts/PageLayout";
import LocationHeader from "../components/LocationHeader";
import LocationTable from "../components/LocationTable";
import { WeatherFactorId } from "shared";
import { mngr } from "../store";
import Card from "brand/components/Card";
import Button from "brand/components/Button";
import Background from "brand/components/Background";

export default function LocationsPage() {
  const [filterQuery, setFilterQuery] = useState("");
  const [mode, setMode] = useState<WeatherFactorId>("precip");
  const { loading: userLoading, error: userError } = mngr.useUser();

  const { atLocationLimit } = mngr.useSubscription();

  return (
    <PageLayout>
      <Helmet>
        <title>Precip | Locations</title>
      </Helmet>

      <LocationHeader
        filterQuery={filterQuery}
        setFilterQuery={setFilterQuery}
        mode={mode}
        setMode={setMode}
      />
      <LocationTable filterQuery={filterQuery} mode={mode} />
      <div style={{ marginTop: "2rem" }} />
      {atLocationLimit && !userLoading && !userError && (
        <Card>
          <div
            style={{
              position: "relative",
              margin: "-2rem",
            }}
          >
            <Background topo fade="center" />
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "3rem",
                zIndex: 100,
              }}
            >
              <h3 style={{ margin: "0.5rem 0" }}>Unlock unlimited locations</h3>
              <p style={{ margin: "0 0 1rem" }}>
                Real-time ground conditions, everywhere you work
              </p>
              <Button
                primary
                large
                // fullWidth
                onClick={() =>
                  window.open("https://precip.ai/contact", "_blank")
                }
              >
                Contact us for pricing
              </Button>
            </div>
          </div>
        </Card>
      )}
    </PageLayout>
  );
}
