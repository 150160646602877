import { Helmet } from "react-helmet-async";

import PageLayout from "../../layouts/PageLayout";
import VerticalNav from "brand/components/VerticalNav";
import Organization from "./Organization";
import Members from "./Members";
import Groups from "./Groups";
import Units from "./Units";
import { mngr } from "../../store";

function Settings() {
  const orgId = mngr.useOrgId();
  const isSharedOrg = !!orgId;

  const settingsMenuItems = [];
  if (isSharedOrg) {
    settingsMenuItems.push({
      id: "organization",
      label: "Organization",
      content: <Organization />,
    });
  }
  if (isSharedOrg) {
    settingsMenuItems.push({
      id: "members",
      label: "Members",
      content: <Members />,
    });
  }
  settingsMenuItems.push({
    id: "groups",
    label: "Groups",
    content: <Groups />,
  });
  settingsMenuItems.push({
    id: "units",
    label: "Units",
    content: <Units />,
  });

  return (
    <PageLayout maxWidth={900}>
      <Helmet>
        <title>Precip | Settings</title>
      </Helmet>

      <div
        className="flex-col border-bottom w100"
        style={{
          padding: "2vh 0 1rem",
          margin: "0 0 2rem",
        }}
      >
        <h1>Settings</h1>
      </div>

      <VerticalNav label="Settings options" items={settingsMenuItems} />
    </PageLayout>
  );
}

export default Settings;
