import Stat from "brand/components/Stat";
import Card from "brand/components/Card";
import {
  useLocationDaily,
  pastDays,
  thisMonth,
  sinceJan1,
  fmtRainM,
  fmtRainI,
} from "shared";
import { ErrorState } from "brand/components/EmptyState";
import { mngr } from "../store";

export default function LocationPageStats({ location }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRain = preferredUnits === "metric" ? fmtRainM : fmtRainI;

  // TODO - make useLocationFoo work with null location
  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location);

  if (errorDaily) {
    return (
      <Card>
        <ErrorState error={errorDaily} />
      </Card>
    );
  }

  return (
    <div className="flex-row w100 gap-4 justify-stretch">
      <Card>
        <label>Past 7 days</label>
        <Stat
          isLoading={isLoadingDaily}
          number={
            isLoadingDaily
              ? ""
              : fmtRain(pastDays(dataDaily?.properties?.days, 7))
          }
          color="active"
          align="left"
        />
      </Card>
      <Card>
        <label>This month</label>
        <div className="flex-row gap-4 justify-between align-center">
          <Stat
            isLoading={isLoadingDaily}
            number={
              isLoadingDaily
                ? ""
                : fmtRain(thisMonth(dataDaily?.properties?.days))
            }
            color="active"
            align="left"
          />
          {/* TODO is YoY rainfall a thing? */}
          {/* <Badge type="plain">↓ 3% YoY</Badge> */}
        </div>
      </Card>
      <Card>
        <label>Since Jan 1</label>
        <Stat
          isLoading={isLoadingDaily}
          number={
            isLoadingDaily
              ? ""
              : fmtRain(sinceJan1(dataDaily?.properties?.days))
          }
          color="active"
          align="left"
        />
      </Card>
    </div>
  );
}
