import Button from "brand/components/Button";
import { useState } from "react";

type Endpoint = {
  name: string;
  path: string;
  times: TimeSpan[];
};
type Location = {
  name: string;
  geometry: {
    coordinates: [number, number];
  };
};

type Result = {
  endpoint: Endpoint;
  url: string;
  location: Location;
  timeSpan: TimeSpan;
  duration?: number;
  status: "waiting" | "ok" | "error" | "loading";
  data?: object;
};

type TimeSpan = {
  name: string;
  start: Date;
  end: Date;
};

// via data-query.ts
const WEATHER_API_URL =
  import.meta.env?.VITE_APP_DATA_BASE_URL || "https://tiles.precip.ai";

// /api/v1/daily?longitude=${longitude}&latitude=${latitude}&start=${start}&end=${end}`
const endpoints: Endpoint[] = [
  {
    name: "Hourly",
    path: "/api/v1/hourly",
    times: [
      {
        name: "past 24 hours",
        start: new Date(Date.now() - 24 * 60 * 60 * 1000),
        end: new Date(),
      },
      {
        name: "next 24 hours",
        start: new Date(),
        end: new Date(Date.now() + 24 * 60 * 60 * 1000),
      },
    ],
  },
  {
    name: "Daily",
    path: "/api/v1/daily",
    times: [
      {
        name: "Year 2023 (Jan-Jan)",
        start: new Date("Jan 1 2023 12:00 am PST"),
        end: new Date("Jan 1 2024 12:00 am PST"),
      },
      {
        name: "July 2023 (30d)",
        start: new Date("Jul 1 2023 12:00 am PST"),
        end: new Date("Jul 31 2023 12:00 am PST"),
      },
    ],
  },
];

const locations = [
  {
    name: "Middle of Nebraska",
    geometry: {
      coordinates: [-99.913361, 41.471475],
    },
  },
  {
    name: "Canada south",
    geometry: {
      coordinates: [-75.0, 48.0],
    },
  },
  {
    name: "Canada north of the border",
    geometry: {
      coordinates: [-100.0, 50.0],
    },
  },
  {
    name: "Canada way north",
    geometry: {
      coordinates: [-100.0, 60.0],
    },
  },
  {
    name: "Off the Atlantic coast",
    geometry: {
      coordinates: [-68.0, 40.0],
    },
  },
];

const defaultResults = [];
for (const endpoint of endpoints) {
  for (const location of locations) {
    for (const timeSpan of endpoint.times) {
      const url = `${WEATHER_API_URL}${endpoint.path}?longitude=${
        location.geometry.coordinates[0]
      }&latitude=${
        location.geometry.coordinates[1]
      }&start=${timeSpan.start.toISOString()}&end=${timeSpan.end.toISOString()}`;
      defaultResults.push({
        url,
        endpoint,
        location,
        timeSpan,
        status: "waiting",
        data: undefined,
      } as Result);
    }
  }
}

export default function Test() {
  const [results, setResults] = useState<Result[]>(defaultResults);

  const testUrl = async (url: string) => {
    const lastCalled = new Date();
    setResults((results) => {
      const newResults = [...results];
      const result = newResults.find((r) => r.url === url);
      if (!result) alert("no result");
      result.status = "loading";
      result.duration = undefined;
      return newResults;
    });
    try {
      const response = await fetch(url);
      const data = await response.json();
      setResults((results) => {
        const newResults = [...results];
        const result = newResults.find((r) => r.url === url);
        if (!result) throw new Error("no result");
        result.status = response.ok && data.features.length ? "ok" : "error";
        result.duration = new Date().getTime() - lastCalled.getTime();
        result.data = data;
        return newResults;
      });
    } catch (error) {
      setResults((results) => {
        const newResults = [...results];
        const result = newResults.find((r) => r.url === url);
        if (!result) throw new Error("no result");
        result.status = "error";
        result.duration = new Date().getTime() - lastCalled.getTime();
        result.data = undefined;
        return newResults;
      });
    }
  };

  const isWorking = results.some((result) => result.status === "loading");

  return (
    <div
      style={{
        // background: "var(--background-secondary)",
        flex: "1",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          // background: "var(--background-primary)",
          // borderRadius: "var(--brad-s)",
          // boxShadow: "var(--shadow-s)",
          padding: "1rem",
          margin: "1rem auto",
        }}
      >
        <h1>Test API endpoints</h1>
        <Button
          secondary
          onClick={() => {
            results.map((r) => testUrl(r.url));
          }}
          disabled={isWorking}
        >
          {isWorking ? "Running tests..." : "Test all"}
        </Button>
        <table>
          <tbody>
            {results?.map((result) => (
              <tr key={result.url}>
                <td>
                  <strong>{result.endpoint.name}</strong>
                </td>
                <td>
                  <code>{result.endpoint.path}</code>
                </td>
                <td style={{ fontSize: "var(--s)" }}>{result.location.name}</td>
                <td style={{ fontSize: "var(--s)" }}>{result.timeSpan.name}</td>
                <td>
                  {result.duration
                    ? `${(result.duration / 1000).toFixed(1)}s`
                    : "-"}
                </td>
                <td>
                  {result.status === "waiting"
                    ? "-"
                    : result.status === "ok"
                    ? "✅"
                    : result.status === "loading"
                    ? "⏳"
                    : result.status === "error"
                    ? "❌"
                    : "🚧"}
                </td>
                {/* <td>
                  <code>{JSON.stringify(result.data, null, 2)}</code>
                </td> */}
                <td style={{ width: "80px" }}>
                  <Button
                    secondary
                    small
                    onClick={() => {
                      testUrl(result.url);
                    }}
                    // disabled={result.status === "loading"}
                    working={result.status === "loading"}
                  >
                    {result.status === "loading" ? "..." : "Test"}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
