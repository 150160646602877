import { useState } from "react";
import { Helmet } from "react-helmet-async";

import Button from "brand/components/Button";

export default function ErrorPage({ error }: { error?: Error }) {
  const [showError, setShowError] = useState(false);

  const headline = error
    ? "Whoops, something went wrong"
    : "Whoops, this page has moved";

  return (
    <div
      style={{
        maxWidth: "800px",
        margin: "10vh auto",
      }}
    >
      <Helmet>
        <title>Precip | Error</title>
      </Helmet>

      <h1>{headline}</h1>
      <br />
      <p>Sorry about that, it's our fault.</p>
      {error && (
        <p>
          {showError ? (
            <code style={{ color: "red" }}>{error.message}</code>
          ) : (
            <button
              style={{
                fontSize: "var(--s)",
                textDecoration: "underline",
              }}
              onClick={() => setShowError(true)}
            >
              Show error
            </button>
          )}
        </p>
      )}
      <Button secondary onClick={() => window.location.replace("/")}>
        Go home
      </Button>
    </div>
  );
}
