import styles from "./Raindrops.module.css";

export const Raindrops = ({ color }: { color: "blue" | "white" }) => (
  <div>
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "5%",
        animationDelay: "0.55s",
        animationDuration: "0.5s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "15%",
        animationDelay: "0.25s",
        animationDuration: "0.6s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "25%",
        animationDelay: "0.85s",
        animationDuration: "0.5s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "35%",
        animationDelay: "0.65s",
        animationDuration: "0.4s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "45%",
        animationDelay: "0.35s",
        animationDuration: "0.6s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "55%",
        animationDelay: "0.15s",
        animationDuration: "0.5s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "65%",
        animationDelay: "0.95s",
        animationDuration: "0.4s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "75%",
        animationDelay: "0.45s",
        animationDuration: "0.5s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "85%",
        animationDelay: "0.55s",
        animationDuration: "0.6s",
      }}
    />
    <div
      className={`${styles.drop} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "95%",
        animationDelay: "0.75s",
        animationDuration: "0.4s",
      }}
    />
  </div>
);

export const Snowfall = ({ color }: { color: "blue" | "white" }) => (
  <div>
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "5%",
        animationDelay: "0.55s",
        animationDuration: "1.5s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "15%",
        animationDelay: "0.25s",
        animationDuration: "1.6s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "25%",
        animationDelay: "0.85s",
        animationDuration: "1.5s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "35%",
        animationDelay: "0.65s",
        animationDuration: "1.4s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "45%",
        animationDelay: "0.35s",
        animationDuration: "1.6s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "55%",
        animationDelay: "0.15s",
        animationDuration: "1.5s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "65%",
        animationDelay: "0.95s",
        animationDuration: "1.4s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "75%",
        animationDelay: "0.45s",
        animationDuration: "1.5s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "85%",
        animationDelay: "0.55s",
        animationDuration: "1.6s",
      }}
    />
    <div
      className={`${styles.flake} ${color === "blue" ? styles.blue : ""}`}
      style={{
        left: "95%",
        animationDelay: "0.75s",
        animationDuration: "1.4s",
      }}
    />
  </div>
);
