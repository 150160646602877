import { QueryClient } from "@tanstack/react-query";
import {
  ENTERPRISE_BETA_CUSTOMER_EMAIL_DOMAINS,
  ENTERPRISE_CUSTOMER_EMAIL_DOMAINS,
  ENTERPRISE_CUSTOMER_INDIVIDUAL_EMAILS,
  MAX_ENTERPRISE_LOCATIONS,
  MAX_FREE_LOCATIONS,
  StateManager,
} from "shared";

// THIS HAS THE SAME LOGIC AS THE APP STORE JUST WITHOUT REVENUECAT
class DashboardStateManager extends StateManager {
  useSubscription() {
    const email = this.backendUser?.user?.email;

    // check for enterprise membership
    const isPartOfEnterpriseOrg = ENTERPRISE_CUSTOMER_EMAIL_DOMAINS.some(
      (domain) => email?.includes(domain)
    );
    const isEnterpriseUser = ENTERPRISE_CUSTOMER_INDIVIDUAL_EMAILS.map((e) =>
      e.toLocaleLowerCase()
    ).includes((email || "").toLocaleLowerCase());
    const hasEnterpriseTierAccess =
      email && (isPartOfEnterpriseOrg || isEnterpriseUser);

    const hasEnterpriseBetaAccess = ENTERPRISE_BETA_CUSTOMER_EMAIL_DOMAINS.some(
      (domain) => email?.includes(domain)
    );

    // calculate location limits
    const locationCount = Object.keys(this.locations || {}).length;
    const maxUserLocations = hasEnterpriseTierAccess
      ? MAX_ENTERPRISE_LOCATIONS
      : MAX_FREE_LOCATIONS;
    const atLocationLimit = locationCount >= maxUserLocations;
    const aboveLocationLimit = locationCount > maxUserLocations;

    return {
      hasEnterpriseTierAccess,
      hasEnterpriseBetaAccess,
      maxUserLocations,
      atLocationLimit,
      aboveLocationLimit,
    };
  }
}

export const queryClient = new QueryClient();
export const mngr = new DashboardStateManager();

// @ts-ignore
window.mngr = mngr;
