import { useEffect, useState } from "react";
import { useDarkMode } from "./useDarkMode";
import { mapLayers, MapLayerKey } from "brand/components/map/Layers";
import mapboxgl from "mapbox-gl";
import { addLayer } from 'shared/layers';


// TODO - calculate base layer, raster layers etc and return a single style
// object to the map to render (so we don't have to deal with all the race
// conditions and state management that Mapbox doesn't let us see)

type BaseLayer = "satellite" | "streets";
type RasterLayer = MapLayerKey | null;


export const useMapStyles = ({
  map,
  isAdding,
  defaultBaseLayer = "satellite",
  defaultRasterLayer = mapLayers[0].id,
}: {
  map: mapboxgl.Map;
  isAdding: boolean;
  defaultBaseLayer?: BaseLayer;
  defaultRasterLayer?: RasterLayer;
}) => {
  const isDarkMode = useDarkMode();

  const [baseLayer, setBaseLayer] = useState<BaseLayer>(defaultBaseLayer);
  const [rasterLayer, setRasterLayer] =
    useState<RasterLayer>(defaultRasterLayer);

  const roadStyle = isDarkMode
    ? "mapbox://styles/mapbox/navigation-night-v1"
    : "mapbox://styles/mapbox/streets-v12";
  const satelliteStyle = "mapbox://styles/mapbox/satellite-streets-v12";

  const currentStyle = baseLayer === "streets" ? roadStyle : satelliteStyle;

  // TODO - bring back changing colors when the new backend has support for inverse,
  // taking colors in the request params, or coloring client-side.
  // Remove invertScale and use darkMapColors instead
  const invertScale = true;
  const darkMapColors = isDarkMode || baseLayer === "satellite";

  const baseLayerOpacity = baseLayer === "satellite" ? 0.3 : 1;
  const rasterLayerOpacity = 0.8;

  const baseLayerLoaded = map?.getLayer(baseLayer);
  const isStyleLoaded = map?.isStyleLoaded();

  // update base layer
  useEffect(() => {
    map?.setStyle(currentStyle);
  }, [currentStyle]);

  // update base layer opacity
  useEffect(() => {
    if (!map || !map?.isStyleLoaded() || !map?.getLayer(baseLayer)) return;
    map.setPaintProperty(baseLayer, "raster-opacity", baseLayerOpacity);
  }, [isStyleLoaded, baseLayerLoaded, baseLayer, baseLayerOpacity]);

  // update raster layer
  useEffect(() => {
    if (!map || !map?.isStyleLoaded()) return;
    for (const l of mapLayers) {
      if (l.id === rasterLayer && !isAdding) {
        // turn on the selected layer if it exists
        if (map?.getLayer(l.id)) {
          map?.setLayoutProperty(rasterLayer, "visibility", "visible");
          console.log("Setting layer visible", l);
        } else {
          console.log("Adding layer", l);
          addLayer(map, l);
        }
      } else {
        // turn off all other layers
        if (map?.getLayer(l.id)) {
          console.log("Setting layer INvisible", l);
          // map?.setPaintProperty(l.id, "raster-opacity", 0);
          map?.setLayoutProperty(l.id, "visibility", "none");
        }
      }
    }


  }, [baseLayer, rasterLayer, isStyleLoaded, isAdding]);

  return {
    darkMapColors,
    invertScale,
    baseLayer,
    setBaseLayer,
    rasterLayer: isAdding ? null : rasterLayer,
    setRasterLayer,
    rasterLayerObj: mapLayers.find((l) => l.id === rasterLayer),
    currentStyle,
  };
};
