import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Card from "brand/components/Card";
import BarChart from "brand/components/chart/BarChart";

import PageLayout from "../layouts/PageLayout";
import LocationMapThumb from "../components/LocationMapThumb";
import { mngr } from "../store";
import {
  useLocationHourly,
  fmtWindM,
  fmtWindI,
  fmtWindUnitM,
  fmtWindUnitI,
  mpsToMph,
  mphToMps,
} from "shared";
import Breadcrumbs from "brand/components/Breadcrumbs";
// import { keyBy } from "shared";
import ErrorPage from "./ErrorPage";
import { Placeholder, Spinner } from "brand/components/Loading";
import styles from "./Location.module.css";
import NoAuthPage from "./NoAuthPage";
import colors from "brand/scripts/colors";
import { CalendarIcon, Crosshair1Icon } from "@radix-ui/react-icons";

export default function LocationWrapped() {
  const { locationId } = useParams();
  const {
    data: locations,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();
  const location = locations?.[locationId];
  if (locationsError) return <ErrorPage error={locationsError} />;
  if (!location) return <NoAuthPage />;
  return <Location location={location} locationsLoading={locationsLoading} />;
}

export const dtfmt = (d) => d.toISOString().slice(0, 10);

function Location({ location, locationsLoading }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtWind = preferredUnits === "metric" ? fmtWindM : fmtWindI;
  const fmtWindUnit = preferredUnits === "metric" ? fmtWindUnitM : fmtWindUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mpsToMph;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : mphToMps;
  const windUnit = preferredUnits === "metric" ? "m/s" : "mph";

  const start = new Date("Jun 14 2023 12:00 am CST");
  const end = new Date("Jun 14 2023 11:59 pm CST");

  const {
    data: dataHourly,
    isLoading: isLoadingHourly,
    error: errorHourly,
  } = useLocationHourly(location, start, end);

  const name = location?.properties?.name;
  const group = mngr.groups?.[location?.properties?.groupId]?.name;

  // locationsLoading probably redundant here but keeping just in case
  const isLoading = isLoadingHourly;

  if (errorHourly) {
    return <ErrorPage error={errorHourly} />;
  }

  const hourlyWindData = dataHourly?.properties?.hours?.map(
    ({ startTime, wind_speed, source }) => {
      return {
        value: toConvertedUnits(wind_speed),
        date: new Date(startTime),
        source,
      };
    }
  );

  const pageName = isLoading ? "..." : name || "Unnamed location";

  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZone: "America/Chicago", // CST timezone
    // hour12: false, // 24-hour format
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit'
  });

  return (
    <PageLayout>
      <Helmet>
        <title>Precip | {pageName}</title>
      </Helmet>

      <div className={styles.columns}>
        <div>
          <div
            className="flex-col border-bottom w100"
            style={{
              padding: "2vh 0 1rem",
              margin: "0 0 2rem",
            }}
          >
            <Breadcrumbs
              pages={[
                { name: "Locations", url: "/locations" },
                { name: pageName },
              ]}
            />
            <div className="flex-row gap-4 justify-between align-end">
              <div className="flex-col gap-2 align-base">
                <h1
                  style={{
                    margin: 0,
                    lineHeight: 1,
                  }}
                >
                  Daily wind report:{" "}
                  {isLoading ? (
                    <Placeholder
                      style={{
                        width: "12rem",
                        height: "2rem",
                      }}
                    />
                  ) : (
                    name || (
                      <span style={{ color: "var(--text-disabled)" }}>
                        Unnamed location
                      </span>
                    )
                  )}
                </h1>
                <div
                  className="flex-row gap-4 align-base"
                  style={{ lineHeight: 1, fontSize: "var(--s)" }}
                >
                  {group && (
                    <span
                      style={{
                        color: "var(--text-tertiary)",
                        margin: "0 .5rem 0 0",
                      }}
                    >
                      {group}
                    </span>
                  )}
                  <div className="flex-row align-end gap-2">
                    <CalendarIcon />
                    <div>{formatter.format(start)}</div>
                  </div>
                  <div className="flex-row align-end gap-2">
                    <Crosshair1Icon />
                    <div>
                      {location?.geometry?.coordinates?.[0].toFixed(4)}
                      {", "}
                      {location?.geometry?.coordinates?.[1].toFixed(4)}
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <LocationMapThumb coordinates={location.geometry.coordinates} />
          <br />

          <Card>
            <h4>Wind speed</h4>
            <BarChart
              xAxisLabel="Time"
              yAxisLabel={`Speed (${windUnit})`}
              xAxisTimeFormat="%m/%d - %I%p"
              unitFormatter={fmtWindUnit}
              toOriginalUnits={toOriginalUnits}
              colorScale={colors["slate"]}
              data={hourlyWindData}
              isLoading={isLoading}
            />
          </Card>

          <Card>
            <h4>Wind hours</h4>
            <div
              style={{
                columns: "3",
                columnGap: "3rem",
              }}
            >
              {isLoading ? (
                <Spinner padded />
              ) : (
                (hourlyWindData || []).map((d) => (
                  <div
                    key={d.date.toISOString()}
                    className="pad-2 border-bottom flex-row align-center justify-between"
                    style={{
                      background:
                        d.value >= 10 ? "var(--item-primary)" : "transparent",
                      fontWeight: d.value >= 10 ? "bold" : "normal",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-tertiary)",
                      }}
                    >
                      {d.date.toLocaleDateString(undefined, {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      })}
                    </div>
                    <div
                      style={{
                        fontSize: "var(--s)",
                        color: "var(--text-primary)",
                      }}
                    >
                      {fmtWind(toOriginalUnits(d.value))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </Card>
        </div>
      </div>
    </PageLayout>
  );
}
