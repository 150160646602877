import { CaretSortIcon } from "@radix-ui/react-icons";
import styles from "./Select.module.css";
import { forwardRef } from "react";

const Select = forwardRef(
  (
    {
      id,
      value,
      options,
      onChange,
      fullWidth,
      minWidth,
      disabled,
      ...props
    }: {
      id?: string;
      value: string;
      options: {
        value: string;
        label: string;
      }[];
      onChange: (value: string) => void;
      minWidth?: number;
      disabled?: boolean;
      fullWidth?: boolean;
    },
    ref
  ) => {
    return (
      <div
        key={id}
        className={styles.selectWrapper}
        style={{
          width: fullWidth ? "100%" : "auto",
          minWidth: minWidth || "auto",
        }}
      >
        <select
          // @ts-ignore
          ref={ref}
          id={id}
          value={value}
          className={styles.select}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          {...props}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className={styles.icon}>
          <CaretSortIcon />
        </div>
      </div>
    );
  }
);

export default Select;
