import { useState } from "react";
import { Helmet } from "react-helmet-async";

import AccountLayout from "../../layouts/AccountLayout";
import Button from "brand/components/Button";
import {
  signInWithGoogle,
  signInWithApple,
  requestLinkForEmailLinkSignIn,
} from "../../Auth";
import { trackEvent } from "../../tracking";

export default function SignIn() {
  // TODO - check for auth and automatically redirect to dashboard if already signed in

  const [showSignInWithEmail, setShowSignInWithEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [isRequestingEmailLink, setIsRequestingEmailLink] = useState(false);

  return (
    <AccountLayout>
      <Helmet>
        <title>Precip | Sign in</title>
      </Helmet>

      <div
        className="pad-4 flex-col gap-4 flex-1"
        style={{
          textAlign: "center",
          minWidth: "360px",
        }}
      >
        <h2>Welcome back</h2>
        <div className="flex-col flex-center gap-2">
          <Button
            authProvider="google"
            secondary
            round
            fullWidth
            onClick={() => {
              signInWithGoogle().then(() => {
                trackEvent("user_signed_in");
                window.location.href = "/";
              });
            }}
          >
            Continue with Google
          </Button>
          <Button
            authProvider="apple"
            secondary
            round
            fullWidth
            onClick={() => {
              signInWithApple().then(() => {
                trackEvent("user_signed_in");
                window.location.href = "/";
              });
            }}
          >
            Continue with Apple
          </Button>
        </div>

        {!showSignInWithEmail ? (
          <Button
            tertiary
            round
            fullWidth
            onClick={() => {
              setShowSignInWithEmail(true);
            }}
          >
            or sign in with email
          </Button>
        ) : (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setIsRequestingEmailLink(true);
              requestLinkForEmailLinkSignIn({ email }).then(({ error }) => {
                setIsRequestingEmailLink(false);
                if (error) {
                  // Common errors: invalid email, invalid or expired OTP
                  // TODO - show in UI, not an alert
                  alert(
                    `Sorry, we could not get a sign in link for you right now. (${error.message} - ${error.code})`
                  );
                } else {
                  trackEvent("user_requested_email_link");
                  alert(
                    `We've sent a link to ${email}. Please check your email to sign in.`
                  );
                }
              });
            }}
          >
            <hr />
            <label style={{ width: "100%", textAlign: "left" }}>
              Your email
            </label>
            <input
              autoFocus
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "100%", margin: "0 0 .5rem" }}
            />
            <Button
              type="submit"
              authProvider="email"
              secondary
              round
              fullWidth
              disabled={isRequestingEmailLink}
            >
              Request email link
            </Button>
          </form>
        )}
        <p
          style={{
            fontSize: "var(--s)",
          }}
        >
          Don't have an account? <a href="/sign-up">Sign up</a>
        </p>
      </div>
    </AccountLayout>
  );
}
