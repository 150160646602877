import { DrawingPinFilledIcon, DrawingPinIcon } from "@radix-ui/react-icons";
import styles from "./Pin.module.css";

const Pin = ({
  isPinned,
  onClick,
}: {
  isPinned: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      className={[styles.pin, isPinned ? styles.pinned : ""].join(" ")}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        onClick && onClick();
      }}
    >
      <div style={{ rotate: "-44deg", scale: "1.2" }}>
        {isPinned ? <DrawingPinFilledIcon /> : <DrawingPinIcon />}
      </div>
    </button>
  );
};

export default Pin;
