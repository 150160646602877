// https://2019.wattenberger.com/blog/d3-interactive-charts
// https://d3-graph-gallery.com/graph/interactivity_tooltip.html
export default function ChartTooltip({
  left,
  maxDistance,
  top,
  height,
  content,
  isVisible,
  dotValue,
  dotColor,
  hideLine,
  boxy,
}: {
  left: number;
  maxDistance: number;
  top: number;
  height: number;
  content: React.ReactNode;
  isVisible: boolean;
  dotValue?: number;
  dotColor?: string;
  hideLine?: boolean;
  boxy?: boolean;
}) {
  const width = 130;
  const flip = left > maxDistance - 200;
  const xOffset = flip ? -width : 8;
  const topOffset = -40;
  const lineOffset = topOffset + 4;

  return (
    <div
      style={{
        position: "absolute",
        top,
        height,
        opacity: isVisible ? 1 : 0,
        pointerEvents: "none",
        zIndex: 300,
      }}
    >
      <div
        style={{
          transform: `translate(${left + xOffset}px, ${topOffset}px)`,
          background: "rgba(var(--background-primary-rgb), .95)",
          backdropFilter: "blur(1px)",
          width,
          padding: boxy ? "8px" : 0,
          borderRadius: boxy ? "var(--brad-s)" : 0,
          boxShadow: boxy ? "var(--shadow-l), var(--shadow-m)" : "none",
        }}
      >
        {content}
      </div>
      {!hideLine && (
        <div
          style={{
            position: "absolute",
            top: lineOffset,
            bottom: 0,
            left: left + "px",
            width: 0,
            borderLeft: "1px solid var(--border)",
          }}
        />
      )}
      {(dotValue || dotValue === 0) && (
        <div
          style={{
            position: "absolute",
            height: "6px",
            width: "6px",
            transform: "translate(-50%, -50%)",
            borderRadius: "var(--brad-full)",
            left: left + "px",
            background: dotColor,
            top: dotValue + "px",
            boxShadow: "0 0 0 3px rgba(var(--background-primary-rgb), 0.8)",
          }}
        />
      )}
    </div>
  );
}
