import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "brand/components/Button";
import Modal from "brand/components/Modal";
import Select from "brand/components/Select";
import { mngr } from "../store";
import { trackEvent } from "../tracking";

// TODO can we import from prisma?
export type LocationForm = {
  name: string;
  groupId: string;
  geometry: Point;
};

type Point = { type: "Point"; coordinates: [number, number] };

export default function AddLocation({
  isOpen,
  setIsOpen,
  geometry,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  geometry: Point;
}) {
  const navigate = useNavigate();

  const {
    data: groups,
    loading: groupsLoading,
    error: groupsError,
  } = mngr.useGroups();
  const [name, setName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [working, setWorking] = useState(false);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setWorking(true);
      trackEvent("location_added");
      const newLocation = await mngr.addLocation({
        type: "Feature",
        properties: { name: name, groupId: groupId },
        geometry,
      });
      const locId = newLocation.properties.id;
      setWorking(false);
      if (locId) {
        setIsOpen(false);
        navigate(`/location/${locId}`);
      }
    } catch (error) {
      setWorking(false);
      alert(
        `Sorry, we couldn't add this location. (${error.code} - ${error.message})`
      );
      console.error("error adding location", error);
    }
  };

  // TODO
  // don't even show the button if you're in an org and don't have permission
  // if (orgId && !isWriteRole(role)) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size="small"
      title="Add a new location"
    >
      <form onSubmit={onSubmit}>
        <div className="flex-col gap-4 justify-between">
          <div className="flex-col gap-4">
            <div>
              <label>Name</label>
              <input
                id="name"
                type="text"
                style={{ width: "100%" }}
                placeholder="Location name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={working}
              />
            </div>
            <div>
              <label>Group</label>
              <Select
                fullWidth
                disabled={working || groupsLoading || Boolean(groupsError)}
                value={groupId}
                onChange={async (value) => {
                  console.log("value", value);
                  if (value === "--new--") {
                    const groupName = prompt("Enter group name");
                    if (!groupName || groupName === "") {
                      setGroupId(undefined);
                      return;
                    }
                    trackEvent("group_added");
                    const group = await mngr.addGroup(groupName);
                    console.log("about to set group id", group.id);
                    setGroupId(group.id);
                  } else {
                    setGroupId(value);
                  }
                }}
                options={[
                  { label: "-- Choose group --", value: null },
                  ...Object.values(groups || {}).map((g) => ({
                    label: g.name,
                    value: g.id,
                  })),
                  { label: "+ New group", value: "--new--" },
                ]}
              />
              {groupsError && (
                <div
                  style={{ color: "var(--text-danger)", fontSize: "var(--s)" }}
                >
                  Sorry, we couldn't load your groups right now
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ marginTop: "1rem" }} />
        <div className="flex-row justify-end align-center gap-2">
          <Button secondary disabled={working} onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            type="submit"
            primary
            disabled={!geometry.coordinates || working}
            working={working}
          >
            Save location
          </Button>
        </div>
      </form>
    </Modal>
  );
}
