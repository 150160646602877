import posthog from "posthog-js";
import { useEffect, useState } from "react";

// keep this type in sync with the list of flags in PostHog
// https://us.posthog.com/project/61663/feature_flags
export const FEATURE_FLAG_IDS = [
  "location_chart_widget",
  "location_cumulative_and_normals",
  "wind_temp_charts",
  "wind_on_locations_list",
  "map_layer_soil_moisture",
  "soil_moisture_feature_on_upsell",
  "app_review_link",
  "login_with_magic_link",
  "calendar_day_hourly_bar_chart",
  "notifications",
  "show_currently_raining",
  "metric_units",
  "locations_list_layout_style",
  "locations_list_rainfall_period",
  "internal_contact_form",
];

type FeatureFlagId = (typeof FEATURE_FLAG_IDS)[number];

// Old flags no longer used
// | "map_layer_toggles"
// | "map_explore_mode"

export default function useFeatureFlag(featureFlagId: FeatureFlagId) {
  // all features are enabled during development
  // TODO - set up posthog using the real React docs and just use the raw posthog hook
  const isDevMode = !import.meta.env.PROD;
  // const isDevMode = false; // debugging only
  const [isEnabled, setIsEnabled] = useState(isDevMode);

  useEffect(() => {
    // posthog.onSessionId((sessionId) => {
    //   console.log("🐎 Session ID", sessionId);
    // });

    const unsubscribe = posthog.onFeatureFlags((enabledIds) => {
      const enabled =
        enabledIds.includes(featureFlagId) &&
        Boolean(posthog.getFeatureFlagPayload(featureFlagId));
      // posthog.isFeatureEnabled(featureFlagId);
      setIsEnabled(enabled);
    });

    return unsubscribe;
  }, []);

  return isEnabled;
}
