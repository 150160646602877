import { GearIcon, ExitIcon, CheckIcon } from "@radix-ui/react-icons";

import { useNavigate } from "react-router-dom";
import Avatar from "brand/components/Avatar";
import DropdownMenu, { DropdownMenuItem } from "brand/components/DropdownMenu";
// import Button from "brand/components/Button";
import { mngr } from "../../store";
import { signOut } from "../../Auth";
import { set as setLocalStorage } from "../../LocalStorage";
import { trackEvent } from "../../tracking";

const AccountDropdown = () => {
  const currentOrgId = mngr.useOrgId();
  const {
    data: backendUser,
    loading: userLoading,
    error: userError,
  } = mngr.useUser();
  const orgs = backendUser?.orgs;
  const displayName = backendUser?.user?.displayName;
  const email = backendUser?.user?.email;
  const photoUrl = backendUser?.user?.photoUrl;
  const navigate = useNavigate();

  // Only let precip founders see their personal org.
  // Soon it should be if they've signed up self-serve too.
  const isPrecipEmail = email?.includes("@getprecip.com");
  const allOrgs = orgs ? [...orgs] : [];
  if (isPrecipEmail) {
    allOrgs.push({ orgId: null, orgName: "Personal" });
  }

  return (
    <>
      <DropdownMenu
        label="Your account"
        align="end"
        sideOffset={12}
        alignOffset={-12}
        trigger={
          <button
            data-test-id="account_dropdown"
            disabled={userLoading || Boolean(userError)}
          >
            <Avatar
              size={30}
              name={displayName}
              image={photoUrl}
              isLoading={userLoading}
              error={userError}
            />
          </button>
        }
      >
        <div
          className="sentry-mask"
          style={{
            display: "flex",
            gap: ".66rem",
            fontSize: "var(--s)",
            margin: "0 0 .5rem",
            lineHeight: "1.2",
            alignItems: "center",
            padding: "1rem 1rem 0",
          }}
        >
          <div>
            <Avatar size={48} name={displayName} image={photoUrl} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ fontWeight: 500, color: "var(--text-primary)" }}>
              {displayName}
            </div>
            <div>{email}</div>
          </div>
        </div>

        <hr />

        {allOrgs?.map(({ orgId, orgName }) => {
          return (
            <DropdownMenuItem
              key={orgId || "personal"}
              onSelect={() => {
                trackEvent("organization_switched");
                mngr.setOrgId(orgId);
                // TODO - move this to state manager once we have
                // a single api for localstorage on both platforms
                setLocalStorage("most_recent_org_id", orgId || "personal");
              }}
            >
              {currentOrgId === orgId ? (
                <CheckIcon />
              ) : (
                <div
                  style={{
                    width: "1rem",
                  }}
                />
              )}
              {orgName}
            </DropdownMenuItem>
          );
        })}

        <DropdownMenuItem
          key="add"
          onSelect={async () => {
            if (backendUser?.user?.email?.includes("@getprecip.com")) {
              const name = prompt(
                "(Precip team only) Name your new organization"
              );
              if (!name || name === "") return;
              trackEvent("organization_added");
              const newOrg = await mngr.createOrg(name);
              if (newOrg) {
                alert(
                  "(Precip team only) Organization created. Refresh to see it in the list and switch."
                );
              }
            } else {
              window.open("https://precip.ai/contact", "_blank");
            }
          }}
        >
          <div
            style={{
              width: "1rem",
            }}
          />
          New organization...
        </DropdownMenuItem>

        <hr />

        <DropdownMenuItem
          onSelect={() => {
            navigate("/settings");
          }}
        >
          <GearIcon />
          Settings
        </DropdownMenuItem>
        <DropdownMenuItem
          onSelect={() => {
            trackEvent("user_signed_out");
            signOut();
          }}
        >
          <ExitIcon />
          Sign out
        </DropdownMenuItem>
      </DropdownMenu>
    </>
  );
};

export default AccountDropdown;
