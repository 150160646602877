import { CaretSortIcon } from "@radix-ui/react-icons";

import styles from "./Comparer.module.css";
import { useEffect, useRef, useState } from "react";

const easeOut = (t) => t * (2 - t);

const duration = 1500;
const start = 0;
const destination = 5000;
const valueMax = 10000;

export default function Comparer({
  cell1,
  cell2,
  containerRef,
}: {
  cell1: React.ReactNode;
  cell2: React.ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
}) {
  const [value, setValue] = useState(start);
  const startTime = useRef(null);
  const isDragging = useRef(false);

  // value max is 10k, not 100, for smooth dragging on large screens
  // ie, percent will be 12.34% for a value of 1234
  const percent = value / 100;

  // animate value on load using an easing function
  useEffect(() => {
    const animate = (now?: number) => {
      if (!now) {
        // keep trying until we have it
        requestAnimationFrame(animate);
        return;
      }
      if (!startTime.current) startTime.current = now;
      const progress = Math.min(1, (now - startTime.current) / duration);
      const easedProgress = easeOut(progress);
      const value = easedProgress * (destination - start) + start;
      setValue(value);
      if (value < destination) {
        requestAnimationFrame(animate);
      }
    };
    animate();
  }, []);

  const handleMouseDown = () => {
    isDragging.current = true;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!isDragging.current) return;

    const containerWidth = containerRef.current.offsetWidth;
    const scrubberWidth = 2;
    const offsetX =
      event.clientX - containerRef.current.getBoundingClientRect().left;
    let newValue =
      ((offsetX - scrubberWidth / 2) / (containerWidth - scrubberWidth)) *
      valueMax;
    newValue = Math.min(Math.max(newValue, valueMax * 0.05), valueMax * 0.95); // clamp
    setValue(newValue);
  };

  const handleMouseUp = () => {
    isDragging.current = false;
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  return (
    <div className={styles.wrapper} ref={containerRef}>
      <div className={styles.track}>
        <div className={styles.cellWrapper}>{cell2}</div>
        <div className={styles.range} style={{ width: `${percent}%` }}>
          <div className={styles.cellWrapper}>{cell1}</div>
        </div>
      </div>
      <div
        className={styles.scrubber}
        aria-label="Slider"
        style={{ left: `${percent}%` }}
        onMouseDown={handleMouseDown}
      >
        <div className={styles.handle}>
          <CaretSortIcon />
        </div>
      </div>
    </div>
  );
}
