// DevPortalSignIn, DevPortalSignUp
//  from https://zuplo.com/blog/2023/09/29/announcing-supabase-auth-for-dev-portal
/*
 added ZUPLO_API_KEY to 1password
 following: https://zuplo.com/docs/articles/dev-portal-supabase-auth
 code: https://github.com/zuplo-samples/supa-api-site/blob/main/app/auth/sign-in/route.ts#L31-L70
 */

import { useEffect } from "react";
import { mngr } from "../store";
import { signOut, useAuth } from "../Auth";

// http://localhost:3001/dev-portal/sign-in?session-create-url=https%3A%2F%2Fapi.precip.ai%2Fdocs%2Fzp%2Fauth%2Fexternal%2Fsso

async function devPortalLogin() {
  // @ts-ignore
  const searchParams = new URL(document.location).searchParams;
  const scu = searchParams.get("session-create-url");
  console.log("scu", scu);

  // TODO - move to state manager?
  const { data, error } = await mngr.post(
    `/dev-portal/login?session-create-url=${encodeURIComponent(scu)}`,
    {}
  );
  // TODO - how to handle this?
  if (error) throw error;
  const { redirectUri } = data;
  console.log("redirectUri window.location", redirectUri);
  window.location.replace(redirectUri);
}

export function DevPortalSignIn() {
  const token = mngr.useAuthToken();
  useEffect(() => {
    if (!token) return;
    devPortalLogin();
  }, [token]);
  return <></>;
}

export function DevPortalSignUp() {
  const token = mngr.useAuthToken();
  useEffect(() => {
    if (!token) return;
    devPortalLogin();
  }, [token]);
  return <></>;
}

export function DevPortalSignOut() {
  const { data: session } = useAuth();
  useEffect(() => {
    if (!session) return;
    signOut();
    // TODO redirect
  }, [session]);

  return <></>;
}
