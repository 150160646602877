function CrosshairSVG({
  color,
  top,
  pad,
}: {
  color: string;
  top?: boolean;
  pad?: boolean;
}) {
  const gutter = 4;
  const size = 50;
  const half = size / 2;
  const centerPadding = size * 0.125;
  const strokeWidth = pad ? 3 : 1;
  const circleSize = 2.75;

  return (
    <svg
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        stroke: color,
        fill: color,
        position: "relative",
        zIndex: top ? 200 : 180,
      }}
    >
      <line
        x1={gutter}
        x2={half - centerPadding}
        y1={half}
        y2={half}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={half + centerPadding}
        x2={size - gutter}
        y1={half}
        y2={half}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={half}
        x2={half}
        y1={gutter}
        y2={half - centerPadding}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <line
        x1={half}
        x2={half}
        y1={half + centerPadding}
        y2={size - gutter}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <circle
        cx={half}
        cy={half}
        r={circleSize}
        strokeWidth={strokeWidth}
        fill="none"
      />
    </svg>
  );
}

export default function Crosshair({
  hidden,
  darkMapColors,
}: {
  hidden: boolean;
  darkMapColors?: boolean;
}) {
  return (
    <div
      style={{
        opacity: hidden ? 0 : 1,
        transition: "all .15s ease-in-out",
        pointerEvents: "none",
        position: "absolute",
        /*
          Top must be in "vh" units, not %, because it needs to be centered on
          the map which is sized by vh (so that it doesn't change size when the
          tab bar is removed). See Map.tsx for more details.
        */
        top: "50vh",
        left: "50%",
        minWidth: "75px",
        width: "10vw",
        maxWidth: "100px",
        transform: "translate(-50%, -50%)",
        zIndex: "200",
      }}
    >
      {/* main crosshair */}
      <CrosshairSVG
        color={darkMapColors ? "var(--utility-white)" : "var(--slate-800)"}
        top
      />

      {/* shadow */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <CrosshairSVG
          color={darkMapColors ? "var(--slate-800)" : "var(--utility-white)"}
          pad
        />
      </div>
    </div>
  );
}
