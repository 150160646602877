import styles from "./CloseX.module.css";

export const CloseX = ({
  onClick = (e) => e?.stopPropagation(),
  size = "m",
  inline = false,
  darkBg = false,
}: {
  onClick?: (e?: any) => void;
  size?: "xs" | "s" | "m" | "l" | "xl";
  inline?: boolean;
  darkBg?: boolean;
}) => {
  const widthHeight =
    size === "xs"
      ? "1rem"
      : size === "s"
      ? "1.25rem"
      : size === "m"
      ? "1.5rem"
      : size === "l"
      ? "1.75rem"
      : size === "xl"
      ? "2rem"
      : "1.5rem";

  const padding =
    size === "xs"
      ? "0.25rem"
      : size === "s"
      ? "0.75rem"
      : size === "m"
      ? "1rem"
      : size === "l"
      ? "1.5rem"
      : size === "xl"
      ? "1.75rem"
      : "1rem";

  return (
    <div
      onClick={onClick}
      className={styles.wrapper}
      style={{
        position: inline ? "static" : "absolute",
        width: `calc(${widthHeight} + ${padding})`,
        height: `calc(${widthHeight} + ${padding})`,
        background: darkBg ? "var(--item-primary)" : "transparent",
      }}
    >
      {/*
        Going with a raw svg instead of an icon from a set.
        This gives us programmatic control over line weight etc.
        We don't really want this to match UI icons anyway,
        it's a different size and serves a different purpose.
        via https://phosphoricons.com/
      */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 256 256"
        style={{
          width: widthHeight,
          height: widthHeight,
          color: "var(--text-secondary)",
        }}
      >
        <rect width="256" height="256" fill="none" />
        <line
          x1="200"
          y1="56"
          x2="56"
          y2="200"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={size === "xs" ? "16" : "20"}
        />
        <line
          x1="200"
          y1="200"
          x2="56"
          y2="56"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={size === "xs" ? "16" : "20"}
        />
      </svg>
    </div>
  );
};
