import { getDataColors } from "brand/scripts/colors";
import styles from "./Badge.module.css";
import { PrecipTypeIcon } from "./PrecipTypeIcon";
import Tooltip from "./Tooltip";
import { PrecipType, inToMM } from "shared";
import { useDarkMode } from "../hooks/useDarkMode";
import { Shimmer } from "./Loading";
import { RAIN_RELEVANCE_EXPIRY_DAYS } from "shared/constants";

export function Badge({
  children,
  type,
  isLoading = false,
}: {
  children: React.ReactNode;
  type: "plain" | "dark" | "solid" | "error";
  isLoading?: boolean;
}) {
  const style = isLoading
    ? styles.loading
    : type === "dark"
    ? styles.dark
    : type === "solid"
    ? styles.solid
    : type === "error"
    ? styles.error
    : styles.plain;
  return (
    <div className={`${styles.badge} ${style}`}>
      {children}
      <Shimmer isLoading={isLoading} />
    </div>
  );
}

export function RainBadge({
  isLoading = false,
  amount,
  precipType = "rain",
  fmtRain,
  fmtSnowRange,
  dateForExpiringBadge,
}: {
  isLoading?: boolean;
  amount: number;
  precipType?: PrecipType;
  fmtRain: (amount: number) => string;
  fmtSnowRange: (amount: number) => string;
  dateForExpiringBadge?: Date;
}) {
  const isDarkMode = useDarkMode();

  // this is (hopefully) the smallest amount that will show blue bg color
  const minPlainAmt = inToMM(0.01 * 0.5);
  let style = amount <= minPlainAmt ? styles.plain : "";
  let amountForColoring = amount;

  // If they pass a dateForExpiringBadge, it means this badge "expires"
  // (ie we shouldn't show "old" rain with a bright blue background)
  // and the date of the rain was dateForExpiringBadge.
  if (dateForExpiringBadge) {
    const dateWhereRainBecomesOld = new Date().setDate(
      new Date().getDate() - RAIN_RELEVANCE_EXPIRY_DAYS
    );
    if (new Date(dateForExpiringBadge).getTime() < dateWhereRainBecomesOld) {
      amountForColoring = 0;
      style = styles.plain;
    }
  }

  const { backgroundColor, textColor } = getDataColors({
    amount: amountForColoring,
    factor: "precip",
    precipType,
    isDarkMode,
  });

  return (
    <Tooltip
      trigger={
        <div
          className={`${styles.badge} ${style}`}
          data-test-id="rain_badge"
          style={{
            backgroundColor: !isLoading && backgroundColor,
            color: !isLoading && textColor,
            gap: 0,
          }}
        >
          {fmtRain(amount)}
          {amount > 0 && (
            <div style={{ maxWidth: "14px" }}>
              <PrecipTypeIcon precipType={precipType} />
            </div>
          )}
          <Shimmer isLoading={isLoading} />
        </div>
      }
    >
      {precipType === "snow" ? (
        <div>
          {fmtSnowRange(amount)} of snow
          <br />({fmtRain(amount)} of rain equivalent)
        </div>
      ) : (
        <div>{fmtRain(amount)} of rain</div>
      )}
    </Tooltip>
  );
}
