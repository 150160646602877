import { proxy, useSnapshot } from "valtio";
import { keyBy, mkid } from "shared";
import * as Sentry from "@sentry/react";

// ---
// TYPES
// ---

// this is what firebase returns from authStateChange event not the same as User from "firebase/auth"
type FirebaseUser = {
  uid: string;
  photoUrl: string;
  email: string;
  displayName: string;
  emailVerified: boolean;
  providerData: any[];
};

export type Id = string;

type Properties = {
  id?: Id;
  name: string;
  orgId?: Id;
  groupId?: Id;
  isPinned?: boolean;
};

export type LocationFeature<Props = Properties> = {
  type: "Feature";
  properties: Props;
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
};

export type LocationFeatureCollection<Props = Properties> = {
  type: "FeatureCollection";
  features: LocationFeature<Props>[];
};

// TODO from prisma??
export type LocationBackend = {
  id: Id;
  name: string;
  orgId: Id;
  groupId?: Id;
  geometry: {
    type: "Point";
    coordinates: [number, number];
  };
};

export type Group = {
  id: Id;
  name: string;
};

export type Org = {
  orgId?: Id; // can be null for personal org
  orgName: string;
  role?: Role; // can be null for personal org
};

export type UserBackend = {
  user: {
    id: Id;
    email: string;
    displayName: string;
    photoUrl: string;
    notifications: boolean;
  };
  orgs: Org[];
};

export type Role = "admin" | "editor" | "viewer";

export type MemberBackend = {
  id: Id;
  userEmail: string;
  userDisplayName?: string;
  userPhotoUrl?: string;
  createdAt?: string;
  role?: Role;
};

type Pin = { id: Id; locationId: Id };

type Log = { type: "log" | "error"; args: any[]; timestamp: Date };

// maybe we'll have a "Custom" option later for more fine-grained control
export const unitsSystems = {
  imperial: {
    label: "Imperial",
  },
  metric: {
    label: "Metric",
  },
};
export type UnitsSystem = keyof typeof unitsSystems;

const USER_API_URL =
  import.meta.env?.VITE_APP_BASE_URL ||
  "https://ent-api-dev-kxe64thdqq-uc.a.run.app";

export class StateManager {
  async resetStateAfterLogout() {
    console.log("resetting state after logout");
    this.setUser(null);
    this.setAuthToken(null);
    this.setOrgId(null);
    this.setMembers({});

    // TODO - make this.setLocations() and this.setGroups() etc
    this.resetLocations();

    this.pins = {};

    this.groups = {};
    this.groupsLoading = false;
    this.groupsError = null;
    this.groupsProxy.i++;

    // TODO - clear subscription state too
  }

  // Auth
  // TODO - auth loading, auth error states?
  authToken: string | null = null;
  authTokenProxy = proxy({ i: 0 });
  setAuthToken(token: string) {
    this.authToken = token;
    this.authTokenProxy.i++;
  }
  useAuthToken() {
    useSnapshot(this.authTokenProxy);
    return this.authToken;
  }
  firebaseUser: FirebaseUser = null;

  // User

  backendUser: UserBackend = null;
  userLoading: boolean = false;
  userError: Error | undefined = undefined;
  userProxy = proxy({ i: 0 });
  async fetchUser() {
    this.userLoading = true;
    this.userError = undefined;
    this.userProxy.i++;
    const { data: backendUser, error } = await this.get(`/user`);
    this.backendUser = backendUser;
    this.userLoading = false;
    this.userError = error;
    this.userProxy.i++;
  }
  async setUser(user: UserBackend) {
    this.backendUser = user;
    this.userProxy.i++;
  }
  useUser() {
    useSnapshot(this.userProxy);
    return {
      data: this.backendUser,
      loading: this.userLoading,
      error: this.userError,
    };
  }

  async deleteLoggedInUser() {
    console.log("❌ Deleting user");
    const { error } = await this.delete(`/user`);

    if (error) {
      throw new Error("Unable to delete user.");
    }
  }

  // @cspell:disable-next-line
  orgId: string | null = null; // "5oPDuiyuz2m02dVQTizBw0";
  orgIdProxy = proxy({ i: 0 });
  setOrgId(id: string) {
    this.orgId = id;
    this.orgIdProxy.i++;
  }
  useOrgId() {
    useSnapshot(this.orgIdProxy);
    // TODO - error and loading states
    return this.orgId;
  }
  async createOrg(name) {
    const newOrg = {
      name,
      id: mkid(),
    };
    const { error } = await this.post(`/org`, newOrg);
    if (error) {
      // TODO - use a toast
      alert(`Sorry, we couldn't create this org right now. (${error.message})`);
      return;
    }
    return newOrg;
  }
  async putOrg(org) {
    const { data: updatedOrgs, error } = await this.put(`/org`, org);
    if (error) {
      // TODO - use a toast
      alert(`Sorry, we couldn't update this org right now. (${error.message})`);
      return;
    }
    this.backendUser.orgs = updatedOrgs;
    return updatedOrgs;
  }
  useOrg() {
    const { data: backendUser, loading, error } = this.useUser();
    const data = (backendUser?.orgs || []).find(
      (w) => this.orgId === w.orgId
    ) || {
      orgName: "Personal",
      role: undefined,
    };

    return { data, loading, error };
  }

  // org members

  members: Record<Id, MemberBackend> = {};
  membersLoading: boolean = false;
  membersError: Error | undefined = undefined;
  membersProxy = proxy({ i: 0 });
  async fetchMembers() {
    this.membersLoading = true;
    this.membersError = undefined;
    this.membersProxy.i++;
    const { data, error } = await this.get("/org_user");
    this.members = keyBy(data, "id");
    this.membersLoading = false;
    this.membersError = error;
    this.membersProxy.i++;
  }
  async setMembers(members: Record<Id, MemberBackend>) {
    this.members = members;
    this.membersProxy.i++;
  }
  useMembers() {
    useSnapshot(this.membersProxy);
    // return {
    //   data: null,
    //   loading: false,
    //   error: new Error("Test error for dev only"),
    // };
    return {
      data: this.members,
      loading: this.membersLoading,
      error: this.membersError,
    };
  }
  async deleteMember(id: Id) {
    console.log("❌ Deleting member", id);
    const { error } = await this.delete(`/org_user/${id}`);
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't remove this member right now. (${error.message})`
      );
      return;
    }
    delete this.members[id];
    this.membersProxy.i++;
  }
  async addMember(userEmail: string, role: Role) {
    const newMember = {
      id: mkid(),
      userEmail,
      orgId: this.orgId,
      role,
    };
    const { error } = await this.post(`/org_user`, newMember);
    if (error) {
      // TODO - use a toast
      alert(`Sorry, we couldn't add this member right now. (${error.message})`);
      return;
    }
    this.members[newMember.id] = newMember;
    this.membersProxy.i++;
    return newMember;
  }
  async updateMemberRole(id: Id, role: Role) {
    const { error } = await this.put(`/org_user/${id}`, { role });
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't update this member right now. (${error.message})`
      );
      return;
    }
    this.members[id].role = role;
    this.membersProxy.i++;
  }

  // Locations

  locations: Record<Id, LocationFeature> = {};
  locationsToDelete: Record<Id, LocationFeature> = {};
  locationsLoading: boolean = false;
  locationsError: Error | undefined = undefined;
  locationsProxy = proxy({ i: 0 });
  async addLocation(location: LocationFeature) {
    location.properties.id = mkid();
    const locationProperties = {
      geometry: location.geometry,
      id: location.properties.id,
      name: location.properties?.name,
    } as LocationBackend;
    if (location.properties?.groupId) {
      // @ts-ignore
      locationProperties.groupId = location.properties?.groupId;
    }
    const { error } = await this.post(`/location`, locationProperties);
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't add this location right now. (${error.message})`
      );
      return;
    }
    // for some reason this throws an error sometimes, trying to add logs to figure out why
    // https://precipitation-inc.sentry.io/issues/5204660589/?project=4506304132218880&query=is%3Aunresolved&referrer=issue-stream&statsPeriod=14d&stream_index=6
    try {
      this.locations[location.properties.id] = location;
    } catch (e) {
      Sentry.captureMessage(
        `Error adding location to state after save. Id: ${location.properties.id}, post error: ${error.code} (${error.message}): ${error}, caught error: ${e.code} (${e.message}): ${e}`
      );
    }
    this.locationsProxy.i++;
    return location;
  }
  // Does NOT include locations queued for deletion
  useLocations() {
    // @ts-ignore
    useSnapshot(this.locationsProxy);
    // return {
    //   data: null,
    //   loading: false,
    //   error: new Error("Test error for dev only"),
    // };

    // don't show locations currently being deleted in the list of locations
    const locations = { ...this.locations };
    for (const id in this.locationsToDelete) {
      delete locations[id];
    }

    return {
      data: locations,
      loading: this.locationsLoading,
      error: this.locationsError,
    };
  }
  // Includes locations queued for deletion
  useLocation(id: Id) {
    // @ts-ignore
    useSnapshot(this.locationsProxy);
    // return {
    //   data: null,
    //   loading: false,
    //   error: new Error("Test error for dev only"),
    // };
    return {
      data: this.locations?.[id],
      loading: this.locationsLoading,
      error: this.locationsError,
    };
  }

  resetLocations() {
    this.locationsLoading = false;
    this.locationsError = null;
    this.locations = {};
    this.locationsProxy.i++;
  }

  async fetchLocations() {
    this.locationsLoading = true;
    this.locationsError = undefined;
    this.locationsProxy.i++;
    this.groupsLoading = true;
    this.groupsError = undefined;
    this.groupsProxy.i++;

    if (!this.backendUser) {
      this.resetLocations();
      return;
    }

    // TODO - use separate state for these?
    const [
      { data: locations, error: locationsError },
      { data: groups, error: groupsError },
      // TODO - handle pins fetch error?
      { data: pins, error: pinsError },
    ] = await Promise.all([
      this.get(`/location`),
      this.get(`/group`),
      this.get(`/pin`),
    ]);

    // locations
    this.locations = locations?.reduce(
      (acc: Record<Id, LocationFeature>, loc: LocationBackend) => {
        acc[loc.id] = {
          type: "Feature",
          properties: {
            id: loc.id,
            name: loc.name,
            orgId: loc.orgId,
            groupId: loc.groupId,
            isPinned: loc.id in this.pins,
          },
          geometry: loc.geometry,
        };
        return acc;
      },
      {} as Record<Id, LocationFeature>
    );
    this.locationsLoading = false;
    this.locationsError = locationsError;
    this.locationsProxy.i++;

    // groups
    this.groups = groups?.reduce((acc: Record<Id, Group>, group: Group) => {
      acc[group.id] = group;
      return acc;
    }, {} as Record<Id, Group>);
    this.groupsLoading = false;
    this.groupsError = groupsError;
    this.groupsProxy.i++;

    // pins
    this.pins = keyBy(pins, "locationId");
  }
  async deleteLocation(id: Id) {
    console.log("❌ Deleting location", id);
    const { error } = await this.delete(`/location/${id}`);
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't remove this location right now. (${error.message})`
      );
      return;
    }
    delete this.locations[id];
    this.locationsProxy.i++;
  }
  async changeLocationName(id: Id, name: string) {
    const { error } = await this.put(`/location/${id}`, { name });
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't change this location's name right now. (${error.message})`
      );
      return;
    }
    this.locations[id].properties.name = name;
    this.locationsProxy.i++;
  }

  // Location pins
  // keeping pins optimistic (for now) for speed and simplicity
  // TODO - show loading/error states?

  pins: Record<Id, Pin> = {};
  async toggleLocationPin(locationId: Id) {
    const isPinned = !this.locations[locationId].properties.isPinned;
    this.locations[locationId].properties.isPinned = isPinned;
    this.locationsProxy.i++;
    if (isPinned) {
      const newPin = {
        id: mkid(),
        locationId,
      };
      this.post(`/pin`, newPin);
      this.pins[locationId] = newPin;
    } else {
      const pinId = this.pins[locationId].id;
      delete this.pins[locationId];
      this.delete(`/pin/${pinId}`);
    }
  }

  // Location groups

  groups: Record<Id, Group> = {};
  groupsLoading: boolean = false;
  groupsError: Error | undefined = undefined;
  groupsProxy = proxy({ i: 0 });
  async addGroup(name: string) {
    const group = {
      id: mkid(),
      name,
    } as Group;
    const { error } = await this.post(`/group`, group);
    if (error) {
      // TODO - use a toast
      alert(`Sorry, we couldn't add this group right now. (${error.message})`);
      return;
    }
    this.groups[group.id] = group;
    this.groupsProxy.i++;
    // TODO - return what we get back from the server?
    return group;
  }
  useGroups() {
    useSnapshot(this.groupsProxy);
    // return {
    //   data: null,
    //   loading: false,
    //   error: new Error("Test error for dev only"),
    // };
    return {
      data: this.groups,
      loading: this.groupsLoading,
      error: this.groupsError,
    };
  }
  async renameGroup(id: Id, name: string) {
    const { error } = await this.put(`/group/${id}`, { name });
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't change this group's name right now. (${error.message})`
      );
      return;
    }
    this.groups[id].name = name;
    this.groupsProxy.i++;
  }
  async deleteGroup(id: Id) {
    console.log("❌ Deleting group", id);
    const { error } = await this.delete(`/group/${id}`);
    if (error) {
      // TODO - use a toast
      alert(
        `Sorry, we couldn't remove this group right now. (${error.message})`
      );
      return;
    }
    delete this.groups[id];
    this.groupsProxy.i++;
  }

  preferredUnits: UnitsSystem = "imperial";
  unitsProxy = proxy({ i: 0 });
  async setPreferredUnits(unitsSystem: UnitsSystem) {
    this.preferredUnits = unitsSystem || "imperial";
    this.unitsProxy.i++;
  }
  useUnits() {
    useSnapshot(this.unitsProxy);
    return {
      preferredUnits: this.preferredUnits,
    };
  }

  // Map

  map: mapboxgl.Map | undefined;
  mapLoadProxy = proxy({ i: 0 });
  useMapLoad() {
    return useSnapshot(this.mapLoadProxy);
  }

  // Logs
  // take over the real console.log
  // https://stackoverflow.com/questions/20256760/javascript-console-log-to-html

  logs: Log[] = [];
  logsProxy = proxy({ i: 0 });
  initLogCapture() {
    const oldConsoleLog = console.log;
    console.log = (...args) => {
      oldConsoleLog(...args);
      this.setLogs([
        ...this.logs,
        { type: "log", args, timestamp: new Date() },
      ]);
    };
    const oldConsoleError = console.error;
    console.error = (...args) => {
      oldConsoleError(...args);
      this.setLogs([
        ...this.logs,
        { type: "error", args, timestamp: new Date() },
      ]);
    };
  }
  setLogs(logs: Log[]) {
    this.logs = logs;
    this.logsProxy.i++;
  }
  useLogs() {
    useSnapshot(this.logsProxy);
    return this.logs;
  }
  useLogsDisplay() {
    const logs = this.useLogs();
    return logs.map((log) => {
      return {
        type: log.type,
        time: log.timestamp.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          // hour12: false,
        }),
        content: log.args
          .map((arg, index) => (index === 0 ? arg : JSON.stringify(arg)))
          .join(", "),
      };
    });
  }
  useLogsText() {
    const logsDisplay = this.useLogsDisplay();
    return logsDisplay
      .map(
        (log) =>
          log.time + (log.type === "error" ? "❌ ERROR: " : ": ") + log.content
      )
      .join(" |\n");
  }

  // App version

  async getVersion() {
    const { data: version } = await this.get("/version");
    return version;
  }

  // Network stuff

  async getHeaders() {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.authToken}`,
      mode: "cors",
    };
  }
  params() {
    if (!this.orgId) return "";
    return `?orgId=${this.orgId}`;
  }
  async get(path) {
    return fetch(`${USER_API_URL}${path}${this.params()}`, {
      headers: await this.getHeaders(),
    })
      .then(async (res) => {
        if (!res.ok) {
          console.error(`Get NOT ok ${path}`, res);
          return {
            data: null,
            error: new Error(res.statusText),
          };
        }
        const data = await res.json();
        return {
          data,
          error: undefined,
        };
      })
      .catch((e) => {
        console.error(`Caught error during get ${path}`, e);
        return {
          data: null,
          error: e,
        };
      });
  }
  async post(path, body) {
    return fetch(`${USER_API_URL}${path}${this.params()}`, {
      method: "POST",
      headers: await this.getHeaders(),
      // TODO stop the flip flop pick feature or DB
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        if (!res.ok) {
          console.error(`Post NOT ok ${path}`, res);
          return {
            data: null,
            error: new Error(res.statusText),
          };
        }
        const data = await res.json();
        return {
          data,
          error: undefined,
        };
      })
      .catch((e) => {
        console.error(`Caught error during post ${path}`, e);
        return {
          data: null,
          error: e,
        };
      });
  }
  async put(path, body) {
    return fetch(`${USER_API_URL}${path}${this.params()}`, {
      method: "PUT",
      headers: await this.getHeaders(),
      // TODO stop the flip flop pick feature or DB
      body: JSON.stringify(body),
    })
      .then(async (res) => {
        if (!res.ok) {
          console.error(`Put NOT ok ${path}`, res);
          return {
            data: null,
            error: new Error(res.statusText),
          };
        }
        const data = await res.json();
        return {
          data,
          error: undefined,
        };
      })
      .catch((e) => {
        console.error(`Caught error during put ${path}`, e);
        return {
          data: null,
          error: e,
        };
      });
  }
  async delete(path) {
    return fetch(`${USER_API_URL}${path}${this.params()}`, {
      method: "DELETE",
      body: JSON.stringify({}),
      headers: await this.getHeaders(),
    })
      .then(async (res) => {
        if (!res.ok) {
          console.error(`Delete NOT ok ${path}`, res);
          return {
            data: null,
            error: new Error(res.statusText),
          };
        }
        const data = await res.json();
        return {
          data,
          error: undefined,
        };
      })
      .catch((e) => {
        console.error(`Caught error during delete ${path}`, e);
        return {
          data: null,
          error: e,
        };
      });
  }
}
