import { forwardRef } from "react";

import styles from "./Button.module.css";
import GoogleLogo from "../assets/social/google_logo.svg";
import AppleLogo from "../assets/social/apple_logo.svg";
import MicrosoftLogo from "../assets/social/microsoft_logo.svg";
import FacebookLogo from "../assets/social/facebook_logo.svg";
import { Spinner } from "./Loading";
import { useDarkMode } from "../hooks/useDarkMode";

const Button = forwardRef(
  (
    {
      id = "",
      primary,
      secondary,
      tertiary,
      map,
      authProvider,
      round,
      transparent,
      working,
      destructive,
      small,
      large,
      iconOnly,
      fullWidth,
      type = "button",
      selected,
      padding,
      children,
      onClick,
      disabled,
      ...props
    }: {
      id?: string;
      primary?: boolean;
      secondary?: boolean;
      tertiary?: boolean;
      map?: boolean;
      authProvider?: "google" | "apple" | "microsoft" | "facebook" | "email";
      round?: boolean;
      transparent?: boolean;
      working?: boolean;
      destructive?: boolean;
      small?: boolean;
      large?: boolean;
      iconOnly?: boolean;
      fullWidth?: boolean;
      type?: "button" | "submit" | "reset";
      selected?: boolean;
      padding?: string;
      children: React.ReactNode;
      onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      disabled?: boolean;
    },
    ref: React.Ref<HTMLButtonElement>
  ) => {
    const typeStyle = map
      ? styles.map
      : primary
      ? styles.primary
      : secondary
      ? styles.secondary
      : tertiary
      ? styles.tertiary
      : transparent
      ? styles.transparent
      : destructive
      ? styles.destructive
      : secondary; // default

    const sizeStyle = small ? styles.small : large ? styles.large : "";

    const isDarkMode = useDarkMode();

    if (
      !primary &&
      !secondary &&
      !tertiary &&
      !transparent &&
      !destructive &&
      !map
    ) {
      console.warn("No button type specified");
    }

    return (
      <button
        id={id}
        ref={ref}
        className={`${styles.button} ${typeStyle} ${sizeStyle}`}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          onClick && onClick(e);
        }}
        disabled={disabled || working}
        type={type}
        style={{
          width: fullWidth
            ? "100%"
            : map && iconOnly && (large ? "3rem" : "2rem"),
          height: map && iconOnly && (large ? "3rem" : "2rem"),
          fontSize: small ? "var(--s)" : large ? "var(--m)" : undefined,
          // fontWeight: small && "600",
          borderRadius:
            map && iconOnly
              ? "var(--brad-full)"
              : round
              ? "var(--brad-full)"
              : "var(--brad-s)",
          padding:
            map && iconOnly
              ? "0"
              : padding
              ? padding
              : small && iconOnly
              ? ".5rem"
              : small
              ? "0.5rem .75rem"
              : large && iconOnly
              ? ".9rem"
              : large
              ? ".9rem 2rem"
              : undefined,
          background:
            map && selected
              ? "var(--brand-primary)"
              : selected
              ? "var(--button-secondary-active)"
              : // I don't love this button color but it's necessary to get contrast
              // on the map in dark mode. TODO - when the map background is in our
              // control, choose colors accordingly.
              map && isDarkMode
              ? "var(--button-secondary)"
              : undefined,
          color: map && selected && "var(--text-contrast)",
          gap: small && "0.25rem",
        }}
        {...props}
      >
        {working && <Spinner size={16} />}
        {authProvider === "google" && (
          <img
            className={styles.socialIcon}
            src={String(GoogleLogo)}
            alt="Google logo"
          />
        )}
        {authProvider === "apple" && (
          <div className="invert-for-dark-mode">
            <img
              className={styles.socialIcon}
              src={String(AppleLogo)}
              alt="Apple logo"
            />
          </div>
        )}
        {authProvider === "microsoft" && (
          <div
            style={{
              transform: "scale(0.9)",
            }}
          >
            <img
              className={styles.socialIcon}
              src={String(MicrosoftLogo)}
              alt="Microsoft logo"
            />
          </div>
        )}
        {authProvider === "facebook" && (
          <div
            style={{
              transform: "scale(0.9)",
            }}
          >
            <img
              className={styles.socialIcon}
              src={String(FacebookLogo)}
              alt="Facebook logo"
            />
          </div>
        )}
        {authProvider === "email" && (
          <div
            style={{
              transform: "scale(0.9)",
            }}
          >
            {/* Phosphor icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 256 256"
              style={{
                width: "16px",
                height: "16px",
                margin: "2px",
              }}
            >
              <rect width="256" height="256" fill="none" />
              <path
                d="M32,56H224a0,0,0,0,1,0,0V192a8,8,0,0,1-8,8H40a8,8,0,0,1-8-8V56A0,0,0,0,1,32,56Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
              <polyline
                points="224 56 128 144 32 56"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="16"
              />
            </svg>
          </div>
        )}
        {iconOnly && working ? "" : children}
      </button>
    );
  }
);

export const ButtonGroupWrapper = ({
  children,
  fullWidth,
}: {
  children: React.ReactNode;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={styles.buttonGroupWrapper}
      style={{
        width: fullWidth ? "100%" : undefined,
      }}
    >
      {children}
    </div>
  );
};

export default Button;
