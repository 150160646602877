// HEY you should probably keep this roughly in sync with the mobile app tracking.ts

import * as Sentry from "@sentry/react";
import * as amplitude from "@amplitude/analytics-browser";
import posthog, { Properties } from "posthog-js";
import { mngr } from "./store";
import { subscribe } from "valtio";

export function setupTracking() {
  console.log("🐎 setupTracking");
  if (!import.meta.env.PROD) {
    // if (!import.meta.env.PROD) {
    console.log("🚧 Development environment, no page/event tracking");
  } else {
    console.log("🟢 Production environment, starting page tracking");

    const posthogToken = import.meta.env.VITE_POSTHOG_TOKEN;
    if (!posthogToken) throw new Error("Missing PostHog token");
    posthog.init(posthogToken, {
      api_host: "https://us.posthog.com",
    });
    // @ts-ignore
    window.posthog = posthog;

    // https://docs.sentry.io/platforms/javascript/guides/react
    // TODO
    // - source maps - https://docs.sentry.io/platforms/javascript/guides/react/sourcemaps/
    // - react component performance - https://docs.sentry.io/platforms/javascript/guides/react/features/component-tracking/
    // - router integration - https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
    const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
    if (!sentryDsn) throw new Error("Missing Sentry DSN");
    Sentry.init({
      dsn: sentryDsn,
      integrations: [
        new Sentry.BrowserTracing({
          // TODO - Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            "localhost",
            /^https:\/\/yourserver\.io\/api/,
          ],
        }),

        // don't mask text/media by default
        // you can opt-in to masking by setting the "sentry-mask" class on any element
        // https://docs.sentry.io/platforms/javascript/session-replay/privacy/
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],

      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions

      // Session Replay
      replaysSessionSampleRate: 1.0, // TODO - Track fewer replays in production (< 1.0)
      replaysOnErrorSampleRate: 1.0, // Track replay for all sessions with errors
    });

    // https://www.docs.developers.amplitude.com/data/sdks/browser-2/#initialize-the-sdk
    const amplitudeApiKey = import.meta.env.VITE_AMPLITUDE_API_KEY;
    if (!amplitudeApiKey) throw new Error("Missing Amplitude API key");
    amplitude.init(amplitudeApiKey, { defaultTracking: true });

    const setupListeners = async () => {
      console.log("mngr.authTokenProxy", mngr.authTokenProxy);
      const unsubscribe = subscribe(mngr.authTokenProxy, () => {
        const firebaseUser = mngr.firebaseUser;
        const precipUserId = mngr.backendUser?.user?.id;
        if (firebaseUser) {
          // https://posthog.com/docs/integrations/js-integration
          posthog.identify(precipUserId, {
            email: firebaseUser.email,
            name: firebaseUser.displayName,
          });

          // https://www.docs.developers.amplitude.com/data/sdks/browser-2
          amplitude.setUserId(precipUserId);

          // https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/
          Sentry.setUser({
            id: precipUserId,
            email: firebaseUser.email,
          });
        } else {
          posthog.reset();
          amplitude.setUserId(null);
          Sentry.setUser(null);
        }
      });
    };
    setupListeners();
  }
}

// TODO - make shared between apps? don't need at all?
type EventName =
  | "app_loaded"
  | "app_loaded_first_time_on_device"
  | "user_signed_up"
  | "user_signed_in"
  | "user_requested_email_link"
  | "user_signed_out"
  | "member_added"
  | "member_access_changed"
  | "member_removed"
  | "location_added"
  | "location_removed"
  | "location_renamed"
  | "group_added"
  | "group_renamed"
  | "group_removed"
  | "organization_switched"
  | "organization_added"
  | "organization_renamed"
  | "map_location_previewed";

export async function trackEvent(eventName: EventName, eventData?: Properties) {
  if (!import.meta.env.PROD) {
    console.log(
      "🚧 Development environment, skip event tracking",
      eventName,
      eventData
    );
    return;
  }

  posthog.capture(eventName, eventData);
  amplitude.track(eventName, eventData);
}
