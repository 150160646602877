import { useState } from "react";

import Card from "brand/components/Card";
import BarChart from "brand/components/chart/BarChart";
import {
  useLocationDaily,
  fmtRainUnitM,
  fmtRainUnitI,
  mmToIn,
  inToMM,
} from "shared";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
import { ErrorState } from "brand/components/EmptyState";
import { mngr } from "../store";

const dtfmt = (d) => d.toISOString().slice(0, 10);

export default function LocationPageRecent({ location }) {
  const monthDt = new Date();
  const [annualChartType, setAnnualChartType] = useState("monthly");

  const { preferredUnits } = mngr.useUnits();
  const fmtRainUnit = preferredUnits === "metric" ? fmtRainUnitM : fmtRainUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : inToMM;

  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location);

  const dataFormattedDaily = dataDaily?.properties?.days?.map(
    ({ startTime, precip, source }) => {
      return {
        value: toConvertedUnits(precip),
        date: new Date(startTime),
        source,
      };
    }
  );

  const dataFormattedWeeklyObj = dataFormattedDaily?.reduce((acc, d) => {
    const dt = new Date(d.date);
    const week = dtfmt(
      new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - dt.getDay())
    );
    const source = d.source;
    if (!acc[week]) acc[week] = { value: 0, date: new Date(week), source };
    acc[week].value += d.value;
    return acc;
  }, {});
  const dataFormattedWeekly = Object.values(dataFormattedWeeklyObj || {});

  const dataFormattedMonthlyObj = dataFormattedDaily?.reduce((acc, d) => {
    const dt = new Date(d.date);
    const month = dtfmt(new Date(dt.getFullYear(), dt.getMonth() + 1));
    const source = d.source;
    if (!acc[month]) acc[month] = { value: 0, date: new Date(month), source };
    acc[month].value += d.value;
    return acc;
  }, {});
  const dataFormattedMonthly = Object.values(dataFormattedMonthlyObj || {});

  return (
    <Card>
      <div
        className="flex-row gap-4 justify-between align-center"
        style={{ marginBottom: "1rem" }}
      >
        <h4>Annual precipitation history</h4>
        <ButtonGroupWrapper>
          <Button
            secondary
            selected={annualChartType === "monthly"}
            onClick={() => {
              setAnnualChartType("monthly");
            }}
          >
            Months
          </Button>
          <Button
            secondary
            selected={annualChartType === "weekly"}
            onClick={() => {
              setAnnualChartType("weekly");
            }}
          >
            Weeks
          </Button>
          <Button
            secondary
            selected={annualChartType === "daily"}
            onClick={() => {
              setAnnualChartType("daily");
            }}
          >
            Days
          </Button>
        </ButtonGroupWrapper>
        {/* <Select
          value={annualChartType}
          options={[
            { label: "Monthly", value: "monthly" },
            { label: "Weekly", value: "weekly" },
            { label: "Daily", value: "daily" },
          ]}
          onChange={(value) => {
            setAnnualChartType(value);
          }}
        /> */}
        {/*
          TODO
          - Compare to 2023
          - Compare to 2022
          - Compare to 2021 etc
          - Compare to 5-year average
          - Compare to 10-year average
          - Compare to 30-year average
        */}
      </div>
      {errorDaily ? (
        <ErrorState error={errorDaily} />
      ) : (
        <BarChart
          key={annualChartType}
          xAxisLabel={String(monthDt.getFullYear())}
          yAxisLabel="Total rainfall"
          xAxisTimeFormat="%b"
          nowLine={false}
          unitFormatter={fmtRainUnit}
          toOriginalUnits={toOriginalUnits}
          showRules
          isLoading={isLoadingDaily}
          // @ts-ignore
          data={
            annualChartType === "daily"
              ? dataFormattedDaily
              : annualChartType === "weekly"
              ? dataFormattedWeekly
              : dataFormattedMonthly
          }
        />
      )}
    </Card>
  );
}
