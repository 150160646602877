import { useEffect, useRef } from "react";

import { axisLeft } from "d3-axis";
import { select } from "d3-selection";

export default function YAxis({
  xPos,
  yPos,
  width,
  height,
  scale,
  label,
  showRules,
  fullWidth,
}: {
  xPos: number;
  yPos: number;
  width: number;
  height: number;
  scale: any;
  label: string;
  showRules?: boolean;
  fullWidth?: number;
}) {
  const groupRef = useRef<SVGGElement>(null);
  const axisRef = useRef<SVGGElement>(null);
  const labelRef = useRef<SVGGElement>(null);

  useEffect(() => {
    // position group
    select(groupRef.current)
      .attr("data-name", `group`)
      .attr("transform", `translate(${xPos},${yPos})`)
      .attr("width", width)
      .attr("height", height);

    // clear old axis
    select(axisRef.current).selectAll("*").remove();

    // create ticks
    select(axisRef.current)
      .attr("data-name", `axis`)
      .attr("transform", `translate(${width},0)`)
      .call(axisLeft(scale).ticks(5));

    // axis line style
    select(axisRef.current).select(".domain").attr("stroke", "transparent");

    // tick text style
    select(axisRef.current)
      .selectAll(".tick text")
      .attr("font-size", "var(--xs)")
      .attr("font-family", "inherit")
      .attr("transform", "translate(4,0)");

    // tick line style
    select(axisRef.current)
      .selectAll(".tick line")
      .attr("stroke", "transparent");

    if (showRules) {
      select(axisRef.current)
        .selectAll(".tick line")
        .clone()
        .attr("x2", fullWidth)
        .attr("stroke", "var(--chart-line)");
    }

    // clear old label
    select(labelRef.current).selectAll("*").remove();

    // create text label
    select(labelRef.current)
      .attr("data-name", `label`)
      .attr("transform", `translate(10, ${height / 2})`)
      .append("text")
      .attr("transform", "rotate(-90)")
      .style("text-anchor", "middle")
      .attr("font-size", "var(--xs)")
      .attr("font-family", "inherit")
      .attr("fill", "var(--chart-axis)")
      .text(label);
  }, [xPos, yPos, width, height, scale, label]);

  return (
    <g ref={groupRef}>
      <g ref={axisRef} />
      <g ref={labelRef} />
    </g>
  );
}
