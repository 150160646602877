import { sum } from "d3-array";
import {
  DAY_CUTOFF_WHEN_WE_NO_LONGER_SHOW_RAIN_EVENT_END_DATES,
  PrecipType,
} from ".";

export const startOfDay = (date: Date) => {
  const toUpdate = new Date(date);
  toUpdate.setHours(0);
  toUpdate.setMinutes(0);
  toUpdate.setSeconds(0);
  toUpdate.setMilliseconds(0);
  return toUpdate;
};

export const endOfDay = (date: Date) => {
  const toUpdate = new Date(date);
  toUpdate.setDate(date.getDate() + 1);

  toUpdate.setHours(0);
  toUpdate.setMinutes(0);
  toUpdate.setSeconds(0);
  toUpdate.setMilliseconds(0);
  return toUpdate;
};

export const firstDayOfMonth = (date: Date): Date => {
  const toUpdate = new Date(date);
  toUpdate.setDate(1);
  return toUpdate;
};

export const getThreeMonthsAgo = (today: Date): Date => {
  const threeMonthsAgo = firstDayOfMonth(today);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  return threeMonthsAgo;
};

export const toDateString = (date: Date) => {
  return date.toISOString().slice(0, 10);
};

export const toDateTimeString = (date: Date) => {
  return date.toISOString().slice(0, 19);
};

export const fromDateString = (dateString: string) => {
  return new Date(dateString);
};

// DailyRainEntry[]
export function daysSince(days: any[]) {
  const now = new Date();
  if (!days || days.length === 0) return "0";
  let daysSince = 0;
  for (let i = days.length - 1; i >= 0; i--) {
    if (new Date(days[i].startTime) > now) continue;
    if (days[i].precip > 0) {
      break;
    }
    daysSince++;
  }
  if (daysSince === days.length) return `> ${daysSince}`;
  return `${daysSince}`;
}

export interface DailyRainEntry {
  startTime: string;
  precip: number;
  precip_type?: PrecipType;
}

export interface HourlyRainEntry {
  startTime: string;
  precip: number;
  precip_type?: PrecipType;
}

export function pastDays(days: DailyRainEntry[], number: number) {
  const now = new Date();
  // we go number + 1 because we'red comparing to startTime
  const startTime = new Date(Date.now() - (number + 1) * 24 * 60 * 60 * 1000);
  const totalMM = sum(
    days
      .filter((d) => {
        const dt = fromDateString(d.startTime);
        return startTime <= dt && dt <= now;
      })
      .map((d) => d.precip)
  );
  return totalMM;
}

export function pastHours(hours: HourlyRainEntry[], number: number) {
  const now = new Date();
  // we go number + 1 because we're comparing to startTime
  const startTime = new Date(Date.now() - (number + 1) * 60 * 60 * 1000);
  const totalMM = sum(
    hours
      .filter((d) => {
        const dt = fromDateString(d.startTime);
        return startTime <= dt && dt <= now;
      })
      .map((d) => d.precip)
  );
  return totalMM;
}

function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

export function thisMonth(days: DailyRainEntry[]) {
  // TODO call fromDateString in useDailyQuery
  const now = new Date();
  const firstOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const totalMM = sum(
    days
      .filter((d) => {
        const dt = fromDateString(d.startTime);
        return firstOfMonth <= dt && dt <= now;
      })
      .map((d) => d.precip)
  );

  return totalMM;
}

export function sinceJan1(days: DailyRainEntry[]) {
  const now = new Date();
  const jan1 = new Date(now.getFullYear(), 0, 1);
  const totalMM = sum(
    days
      .filter((d) => {
        const dt = fromDateString(d.startTime);
        return jan1 <= dt && dt <= now;
      })
      .map((d) => d.precip)
  );

  return totalMM;
}

export function sinceApr1(days: DailyRainEntry[]) {
  const now = new Date();
  const apr1 = new Date(now.getFullYear(), 3, 1);

  // undefined if it's Feb for ex
  if (now < apr1) return undefined;

  const totalMM = sum(
    days
      .filter((d) => {
        const dt = fromDateString(d.startTime);
        return apr1 <= dt && dt <= now;
      })
      .map((d) => d.precip)
  );

  return totalMM;
}

export const forecastDays = () => {
  const numDays = 10;
  const forecast = [];

  const start = 1; // 1 for tomorrow, 0 for today
  for (let i = start; i < numDays; i++) {
    const date = new Date();
    date.setDate(date.getDate() + i);
    // date.setDate(date.getDate() + 10); // for debugging only

    const dayName = date.toLocaleDateString("en-US", { weekday: "short" });
    const dateOfMonth = date.toLocaleDateString("en-US", { day: "numeric" });
    const monthString =
      dateOfMonth === "1"
        ? date.toLocaleDateString("en-US", { month: "short" })
        : "";

    const tooltipContent = date.toLocaleDateString("en-US", {
      weekday: "long",
      month: "long",
      day: "numeric",
    });

    forecast.push({
      date,
      dayName,
      dateOfMonth,
      monthString,
      tooltipContent,
    });
  }

  return forecast;
};

export function isWriteRole(role) {
  return role === "admin" || role === "editor";
}

export function fmtAgo(endTime: string, long?: boolean) {
  if (!endTime || endTime === "") {
    return long
      ? `more than ${DAY_CUTOFF_WHEN_WE_NO_LONGER_SHOW_RAIN_EVENT_END_DATES} days ago`
      : `${DAY_CUTOFF_WHEN_WE_NO_LONGER_SHOW_RAIN_EVENT_END_DATES}d+ ago`;
  }
  const now = new Date();
  const end = new Date(endTime);
  const diff = now.getTime() - end.getTime();
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  // const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return "just now";
      } else {
        return `${minutes}${
          long ? ` minute${minutes !== 1 ? "s" : ""}` : `m`
        } ago`;
      }
    } else {
      return `${hours}${long ? ` hour${hours !== 1 ? "s" : ""}` : `h`} ago`;
    }
  } else {
    return `${days}${long ? ` day${days !== 1 ? "s" : ""}` : `d`} ago`;
  }
}

export type ChartTimeRangeOption = {
  label: string;
  start: Date;
  end: Date;
};

export const chartTimeRangeOptions: ChartTimeRangeOption[] = [
  {
    label: "2 days",
    start: new Date(Date.now() - 24 * 60 * 60 * 1000),
    end: new Date(Date.now() + 24 * 60 * 60 * 1000),
  },
  {
    label: "4 days",
    start: new Date(Date.now() - 48 * 60 * 60 * 1000),
    end: new Date(Date.now() + 48 * 60 * 60 * 1000),
  },
  {
    label: "6 days",
    start: new Date(Date.now() - 72 * 60 * 60 * 1000),
    end: new Date(Date.now() + 72 * 60 * 60 * 1000),
  },
];
