// import { useState } from "react";

import styles from "./Legend.module.css";

export default function Legend({
  label,
  subLabel,
  visible,
  // clear,
  values,
  darkMapColors,
  invertScale,
}: {
  label?: string;
  subLabel?: string;
  visible: boolean;
  // clear?: boolean;
  values: string[];
  darkMapColors?: boolean;
  invertScale?: boolean;
}) {
  return (
    <div
      className={[
        styles.legend,
        visible ? "" : styles.hidden,
        // clear ? styles.clear : "",
        darkMapColors ? styles.darkMapColors : "",
      ].join(" ")}
      style={{
        backgroundColor: darkMapColors
          ? "rgba(var(--slate-900-rgb), 0.8)"
          : "var(--background-primary)",
        color: darkMapColors ? "var(--utility-white)" : "var(--text-primary)",
      }}
    >
      {label && (
        <div className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
          <span className={styles.subLabel}>{subLabel}</span>
        </div>
      )}
      <div
        className={styles.spectrum}
        style={{
          transform: invertScale ? "scaleX(-1)" : "none",
        }}
      />
      <div className={styles.values}>
        {values?.map((value, i) => (
          <div key={i} className={styles.value}>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
}
