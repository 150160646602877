import { Placeholder, Shimmer } from "./Loading";
import styles from "./Stat.module.css";

interface StatProps {
  number: string;
  unit?: string;
  label?: string;
  color?: "active" | "inactive" | "warning" | "contrast";
  small?: boolean;
  align?: "left" | "center" | "right";
  isLoading?: boolean;
}

function Stat({
  number,
  unit,
  label,
  color = "inactive",
  small = false,
  align = "center",
  isLoading = false,
}: StatProps) {
  return (
    <div
      data-testid="Stat-div"
      className={styles.Wrapper}
      style={{
        color: isLoading
          ? "var(--text-disabled)"
          : color === "contrast"
          ? "var(--text-contrast)"
          : color === "warning"
          ? "var(--text-danger)"
          : color === "active"
          ? "var(--brand-primary)"
          : "var(--text-secondary)",
        textAlign: align,
        alignItems:
          align === "left"
            ? "flex-start"
            : align === "right"
            ? "flex-end"
            : "center",
      }}
    >
      {isLoading && <Shimmer />}
      <div className={styles.Value}>
        {isLoading ? (
          <Placeholder
            style={{
              width: "2.5rem",
              height: "1.3rem",
            }}
          />
        ) : (
          <>
            <div
              className={styles.Number}
              style={{
                fontSize: small ? "var(--l)" : "var(--xl)",
              }}
            >
              {number}
            </div>
            {unit && <div className={styles.Unit}>{unit}</div>}
          </>
        )}
      </div>
      {label && <div className={styles.Label}>{label}</div>}
    </div>
  );
}

export default Stat;
