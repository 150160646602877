import Button from "brand/components/Button";
import { mngr } from "../../store";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { Spinner } from "brand/components/Loading";
import { trackEvent } from "../../tracking";

export type OrganizationForm = {
  name: string;
};

function Organization() {
  const { data: org, loading: orgLoading, error: orgError } = mngr.useOrg();
  const orgName = org?.orgName;

  const { register, getValues, setValue } = useForm<OrganizationForm>({
    defaultValues: {
      name: orgName,
    },
  });

  useEffect(() => {
    setValue("name", orgName);
  }, [orgName]);

  return (
    <>
      <h3>Organization</h3>

      {orgError ? (
        <div style={{ color: "var(--text-danger)" }}>
          Error: could not load organization
        </div>
      ) : orgLoading ? (
        <Spinner padded />
      ) : (
        <>
          {/* TODO - use a toast instead of an alert */}
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              const org = getValues();
              trackEvent("organization_renamed");
              const updatedOrgs = await mngr.putOrg(org);
              if (updatedOrgs) {
                alert("Organization name updated");
              }
            }}
          >
            <label htmlFor="organization-name">Name</label>
            <input
              disabled={orgLoading || Boolean(orgError)}
              type="text"
              id="organization-name"
              style={{
                maxWidth: "300px",
                width: "100%",
                margin: "0 0 .5rem",
              }}
              {...register("name")}
              placeholder="Your organization name"
            />
            <br />
            <Button secondary disabled={orgLoading || Boolean(orgError)}>
              Save
            </Button>
          </form>
          <br />
          <a
            href="https://precip.ai/help"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: "var(--s)",
              opacity: 0.5,
            }}
          >
            Delete organization
          </a>
        </>
      )}
    </>
  );
}

export default Organization;
