import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "@sentry/react";
import { HelmetProvider } from "react-helmet-async";

import { mngr, queryClient } from "./store";
import ErrorPage from "./pages/ErrorPage";
import { useDarkMode } from "brand/hooks/useDarkMode";
import ToastProvider from "brand/components/Toast";
import RoutesPage from "./Routes";
import { get as getLocalStorage, set as setLocalStorage } from "./LocalStorage";
import { setupTracking, trackEvent } from "./tracking";

function App() {
  useDarkMode();

  useEffect(() => {
    trackEvent("app_loaded");
    if (getLocalStorage("has_fired_first_app_load_on_device_event")) return;
    trackEvent("app_loaded_first_time_on_device");
    setLocalStorage("has_fired_first_app_load_on_device_event", true);
  }, []);

  // App preferences
  useEffect(() => {
    setupTracking();
    const preferredUnitsSystem = getLocalStorage("preferred_units_system");
    mngr.setPreferredUnits(preferredUnitsSystem || "imperial");
  }, []);

  const isDevMode = import.meta?.env?.DEV;
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ErrorBoundary
          fallback={({ error }) => <ErrorPage error={error} />}
          showDialog={!isDevMode}
        >
          <ToastProvider>
            <HelmetProvider>
              <RoutesPage />
            </HelmetProvider>
          </ToastProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
