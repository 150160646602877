import { useEffect, useId, useRef, useState } from "react";
import { useResizeObserver } from "usehooks-ts";
import { extent, max, min } from "d3-array";
import { scaleTime, scaleLinear } from "d3-scale";
import { select } from "d3-selection";
import { timeFormat } from "d3-time-format";
import { line as d3line } from "d3-shape";

import colors, { Ramp, Stop, rgbaObjToCssString } from "brand/scripts/colors";
import NowLine from "./NowLine";
import ChartTooltip from "./ChartTooltip";
import XAxis from "./XAxis";
import Debug from "./Debug";
import YAxis from "./YAxis";
import Legend, { LineStyle } from "../chart/Legend";
import { WeatherFactorId, isNil, weatherFactorsObj as wf } from "shared";
import { useDarkMode } from "../../hooks/useDarkMode";
import { Spinner } from "../Loading";

type DataType = {
  date: Date;
  value: number;
  cumulativeTotal?: number;
  source: "forecast" | "observation";
}[];

type ChartProps = {
  xAxisLabel?: string;
  yAxisLabel?: string;

  // force y axis to show 0% to 100% for ex, even when the data only goes to 40%
  yAxisBounds?: [number, number];
  yUpperBoundMinimum?: number;

  // https://d3js.org/d3-time-format
  xAxisTimeFormat?: string;
  xAxisTickFrequency?: string;
  hoverTimeFormat?: string;

  nowLine?: boolean;
  showRules?: boolean;
  noPad?: boolean;
  unitFormatter: (value: number) => string;
  toConvertedUnits: (value: number) => number;
  toOriginalUnits: (value: number) => number;
  data: DataType;
  compareData?: DataType;
  colorScale?: Ramp;
  height?: number;
  spark?: boolean;
  hideTooltip?: boolean;
  cumulative?: boolean;
  legend?: boolean;
  gradient?: boolean;
  weatherFactor?: WeatherFactorId;
  isLoading?: boolean;
};

// TODO - configure typescript right so we don't have all this ts-ignore

export default function LineChart({
  xAxisLabel = "",
  yAxisLabel = "",
  yAxisBounds,
  yUpperBoundMinimum = 0,
  showRules,
  noPad,
  xAxisTimeFormat = "%m/%d/%Y",
  xAxisTickFrequency,
  hoverTimeFormat = "%m/%d/%Y",
  nowLine = false,
  unitFormatter = (number: number) => String(number.toFixed(2)),
  toConvertedUnits = (number: number) => number,
  toOriginalUnits = (number: number) => number,
  data,
  compareData,
  colorScale = colors["blue"],
  height = 300,
  spark,
  hideTooltip = false,
  cumulative = true,
  legend = false,
  gradient,
  weatherFactor,
  isLoading = false,
}: ChartProps) {
  // We need this to give each chart it's own unique gradient element
  // because otherwise all the charts have the same gradient
  // @ts-ignore
  const componentId = useId().replaceAll(":", "X");
  const gradientId = `grad-${componentId}`;

  if (spark) height = 30;

  const isDarkMode = useDarkMode();

  const chartRef = useRef<HTMLDivElement>(null);
  const plotRef = useRef<HTMLDivElement>(null);
  const svgRef = useRef<SVGSVGElement>(null);

  const { width: chartWidth = 200, height: chartHeight = height } =
    useResizeObserver({
      ref: chartRef,
      box: "border-box",
    });

  const [tooltipContent, setTooltipContent] = useState({
    left: 0,
    isVisible: false,
    content: null,
    dotValue: null,
  });

  const Y_AXIS_WIDTH = spark ? 0 : !yAxisLabel ? 20 : 40;
  const X_AXIS_HEIGHT = spark ? 0 : legend ? 70 : 40;
  const PAD_TOP = spark ? 0 : 30;
  const PAD_RIGHT = spark || noPad ? 3 : 20;
  const now = new Date();

  const lineStyles: {
    [key: string]: LineStyle;
  } = {
    primary: {
      color: rgbaObjToCssString(colorScale.fn(0.6)),
      opacity: 1,
      strokeWidth: 4,
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeDashArray: "0",
    },
    secondary: {
      color: rgbaObjToCssString(colorScale.fn(0.6)),
      opacity: 0.6,
      strokeWidth: 4,
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeDashArray: "0",
    },
    tertiary: {
      color: rgbaObjToCssString(
        isDarkMode ? colors["slate"].fn(0.5) : colors["slate"].fn(0.3)
      ),
      opacity: 1,
      strokeWidth: 3,
      strokeLinejoin: "round",
      strokeLinecap: "round",
      strokeDashArray: "2,6",
    },
  };

  const strokeDashArray = "2,6";

  const items = data
    ?.reduce((acc, item) => {
      const prevItem = acc[acc.length - 1];
      const cumulativeTotal = prevItem?.cumulativeTotal ?? 0;
      return [
        ...acc,
        {
          date: item.date,
          value: item.value,
          cumulativeTotal: cumulativeTotal + item.value,
          source: item.source,
        },
      ];
    }, [] as DataType)
    .filter((d) => !isNil(d.value));
  const pastItems = items?.filter((item) => {
    return item.source === "observation";
  });
  const futureItemsRaw = items?.filter((item) => {
    return item.source === "forecast";
  });
  // needed to connect the two lines
  const futureItems = [...pastItems.slice(-1), ...futureItemsRaw];
  const compareItems: DataType = !compareData
    ? []
    : compareData
        ?.reduce((acc, item) => {
          const prevItem = acc[acc.length - 1];
          const cumulativeTotal = prevItem?.cumulativeTotal ?? 0;
          return [
            ...acc,
            {
              date: item.date,
              value: item.value,
              cumulativeTotal: cumulativeTotal + item.value,
              source: item.source,
            },
          ];
        }, [] as DataType)
        .filter((d) => !isNil(d?.value));

  const currentItems = [...pastItems, ...futureItems];
  const allItems = [...pastItems, ...futureItems, ...compareItems];

  // https://d3js.org/d3-time
  const xScale = scaleTime()
    .domain(extent(allItems, (d) => d.date))
    .range([0, chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)]);

  const yUpperRange = chartHeight - (X_AXIS_HEIGHT + PAD_TOP);
  const yMaxDomain = max(allItems, (d) =>
    cumulative ? d.cumulativeTotal : d.value
  );
  const yScale = scaleLinear()
    .domain(yAxisBounds || [0, Math.max(yUpperBoundMinimum, yMaxDomain) * 1.05])
    .range([yUpperRange, 0]);

  const yMinDomain = min(allItems, (d) =>
    cumulative ? d.cumulativeTotal : d.value
  );

  const pastPath =
    d3line<{ date: Date; cumulativeTotal: number }>()
      .x((d) => xScale(d.date))
      // @ts-ignore
      .y((d) => yScale(cumulative ? d.cumulativeTotal : d.value))(pastItems) ??
    "";

  const futurePath =
    d3line<{ date: Date; cumulativeTotal: number }>()
      .x((d) => xScale(d.date))
      // @ts-ignore
      .y((d) => yScale(cumulative ? d.cumulativeTotal : d.value))(
      // @ts-ignore
      futureItems
    ) ?? "";

  const comparePath =
    compareData &&
    (d3line<{ date: Date; cumulativeTotal: number }>()
      .x((d) => xScale(d.date))
      // @ts-ignore
      .y((d) => yScale(cumulative ? d.cumulativeTotal : d.value))(
      // @ts-ignore
      compareItems
    ) ??
      "");

  useEffect(() => {
    select(plotRef.current)
      .on("mouseover", () => {
        setTooltipContent({ ...tooltipContent, isVisible: !hideTooltip });
      })
      .on("mousemove", (event) => {
        const { layerX } = event;
        const hoverDate = xScale.invert(layerX);
        let hoverItem = currentItems.find((item) => {
          // Find closest date to hoverDate based on whether we're showing hours or days
          // TODO - better way to do this
          if (xAxisTimeFormat.includes("%p")) {
            return (
              item.date.toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
              }) ===
              hoverDate.toLocaleDateString(undefined, {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
              })
            );
          } else {
            return item.date.toDateString() === hoverDate.toDateString();
          }
        });
        if (!hoverItem) {
          setTooltipContent({ ...tooltipContent, isVisible: false });
          return;
        }
        const hoverDateFormattedOld = hoverDate.toLocaleDateString("en-US", {
          weekday: "short",
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        const hoverDateFormatted = timeFormat(hoverTimeFormat)(hoverDate);

        const value =
          (cumulative ? hoverItem?.cumulativeTotal : hoverItem?.value) ?? 0;
        setTooltipContent({
          left: layerX + Y_AXIS_WIDTH,
          content: (
            <>
              <div
                style={{
                  fontSize: "var(--xs)",
                  color: "var(--text-secondary)",
                }}
              >
                {hoverDateFormatted}
              </div>
              <div
                style={{
                  fontSize: "var(--s)",
                  color: "var(--text-primary)",
                }}
              >
                {unitFormatter(value)}
              </div>
            </>
          ),
          isVisible: !hideTooltip,
          dotValue: yScale(value),
        });
      })
      .on("mouseout", () =>
        setTooltipContent({ ...tooltipContent, isVisible: false })
      );
  }, [unitFormatter, chartWidth, chartHeight, X_AXIS_HEIGHT]);

  const nowDistance = xScale(now) + Y_AXIS_WIDTH;

  const stopsArray = Object.values(
    !(gradient && weatherFactor) ? {} : colorScale?.stops || {}
  );

  // TODO - more granular gradient here to keep colors from getting muddy?
  const svgGradientStops = stopsArray.map((stop: Stop) => ({
    color: stop.cssString,
    offset: `${stop.number / 10}%`,
  }));

  // We use an SVG gradient to color lines based on their value.
  // For example, a temperature line wil be blue in cold values and fade to red for hot.
  // This is done by creating an SVG gradient that covers the entire range of values for temp,
  // for ex, and then positioning it in the background of the SVG scaled appropriately to match
  // the chart data.
  // Use the min/max of the factor to size the gradient.
  // If you use the max of the data (ie yScale(yMaxDomain)),
  // the gradient will always cover the data perfectly,
  // which is too extreme a color range.
  const factorMin = toConvertedUnits(wf[weatherFactor]?.min || 0);
  const factorMax = toConvertedUnits(wf[weatherFactor]?.max || 100);

  // Map weather factor values => percentages (the format svg gradients use)
  const gradientPositionScale = scaleLinear()
    .domain([yMaxDomain, yMinDomain])
    .range([0, 100]);

  const gradientY1 = gradientPositionScale(factorMin);
  const gradientY2 = gradientPositionScale(factorMax);

  weatherFactor &&
    console.log("gradient align", {
      weatherFactor,
      factorMin,
      factorMax,
      yMaxDomain,
      yMinDomain,
      gradientY1: `${gradientY1}%`,
      gradientY2: `${gradientY2}%`,
    });

  return (
    <div ref={chartRef} style={{ height, position: "relative" }}>
      {!spark && (
        <ChartTooltip
          top={PAD_TOP}
          height={chartHeight - X_AXIS_HEIGHT - PAD_TOP}
          left={tooltipContent.left}
          maxDistance={chartWidth}
          isVisible={tooltipContent.isVisible}
          content={tooltipContent.content}
          dotValue={tooltipContent.dotValue}
          dotColor={lineStyles.primary.color}
        />
      )}

      <div
        ref={plotRef}
        style={{
          position: "absolute",
          top: PAD_TOP,
          left: Y_AXIS_WIDTH,
          right: PAD_RIGHT,
          bottom: X_AXIS_HEIGHT,
          zIndex: 300,
        }}
      />

      {isLoading && (
        <div
          className="position-absolute"
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Spinner padded />
        </div>
      )}

      <svg
        ref={svgRef}
        width={chartWidth}
        height={chartHeight}
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        style={{
          position: "relative",
          zIndex: 100,
        }}
      >
        {/* Careful with ordering SVG elements, it determines z-index! */}

        {!spark && (
          <YAxis
            xPos={0}
            yPos={PAD_TOP}
            width={Y_AXIS_WIDTH}
            height={chartHeight - X_AXIS_HEIGHT}
            scale={yScale}
            label={yAxisLabel}
            showRules={!isLoading && showRules}
            fullWidth={chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)}
          />
        )}

        {/* https://d3-graph-gallery.com/graph/line_color_gradient_svg.html */}
        {gradient && (
          <linearGradient
            id={gradientId}
            x1={0}
            x2={0}
            y1={gradientY1}
            y2={gradientY2}
            gradientUnits="userSpaceOnUse"
          >
            {svgGradientStops.map(({ offset, color }) => (
              <stop key={offset} offset={offset} stopColor={color} />
            ))}
          </linearGradient>
        )}

        {/* Compare should go "under" past and future to prevent color overlap weirdness*/}
        <path
          transform={`translate(${Y_AXIS_WIDTH}, ${PAD_TOP})`}
          d={comparePath || []}
          stroke={lineStyles.tertiary.color}
          opacity={lineStyles.tertiary.opacity || 1}
          strokeWidth={lineStyles.tertiary.strokeWidth || 3}
          strokeLinejoin={lineStyles.tertiary.strokeLinejoin || "round"}
          strokeLinecap={lineStyles.tertiary.strokeLinecap || "round"}
          strokeDasharray={strokeDashArray}
          fill="none"
        />

        {/* Future should go "underneath" past to prevent color overlap weirdness*/}
        <path
          transform={`translate(${Y_AXIS_WIDTH}, ${PAD_TOP})`}
          d={futurePath || []}
          stroke={gradient ? `url(#${gradientId})` : lineStyles.secondary.color}
          opacity={lineStyles.secondary.opacity || 0.5}
          strokeWidth={lineStyles.secondary.strokeWidth || 3}
          strokeLinejoin={lineStyles.secondary.strokeLinejoin || "round"}
          strokeLinecap={lineStyles.secondary.strokeLinecap || "round"}
          fill="none"
        />

        <path
          transform={`translate(${Y_AXIS_WIDTH}, ${PAD_TOP})`}
          d={pastPath || []}
          stroke={gradient ? `url(#${gradientId})` : lineStyles.primary.color}
          opacity={lineStyles.primary.opacity || 1}
          strokeWidth={lineStyles.primary.strokeWidth || 3}
          strokeLinejoin={lineStyles.primary.strokeLinejoin || "round"}
          strokeLinecap={lineStyles.primary.strokeLinecap || "round"}
          fill="none"
        />

        {!spark && (
          <XAxis
            xPos={Y_AXIS_WIDTH}
            yPos={chartHeight - X_AXIS_HEIGHT}
            width={chartWidth - (Y_AXIS_WIDTH + PAD_RIGHT)}
            height={X_AXIS_HEIGHT}
            scale={xScale}
            format={xAxisTimeFormat}
            label={xAxisLabel}
            tickFrequency={xAxisTickFrequency}
          />
        )}

        {nowLine && !tooltipContent.isVisible && (
          <NowLine
            nowDistance={nowDistance}
            maxDistance={chartWidth}
            height={chartHeight - X_AXIS_HEIGHT}
          />
        )}

        <Debug
          width={chartWidth}
          height={chartHeight}
          top={PAD_TOP}
          right={PAD_RIGHT}
          bottom={X_AXIS_HEIGHT}
          left={Y_AXIS_WIDTH}
          hide
        />
      </svg>

      {legend && (
        <Legend
          left={Y_AXIS_WIDTH - 2} // minus a few pix for optical alignment
          items={[
            { label: "Observed", style: lineStyles.primary },
            { label: "Forecast", style: lineStyles.secondary },
            { label: "30yr average", style: lineStyles.tertiary },
          ]}
        />
      )}
    </div>
  );
}
