import RawLayout from "./RawLayout";
import styles from "./PageLayout.module.css";
import { useToast } from "brand/components/Toast";

const PageLayout = ({
  children,
  maxWidth,
}: {
  children: React.ReactNode;
  maxWidth?: number;
}) => {
  const { addToast } = useToast();
  return (
    <RawLayout>
      <div className={styles.layout}>
        <div className={styles.content}>
          <div
            style={{
              margin: "0 auto",
              width: "100%",
              maxWidth: maxWidth + "px" || "auto",
            }}
          >
            {children}
          </div>
        </div>
        <footer className={styles.footer}>
          <span
            onClick={() =>
              addToast({
                title: "Precipitation, Inc.",
              })
            }
          >
            &copy;
          </span>{" "}
          {new Date().getFullYear()}{" "}
          <a href="https://getprecip.com/" target="_blank">
            Precipitation Inc.
          </a>
        </footer>
      </div>
    </RawLayout>
  );
};
export default PageLayout;
