import { Pencil1Icon, TrashIcon } from "@radix-ui/react-icons";

import tableStyles from "brand/components/Table.module.css";
import { Spinner } from "brand/components/Loading";
import Button from "brand/components/Button";
import DropdownMenu, { DropdownMenuItem } from "brand/components/DropdownMenu";
import { mngr } from "../../store";
import EmptyState, { ErrorState } from "brand/components/EmptyState";
import { trackEvent } from "../../tracking";

interface GroupRow {
  id: string;
  name: string;
  count: number;
}

export default function Groups() {
  const {
    data: groupsObj,
    loading: groupsLoading,
    error: groupsError,
  } = mngr.useGroups();
  const {
    data: locationsObj,
    loading: locationsLoading,
    error: locationsError,
  } = mngr.useLocations();
  const isLoading = groupsLoading || locationsLoading;
  const error = groupsError || locationsError;
  const groups = Object.values(groupsObj || {}) as GroupRow[];
  const locations = Object.values(locationsObj || {});
  groups.forEach((g) => {
    g.count = locations.filter((l) => l.properties.groupId === g.id).length;
  });

  if (error) {
    return (
      <ErrorState
        title={`Sorry, we couldn't load your groups right now`}
        subtext={`Please try again in a minute`}
      />
    );
  }
  return (
    <>
      <h3>Groups</h3>
      {isLoading ? (
        <Spinner padded />
      ) : groups.length === 0 ? (
        <EmptyState text="No groups yet" />
      ) : (
        <table
          className={`${tableStyles.table} ${tableStyles.padded} ${tableStyles.lines}`}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Locations</th>
              <th style={{ width: 0 }} />
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={16}>
                  <Spinner padded />
                </td>
              </tr>
            ) : (
              groups
                .sort((a, b) => {
                  if (a.name && b.name) {
                    return a.name.localeCompare(b.name);
                  }
                  return 0;
                })
                .map((g) => {
                  return <GroupRow key={g.id} group={g} />;
                })
            )}
          </tbody>
        </table>
      )}
      <br />
      <Button
        secondary
        onClick={() => {
          const name = prompt("Enter a name for the new group");
          if (!name || name === "") return;
          trackEvent("group_added");
          mngr.addGroup(name);
        }}
      >
        Add group
      </Button>
    </>
  );
}

function GroupRow({ group }: { group: GroupRow }) {
  return (
    <tr>
      <td>
        {group.name.length ? (
          group.name
        ) : (
          <span style={{ color: "var(--text-disabled)" }}>Unnamed group</span>
        )}
      </td>
      <td>
        {group.count === 0 ? (
          <span style={{ color: "var(--text-disabled)" }}>No locations</span>
        ) : group.count === 1 ? (
          "1 location"
        ) : (
          `${group.count} locations`
        )}
      </td>
      <td style={{ textAlign: "right" }}>
        <DropdownMenu label="Member actions" align="end">
          <DropdownMenuItem
            onSelect={() => {
              const name = prompt("Enter a new name for the group", group.name);
              if (!name || name === "") return;
              trackEvent("group_renamed");
              mngr.renameGroup(group.id, name);
            }}
          >
            <Pencil1Icon />
            Rename
          </DropdownMenuItem>
          <DropdownMenuItem
            destructive
            onSelect={() => {
              if (
                confirm(
                  `Are you sure you want to delete "${group.name}"? ${
                    group.count > 0
                      ? "Locations in this group will not be deleted."
                      : ""
                  }`
                )
              ) {
                trackEvent("group_removed");
                mngr.deleteGroup(group.id);
              }
            }}
          >
            <TrashIcon />
            Delete
          </DropdownMenuItem>
        </DropdownMenu>
      </td>
    </tr>
  );
}
