import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, ReactNode } from "react";

import { mngr } from "./store";
import Map from "./pages/Map";
import Location from "./pages/Location";
import Report from "./pages/Report";
import Wind from "./pages/Wind";
import LocationsPage from "./pages/Locations";
import SignInPage from "./pages/auth/SignIn";
import SignUpPage from "./pages/auth/SignUp";
import SignInWithEmailLink from "./pages/auth/SignInWithEmailLink";
import Settings from "./pages/settings/Settings";
import ErrorPage from "./pages/ErrorPage";
import {
  DevPortalSignIn,
  DevPortalSignOut,
  DevPortalSignUp,
} from "./pages/DevPortalAuth";
import redirects from "./redirects";
import { useAuth } from "./Auth";
import SignIn from "./pages/auth/SignIn";
import { Spinner } from "brand/components/Loading";
import SignInTest from "./pages/auth/SignInTest";
import SignUpTest from "./pages/auth/SignUpTest";
import { get as getLocalStorage, set as setLocalStorage } from "./LocalStorage";
import Test from "./pages/Test";
import Compare from "./pages/Compare";

function AuthWrap({ children }: { children: ReactNode }) {
  const {
    data: backendUser,
    loading: userLoading,
    error: userError,
  } = mngr.useUser();
  const { data: session, loading: authLoading } = useAuth();

  const loading = userLoading || authLoading;
  const error = userError; // TODO - or authError;
  const isSignedIn = !!session;
  const orgId = mngr.useOrgId();

  useEffect(() => {
    if (!isSignedIn) return;
    mngr.fetchUser().then(() => {
      const orgs = mngr.backendUser?.orgs || [];
      if (mngr.orgId || !orgs.length) return;
      const recentOrgId = getLocalStorage("most_recent_org_id");
      const orgId =
        recentOrgId && orgs.find((w) => w.orgId === recentOrgId)
          ? recentOrgId
          : recentOrgId === "personal"
          ? null
          : orgs[0].orgId;

      console.log("setting most recent org id", orgId);
      mngr.setOrgId(orgId);
      // TODO - move this to state manager once we have
      // a single api for localstorage on both platforms
      setLocalStorage("most_recent_org_id", orgId || "personal");
    });
  }, [isSignedIn]);

  useEffect(() => {
    if (!isSignedIn) return;
    // fetchUser to set orgID
    mngr.fetchLocations();
  }, [orgId, isSignedIn, backendUser]);

  const userEmail = backendUser?.user?.email;

  // careful with the logic here
  if (error) return <ErrorPage error={error} />;
  if (loading) return <Spinner padded />;
  if (!isSignedIn) return <SignIn />;
  if (!userEmail) return <Spinner padded />;
  return <>{children}</>;
}

export default function RoutesPage() {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/locations" />} />

      <Route
        path="/dev-portal/sign-in/*"
        element={
          <AuthWrap>
            <DevPortalSignIn />
          </AuthWrap>
        }
      />
      <Route
        path="/dev-portal/sign-up/*"
        element={
          <AuthWrap>
            <DevPortalSignUp />
          </AuthWrap>
        }
      />
      <Route
        path="/dev-portal/sign-out/*"
        element={
          <AuthWrap>
            <DevPortalSignOut />
          </AuthWrap>
        }
      />

      <Route path="/sign-in/*" element={<SignInPage />} />
      <Route path="/sign-in-test/*" element={<SignInTest />} />
      <Route path="/sign-up/*" element={<SignUpPage />} />
      <Route path="/sign-up-test/*" element={<SignUpTest />} />
      <Route
        path="/sign-in-with-email-link/*"
        element={<SignInWithEmailLink />}
      />
      <Route
        path="/locations"
        element={
          <AuthWrap>
            <LocationsPage />
          </AuthWrap>
        }
      />
      <Route
        path="/map"
        element={
          <AuthWrap>
            <Map />
          </AuthWrap>
        }
      />
      <Route
        path="/location/:locationId"
        element={
          <AuthWrap>
            <Location />
          </AuthWrap>
        }
      />
      <Route
        path="/report/:locationId"
        element={
          <AuthWrap>
            <Report />
          </AuthWrap>
        }
      />
      <Route
        path="/wind/:locationId"
        element={
          <AuthWrap>
            <Wind />
          </AuthWrap>
        }
      />
      <Route
        path="/settings/*"
        element={
          <AuthWrap>
            <Settings />
          </AuthWrap>
        }
      />

      {/* Secret routes for internal testing */}
      <Route path="/test" element={<Test />} />
      <Route
        path="/compare"
        element={
          <AuthWrap>
            <Compare />
          </AuthWrap>
        }
      />

      {redirects.map((r) => (
        <Route
          key={r.from}
          path={r.from}
          element={<Navigate to={r.to} replace />}
        />
      ))}
      <Route path="/*" element={<ErrorPage />} />
    </Routes>
  );
}
