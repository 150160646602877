import { Warning } from "@phosphor-icons/react";
import styles from "./EmptyState.module.css";
import Button from "./Button";

export default function EmptyState({ text }: { text: string }) {
  return <div className={styles.wrapper}>{text}</div>;
}

export function ErrorState({
  error,
  title,
  subtext,
  ctaText = "Contact support",
  ctaOnClick = () => {
    window.open("https://precip.ai/help", "_blank");
  },
}: {
  error?: Error;
  title?: string;
  subtext?: string;
  ctaText?: string;
  ctaOnClick?: () => void;
}) {
  return (
    <div className={styles.error}>
      <Warning size={24} />
      <div style={{ fontSize: "var(--m)" }}>{title || "Could not load"}</div>
      <div style={{ fontSize: "var(--s)" }}>
        {subtext || error?.message || "Try again in a minute?"}
      </div>
      <Button
        secondary
        // TODO - destructive
        onClick={ctaOnClick}
      >
        {ctaText}
      </Button>
    </div>
  );
}
