import * as Dialog from "@radix-ui/react-dialog";
import styles from "./Modal.module.css";
import { CloseX } from "brand/components/CloseX";

export default function Modal({
  isOpen,
  onClose,
  onPresent,
  title,
  description,
  size = "medium",
  maxHeight,
  children,
}: {
  isOpen: boolean;
  onClose?: () => void;
  onPresent?: () => void;
  title?: string;
  description?: string;
  size?: "small" | "medium" | "large";
  maxHeight?: string;
  children: React.ReactNode;
}) {
  return (
    <Dialog.Root
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (isOpen) {
          onPresent?.();
        } else {
          onClose?.();
        }
      }}
    >
      <Dialog.Portal>
        <Dialog.Overlay className={styles.overlay} />
        <Dialog.Content
          className={styles.content}
          style={{
            maxWidth:
              size === "small" ? "400px" : size === "medium" ? "800px" : "85vw",
            height: size === "large" ? "85vh" : "auto",
            maxHeight: maxHeight,
          }}
        >
          <Dialog.Close asChild>
            <CloseX size="m" />
          </Dialog.Close>
          <Dialog.Title className={styles.title}>{title}</Dialog.Title>
          <Dialog.Description className={styles.description}>
            {description}
          </Dialog.Description>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
