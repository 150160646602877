// paywalls
export const MAX_FREE_LOCATIONS: number = 2;
export const MAX_PAID_LOCATIONS: number = 50;
export const MAX_ENTERPRISE_LOCATIONS: number = Infinity;

export const DAY_CUTOFF_WHEN_WE_NO_LONGER_SHOW_RAIN_EVENT_END_DATES = 30;

export const MINIMUM_SESSIONS_BEFORE_PROMPTING_REVIEW = 3;

// revenuecat
export const ENTITLEMENT_HISTORY_IDENTIFIER = "Historical_Data";
export const ENTITLEMENT_LAYERS_IDENTIFIER = "Map_Layers";
export const ENTITLEMENT_TEN_LOCATIONS_IDENTIFIER = "10_Locations";
export const ENTITLEMENT_FIFTY_LOCATIONS_IDENTIFIER = "50_Locations";
export const ENTITLEMENT_UNLIMITED_LOCATIONS_IDENTIFIER = "Unlimited_Locations";

// rain
export const RAIN_RELEVANCE_EXPIRY_DAYS = 7; // how many days until rain no longer looks blue
export const RAIN_HISTORY_COVERAGE_DURATION = "January 2023"; // earliest rain history

// tests
export const testRunnerEmailDomain = "@precip-test-email-domain.com";

// enterprise users
export const ENTERPRISE_CUSTOMER_EMAIL_DOMAINS = [
  // Real customers
  "@lithoscarbon.com",
  "@benchmarklabs.com",
  "@farmhq.com",
  "@livingcarbon.com",
  "@scmac.org",
  "@agrisksolutions.ca",

  // Internal users
  "@getprecip.com",

  // Integration tests
  testRunnerEmailDomain,
];

export const ENTERPRISE_CUSTOMER_INDIVIDUAL_EMAILS = [
  // Real customers
  "Tony.Meekes@syngenta.com",
  "Joe_Ben.Bogle@syngenta.com",

  // Internal users
  "nataliegvollmar@gmail.com",

  // Integration tests
  // TODO
];

export const ENTERPRISE_BETA_CUSTOMER_EMAIL_DOMAINS = [
  // Real customers
  "@agrisksolutions.ca",

  // Internal users
  "@getprecip.com",

  // Integration tests
  testRunnerEmailDomain,
];
