import {
  CrossCircledIcon,
  ExclamationTriangleIcon,
} from "@radix-ui/react-icons";

import styles from "brand/components/map/StatMarker.module.css";
import Tooltip from "brand/components/Tooltip";
import { Placeholder, Shimmer } from "brand/components/Loading";
import { useDarkMode } from "brand/hooks/useDarkMode";
import { mngr } from "../../store";
import { mmToIn } from "shared";
// import { IonSpinner } from "@ionic/react";

// TODO - multiple kinds of props for different situations
// For example, if we're loading, there should be no amount; etc
interface StatMarkerProps {
  // name?: string;
  // factor: WeatherFactor;
  // precipType?: PrecipType;
  // noData?: boolean;
  // amount?: number;
  // active?: boolean;
  // isLoading?: boolean;
  // windDegrees?: number;
  leftName;
  leftValue;
  rightName;
  rightValue;
  isLoading;

  warning?: string;
  error?: Error;
  hideTooltip?: boolean;
  onClick?: (evt: any) => void;
}

function StatMarker({
  // name = "Unnamed location",
  // factor,
  // precipType,
  // noData = false,
  // amount,
  // active = false,
  leftName,
  leftValue,
  rightName,
  rightValue,
  isLoading = false,
  // windDegrees,
  warning,
  error,
  hideTooltip = false,
  onClick,
}: StatMarkerProps) {
  const isDarkMode = useDarkMode();

  const { preferredUnits } = mngr.useUnits();
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;

  const noData = false;

  const backgroundColor = "var(--background-primary)";
  const textColor = "var(--text-primary)";

  // const tooltipContent = (
  //   <>
  //     {name || "Unnamed location"}
  //     <br />
  //     {/* {fullAmountFormatted} {precipType || ""}{" "}
  //     {factor === "wind"
  //       ? "current wind speed"
  //       : factor === "temp"
  //       ? "current soil temperature"
  //       : "in the past 48 hours"} */}
  //   </>
  // );

  const markerInner = (
    <div
      className={styles.inner}
      // Dark mode markers use transparent backgrounds
      // so we need to set a background on the background
      style={{ background: isDarkMode && "var(--background-primary)" }}
    >
      <div
        className={styles.background}
        style={{
          color: textColor,
          backgroundColor: backgroundColor,
        }}
      >
        {/* {active && factor === "precip" && precipType === "rain" && (
          <RainDrops color={intensity && intensity < 1 ? "blue" : "white"} />
        )}
        {active && factor === "precip" && precipType === "snow" && (
          <SnowFall color={intensity && intensity < 1 ? "blue" : "white"} />
        )} */}
        <Shimmer isLoading={isLoading} />
        {noData ? (
          <div className={styles.content}>
            <ExclamationTriangleIcon />
            <span className="flex-row gap-2 align-base">
              No data
              {/* <Tooltip
                trigger={
                  <a
                    href="/TODO"
                    style={{
                      fontSize: "var(--xs)",
                      color: "inherit",
                      textDecorationStyle: "dotted",
                    }}
                  >
                    Why?
                  </a>
                }
              >
                This location is not reporting data.
              </Tooltip> */}
            </span>
          </div>
        ) : error ? (
          <div className={styles.content}>
            <CrossCircledIcon />
            Error
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.Value}>
              {isLoading ? (
                <Placeholder
                  style={{
                    width: "2rem",
                    height: ".9rem",
                    margin: "0 0 3px",
                  }}
                />
              ) : (
                <div className={styles.Number}>
                  {leftName}: {toConvertedUnits(leftValue).toFixed(2)}
                </div>
              )}
            </div>

            <div className={styles.Value}>
              {isLoading ? (
                <Placeholder
                  style={{
                    width: "2rem",
                    height: ".9rem",
                    margin: "0 0 3px",
                  }}
                />
              ) : (
                <div className={styles.Number}>
                  {rightName}: {toConvertedUnits(rightValue).toFixed(2)}
                </div>
              )}
            </div>

            {/* {windDegrees &&
              windDegrees !== 0 &&
              factor === "wind" &&
              !isLoading && <WindDirection degrees={windDegrees} size={8} />} */}
            {/* {amount > 0 && factor === "precip" && precipType === "snow" && (
              <div
                style={{
                  width: "12px",
                  height: "12px",
                }}
              >
                <PrecipTypeIcon precipType={precipType} size={12} />
              </div>
            )} */}
            {warning && <div className={styles.yellowIcon}>!</div>}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={styles.MarkerWrapper}
      onClick={(evt) => onClick && onClick(evt)}
    >
      {markerInner}
      {/* {tooltipContent && !hideTooltip ? (
        <Tooltip hideArrow delayDuration={0} trigger={markerInner}>
          {tooltipContent}
          {warning && (
            <>
              <br />
              <br />
              {warning} Click to learn more.
            </>
          )}
        </Tooltip>
      ) : (
        markerInner
      )} */}

      {/* make sure translucent background colors still appear in the arrow */}
      <div
        className={styles.PopupArrow}
        style={{
          borderTop: `var(--arrow-height) solid var(--background-primary)`,
        }}
      />
      <div
        className={styles.PopupArrow}
        style={{
          borderTop: `var(--arrow-height) solid ${backgroundColor}`,
        }}
      />
    </div>
  );
}

export default StatMarker;
