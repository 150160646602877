import { useEffect, useState } from "react";
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";

import { mngr } from "./store";
import {
  get as getLocalStorage,
  set as setLocalStorage,
  remove as removeLocalStorage,
} from "./LocalStorage";
import { getFirebaseConfig } from "./firebaseConfig";

const firebaseApp = initializeApp(getFirebaseConfig(import.meta.env));

export const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle();
  return result.user;
};

export const signInWithApple = async () => {
  const result = await FirebaseAuthentication.signInWithApple({
    // TODO no idea if these are necessary?
    customParameters: [{ key: "nonce", value: "example-nonce" }],
  });
  return result.user;
};

export const requestLinkForEmailLinkSignIn = async ({ email }) => {
  const actionCodeSettings = {
    // redirect here when clicking the link
    url: `${window.location.origin}/sign-in-with-email-link`,
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.getprecip.ios",
    // },
    // android: {
    //   packageName: "com.getprecip.android",
    //   installApp: true,
    //   minimumVersion: "12",
    // },
    // dynamicLinkDomain: "precip.page.link",
  };

  const auth = getAuth();
  return sendSignInLinkToEmail(auth, email, actionCodeSettings)
    .then(() => {
      setLocalStorage("email_for_sign_in_with_email_link", email);
      return {
        error: null,
      };
    })
    .catch((error) => {
      return {
        error,
      };
    });
};

export const handleLinkFromEmailLinkSignIn = async () => {
  const auth = getAuth();

  // Confirm the link is a sign-in with email link
  if (!isSignInWithEmailLink(auth, window.location.href)) {
    return {
      user: null,
      error: new Error("This link is not a valid email sign-in link"),
    };
  }

  // TODO - send more state parameters in the URL to continue the user's intended action?

  let email = getLocalStorage("email_for_sign_in_with_email_link");

  if (!email) {
    // User opened the link on a different device.
    // For security, require the email address again.
    // TODO - use UI for this, not a prompt
    email = window.prompt("Please provide your email for confirmation");
  }

  // Firebase parses the OTP code from the link
  return signInWithEmailLink(auth, email, window.location.href)
    .then((result) => {
      removeLocalStorage("email_for_sign_in_with_email_link");
      return {
        user: result.user,
        error: null,
      };
    })
    .catch((error) => {
      return {
        user: null,
        error,
      };
    });
};

// Email method should only be used by integration tests
// https://firebase.google.com/docs/auth/web/password-auth
export const signUpWithEmailAndPassword_TESTING_ONLY = async (
  email: string,
  password: string
) => {
  const result = await FirebaseAuthentication.createUserWithEmailAndPassword({
    email,
    password,
  });
  return result.user;
};

export const signInWithEmailAndPassword_TESTING_ONLY = async (
  email: string,
  password: string
) => {
  const result = await FirebaseAuthentication.signInWithEmailAndPassword({
    email,
    password,
  });
  return result.user;
};

export async function signOut() {
  await FirebaseAuthentication.signOut();
}

export function useAuth() {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    FirebaseAuthentication.addListener("authStateChange", async ({ user }) => {
      // @ts-ignore
      mngr.firebaseUser = user;
      if (user) {
        const { token } = await FirebaseAuthentication.getIdToken();
        mngr.setAuthToken(token);
        setInterval(async () => {
          const { token } = await FirebaseAuthentication.getIdToken();
          console.log("refreshing token", token);
          mngr.setAuthToken(token);
        }, 1000 * 60 * 30);
      }
      setLoading(false);
      setSession(user);
    });
  }, []);

  return { data: session, loading };
}
