import * as RadixTooltip from "@radix-ui/react-tooltip";

import styles from "./Tooltip.module.css";

export default function Tooltip({
  trigger,
  delayDuration = 200,
  hideArrow = true,
  contrast = true,
  side = "top",
  sideOffset = 5,
  align = "center",
  alignOffset = 0,
  children,
}: {
  trigger: React.ReactNode;
  delayDuration?: number;
  hideArrow?: boolean;
  contrast?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  sideOffset?: number;
  align?: "start" | "center" | "end";
  alignOffset?: number;
  children: React.ReactNode;
}) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delayDuration}>
        <RadixTooltip.Trigger asChild>{trigger}</RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={[
              styles.TooltipContent,
              contrast && styles.TooltipContentContrast,
            ].join(" ")}
            sideOffset={sideOffset}
            side={side}
            align={align}
            alignOffset={alignOffset}
          >
            {children}
            {!hideArrow && (
              <RadixTooltip.Arrow className={styles.TooltipArrow} />
            )}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
}
