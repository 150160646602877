import { useEffect, useState } from "react";

// TODO - better way to handle state manager?
// @ts-ignore
const mngr = window.mngr;
if (!mngr) throw new Error("mngr not defined");

export type Coords = [number, number] | undefined;

// the purpose of this function is to monitor the current position of the map,
// update the URL state, and report the position to consumers if they want it
// Consumers should use mngr.map?.setCenter(center) and mngr.map?.setZoom(zoom)
// to update the map and this function will be notified of the new position

export const useMapPosition = () => {
  const [center, setCenter] = useState<Coords | undefined>(undefined);
  const [zoom, setZoom] = useState<number | undefined>(undefined);

  // TODO - trigger on setupComplete?
  // update the position state based on the map
  const updatePositionState = () => {
    const center = mngr.map?.getCenter().toArray() as Coords | null;
    const zoom = mngr.map?.getZoom();
    setCenter(center);
    setZoom(zoom);

    if (!center) return;
    setCenter(center);
  };

  // update the URL based on the position of the map
  // when the user refreshes the page, the map state will remain
  // TODO - just remove? i guess this is still useful for links?
  const setPositionInUrl = () => {
    const center = mngr.map?.getCenter().toArray() as Coords | null;

    if (!center) return;
    const lng = center[0].toFixed(4);
    const lat = center[1].toFixed(4);
    const zoom = mngr.map.getZoom().toFixed(2);
    window.history.replaceState({}, "", `?lng=${lng}&lat=${lat}&zoom=${zoom}`);
  };

  useEffect(() => {
    mngr.map?.on("idle", updatePositionState);
    mngr.map?.on("move", updatePositionState);
    mngr.map?.on("moveend", setPositionInUrl);

    return () => {
      mngr.map?.off("idle", updatePositionState);
      mngr.map?.off("move", updatePositionState);
      mngr.map?.off("moveend", setPositionInUrl);
    };
  }, [mngr.map]);

  const flyTo = (center: Coords) => {
    // zoom in if you're far away, otherwise keep the same zoom
    const currentZoom = mngr.map?.getZoom();
    const minZoom = 6; // smaller number is wider zoom

    mngr.map?.flyTo({
      center,
      zoom: Math.max(currentZoom, minZoom),
    });
  };

  return {
    center,
    zoom,
    flyTo,
    updatePositionState,
  };
};
