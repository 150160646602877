import styles from "./Legend.module.css";

export type LineStyle = {
  color: string;
  opacity?: number;
  strokeWidth?: number | string;
  strokeLinejoin?: "inherit" | "round" | "miter" | "bevel";
  strokeLinecap?: "inherit" | "round" | "butt" | "square";
  strokeDashArray?: string;
};

type LegendItem = {
  label: string;
  style: LineStyle;
};

export default function ChartLegend({
  items,
  left = 0,
}: {
  items: LegendItem[];
  left: number;
}) {
  const width = 20;
  const height = 16;
  const pad = 4; // room for round end caps to show

  return (
    <div className={styles.legend} style={{ left }}>
      {items.map((item, i) => (
        <div key={i} className={styles.item}>
          <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <path
              d={`M ${0 + pad} ${height / 2} L ${width - pad} ${height / 2}`}
              stroke={item.style.color}
              opacity={item.style.opacity || 1}
              strokeWidth={item.style.strokeWidth || 3}
              strokeLinejoin={item.style.strokeLinejoin || "round"}
              strokeLinecap={item.style.strokeLinecap || "round"}
              strokeDasharray={item.style.strokeDashArray || "0"}
              fill="none"
            />
          </svg>
          <div className={styles.label}>{item.label}</div>
        </div>
      ))}
    </div>
  );
}
