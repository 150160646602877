import Card from "brand/components/Card";
import LineChart from "brand/components/chart/LineChart";

import {
  useLocationDaily,
  fmtRainUnitM,
  fmtRainUnitI,
  mmToIn,
  inToMM,
} from "shared";
import { Spinner } from "brand/components/Loading";
import { ErrorState } from "brand/components/EmptyState";
import { mngr } from "../store";

export default function LocationPageHistory({ location }) {
  const monthDt = new Date();

  const { preferredUnits } = mngr.useUnits();
  const fmtRainUnit = preferredUnits === "metric" ? fmtRainUnitM : fmtRainUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : inToMM;

  const {
    data: dataDaily,
    isLoading: isLoadingDaily,
    error: errorDaily,
  } = useLocationDaily(location);

  const {
    data: dataDailyNormal,
    // isLoading: isLoadingDailyNormal,
    // error: errorDailyNormal,
  } = useLocationDaily(location, null, null, "prism-normal-precip");

  const dataFormattedDaily = dataDaily?.properties?.days?.map(
    ({ startTime, precip, source }) => {
      return {
        value: toConvertedUnits(precip),
        date: new Date(startTime),
        source,
      };
    }
  );

  const dataFormattedDailyNormal = dataDailyNormal?.properties?.days?.map(
    ({ startTime, precip, source }) => {
      return {
        value: toConvertedUnits(precip),
        date: new Date(startTime),
        source,
      };
    }
  );

  return (
    <Card>
      <div
        className="flex-row gap-4 justify-between align-center"
        style={{ marginBottom: "1rem" }}
      >
        <h4>Cumulative precipitation</h4>
      </div>
      {isLoadingDaily ? (
        <Spinner padded />
      ) : errorDaily ? (
        <ErrorState error={errorDaily} />
      ) : (
        <LineChart
          xAxisLabel={String(monthDt.getFullYear())}
          yAxisLabel="Total rainfall"
          xAxisTimeFormat="%b"
          hoverTimeFormat="%a, %b %d, %Y"
          nowLine={true}
          unitFormatter={fmtRainUnit}
          toConvertedUnits={toConvertedUnits}
          toOriginalUnits={toOriginalUnits}
          legend={true}
          showRules
          data={dataFormattedDaily}
          compareData={dataFormattedDailyNormal}
        />
      )}
    </Card>
  );
}
