// UNITS
// TODO - write unit tests for these? (no pun intended)

// Metric/imperial conversion constants
const MM_TO_INCH = 25.4;
const MPS_TO_MPH = 2.23694;

// Liquid to solid radios
export const RAIN_TO_SNOW = 13;
const RAIN_TO_SNOW_MIN = 9;
const RAIN_TO_SNOW_MAX = 14;

// Number formatting util
//   null  => "..."
//   1.234 => "1.23"
//   0.001 => "0"
export const num = (n: number, digits = 2) => {
  if (!n && n !== 0) return "...";
  if (n === 0) return "0";
  const value = n.toFixed(digits);
  // don't return "0.00"
  // @ts-ignore
  if (value.replaceAll(".", "").replaceAll("0", "") === "") return "0";
  return value;
};

export function isNil(n: any) {
  return n === null || n === undefined || isNaN(n);
}

// Unit conversion functions
// TODO - handle all these in the app
export const cToF = (c: number) => (isNil(c) ? c : (c * 9) / 5 + 32);
export const fToC = (f: number) => (isNil(f) ? f : ((f - 32) * 5) / 9);
export const mmToIn = (mm: number) => (isNil(mm) ? mm : mm / MM_TO_INCH);
export const inToMM = (inch: number) =>
  isNil(inch) ? inch : inch * MM_TO_INCH;
export const mpsToMph = (mps: number) => (isNil(mps) ? mps : mps * MPS_TO_MPH);
export const mphToMps = (mph: number) => (isNil(mph) ? mph : mph / MPS_TO_MPH);

// ----------------------------------------------------------------------------
// It maybe be worth moving all of these formatting functions to the state manager,
// so you could just call fmtRain and it would know what units to use based on
// on your preferred units system.
// However that would require some significant refactoring since our components
// (which live outside the applications and don't have access to app state)
// currently depend on these directly.
// For now I'm just adding separate functions for imperial/metric and calling
// them separately depending on the user's units preference.
// If you do decide to refactor, not that the significant digits are different
// between the two systems. For ex, rain should be 1.25" or 32mm (not 31.75mm)
// Naming format: fooM = metric and fooI = imperial

// Format to string w/ rounding
// For use when the number has already been converted to the correct units
export const fmtTempUnitM = (tempC: number) => `${num(tempC, 0)}°C`;
export const fmtTempUnitI = (tempF: number) => `${num(tempF, 0)}°F`;
export const fmtWindUnitM = (mps: number) => `${num(mps, 0)} m/s`;
export const fmtWindUnitI = (mph: number) => `${num(mph, 0)} mph`;
export const fmtRainUnitM = (mm: number, digits?: number) =>
  `${num(mm, digits || 0)}mm`;
export const fmtRainUnitI = (inch: number, digits?: number) =>
  `${num(inch, digits || 2)}"`;
export const fmtSnowUnitM = (mm: number) => `${num(mm, 0)}mm`;
export const fmtSnowUnitI = (inch: number) => `${num(inch, 1)}"`;

// Combine conversion and unit formatting
export const fmtTempM = (tempC: number) => fmtTempUnitM(tempC);
export const fmtTempI = (tempC: number) => fmtTempUnitI(cToF(tempC));
export const fmtWindM = (mps: number) => fmtWindUnitM(mps);
export const fmtWindI = (mps: number) => fmtWindUnitI(mpsToMph(mps));
export const fmtRainM = (mm: number, digits?: number) =>
  fmtRainUnitM(mm, digits);
export const fmtRainI = (mm: number, digits?: number) =>
  fmtRainUnitI(mmToIn(mm), digits);
export const fmtSnowM = (mm: number) => fmtSnowUnitM(mm * RAIN_TO_SNOW);
export const fmtSnowI = (mm: number) => fmtSnowUnitI(mmToIn(mm * RAIN_TO_SNOW));

// These do both conversion and formatting only (for now)
export const fmtSnowRangeM = (mm: number) => {
  if (!mm && mm !== 0) return `...mm`;
  const min = mm * RAIN_TO_SNOW_MIN;
  const max = mm * RAIN_TO_SNOW_MAX;
  if (min === max) return `${num(min, 1)}mm`;
  return `${num(min, 1)}-${num(max, 1)}mm`;
};
export const fmtSnowRangeI = (mm: number) => {
  if (!mm && mm !== 0) return `..."`;
  const min = mmToIn(mm * RAIN_TO_SNOW_MIN);
  const max = mmToIn(mm * RAIN_TO_SNOW_MAX);
  if (min === max) return `${num(min, 1)}"`;
  return `${num(min, 1)}-${num(max, 1)}"`;
};

// not metric/imperial dependent
export const fmtPercent = (percent: number) => {
  if (!percent && percent !== 0) return `...%`;
  const p = Math.min(100, percent);
  return `${num(p, 0)}%`;
};
