import RawLayout from "./RawLayout";
import styles from "./AccountLayout.module.css";

const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <RawLayout hideNav>
      <div className={styles.layout}>
        <div className={styles.content}>
          <div className={styles.box}>{children}</div>
        </div>
        <footer className={styles.footer}>
          &copy; {new Date().getFullYear()}{" "}
          <a href="https://precip.ai/" target="_blank">
            Precipitation Inc.
          </a>
        </footer>
      </div>
    </RawLayout>
  );
};
export default AccountLayout;
