import styles from "./Card.module.css";

export default function Card({
  minWidth,
  primary,
  noBorder,
  noPad,
  noRadius,
  corners,
  children,
}: {
  minWidth?: string;
  primary?: boolean;
  noBorder?: boolean;
  noPad?: boolean;
  noRadius?: boolean;
  corners?: boolean;
  children: React.ReactNode;
}) {
  return (
    <div
      className={corners ? styles.cornerBox : styles.card}
      style={{
        minWidth: minWidth,
        background: primary ? "var(--background-primary)" : "",
        borderColor: noBorder && "transparent",
        padding: noPad && 0,
        borderRadius: noRadius && 0,
      }}
    >
      {corners ? (
        <>
          <div className={styles.content}>{children}</div>
          <div className={`${styles.corners} ${styles.top}`} />
          <div className={`${styles.corners} ${styles.bottom}`} />
        </>
      ) : (
        children
      )}
    </div>
  );
}
