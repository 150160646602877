import * as RadioGroup from "@radix-ui/react-radio-group";
import { Drop, ThermometerSimple, Wind } from "@phosphor-icons/react";

import styles from "./MarkerTypeToggle.module.css";
import Tooltip from "../Tooltip";
import { WeatherFactorId } from "shared";
import { Badge } from "../Badge";

const items = [
  {
    value: "precip",
    label: "Recent rain",
    icon: <Drop />,
    beta: false,
  },
  {
    value: "wind",
    label: "Average wind speed",
    icon: <Wind />,
    beta: false,
  },
  {
    value: "temp",
    label: "Soil temperature",
    icon: <ThermometerSimple />,
    beta: true,
  },
];

export default function MarkerTypeToggle({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: WeatherFactorId) => void;
}) {
  return (
    <RadioGroup.Root
      className={styles.RadioGroupRoot}
      value={value}
      onValueChange={onChange}
      aria-label="Map marker type"
    >
      {items.map((item) => {
        return (
          <RadioGroup.Item
            key={item.value}
            className={styles.RadioGroupItem}
            value={item.value}
            id={item.value}
          >
            <Tooltip
              delayDuration={0}
              contrast={false}
              side="right"
              trigger={<div className={styles.ItemCircle}>{item.icon}</div>}
            >
              {item.label} {item.beta && <Badge type="solid">Beta</Badge>}
            </Tooltip>
            <RadioGroup.Indicator className={styles.RadioGroupIndicator} />
          </RadioGroup.Item>
        );
      })}
    </RadioGroup.Root>
  );
}
