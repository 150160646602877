import styles from "./Preview.module.css";
import { Link } from "react-router-dom";
import Button from "../Button";

export default function Preview({
  linkUrl,
  title,
  meta = "",
  content = <></>,
  error,
}: {
  linkUrl: string;
  title?: string;
  meta?: React.ReactNode;
  content?: React.ReactNode;
  error?: Error;
}) {
  if (error) {
    return (
      <Link to={linkUrl} className={styles.wrapper}>
        {/* @ts-ignore */}
        <div>
          <h3>Error</h3>
          <div className={styles.meta}>{error.message}</div>
        </div>
        <div>{/* Right side empty for now */}</div>
      </Link>
    );
  }

  return (
    <a href={linkUrl} className={styles.wrapper}>
      <div>
        <h3>
          {title || (
            <span style={{ color: "var(--text-disabled)" }}>
              Unnamed location
            </span>
          )}
        </h3>
        <div className={styles.meta}>{meta}</div>
        <div className={styles.content}>{content}</div>
      </div>
      <div>
        <Button secondary>View</Button>
      </div>
    </a>
  );
}
