import { getDataColors } from "brand/scripts/colors";
import styles from "./Day.module.css";
import Tooltip from "./Tooltip";
import { PrecipTypeIconAnnotation } from "./PrecipTypeIcon";
import { PrecipType, fmtPercent, inToMM } from "shared";
import { useDarkMode } from "../hooks/useDarkMode";

export default function Day({
  amount,
  percent,
  precipType = "rain",
  isLoading = false,
  fmtRain,
  fmtSnowRange,
}: {
  amount: number;
  percent: number;
  precipType?: PrecipType;
  isLoading?: boolean;
  fmtRain: (amount: number) => string;
  fmtSnowRange: (amount: number) => string;
}) {
  const isDarkMode = useDarkMode();

  const { backgroundColor, textColor } = getDataColors({
    amount, // not needed since we're passing intensity, but types require it for now
    factor: "precip",
    precipType,
    intensity: (percent / 100) * 0.2, // because the rain max is higher now
    isDarkMode,
  });

  if (isLoading) {
    return (
      <div className={`${styles.day} ${styles.loading}`}>
        {/* TOO - for some reason layout jumps if these are removed */}
        <div className={styles.percent}></div>
        {/* TODO - pad "1" to "1.0" */}
        <div className={styles.amount}></div>
      </div>
    );
  }

  return (
    <Tooltip
      trigger={
        <div
          className={styles.day}
          style={{
            backgroundColor,
          }}
        >
          <div
            className={styles.dayInner}
            style={{
              color: textColor,
            }}
          >
            {amount > 0 && <PrecipTypeIconAnnotation precipType={precipType} />}
            <div className={styles.percent}>{fmtPercent(percent)}</div>
            <div className={styles.amount}>{fmtRain(amount)}</div>
          </div>
        </div>
      }
    >
      {amount < inToMM(0.01) && percent < 1 ? (
        "No rain expected"
      ) : (
        <>
          {fmtPercent(percent)} chance of precipitation
          <br />
          {precipType === "snow" ? (
            <div>
              {fmtSnowRange(amount)} of snow
              <br />({fmtRain(amount)} of rain equivalent)
            </div>
          ) : (
            <div>{fmtRain(amount)} of rain</div>
          )}
        </>
      )}
    </Tooltip>
  );
}
