import styles from "./Loading.module.css";

export const Spinner = ({
  padded,
  size = 20,
  dark = false,
}: {
  padded?: boolean;
  size?: number;
  dark?: boolean;
}) => {
  return (
    <div
      className="flex-col align-center justify-center"
      style={{
        padding: padded ? "4rem" : undefined,
        margin: "-2px 0",
      }}
    >
      <div
        className={styles.spinner}
        style={{
          width: size,
          height: size,
          borderWidth: size < 20 ? 1.5 : 2,
          borderColor:
            dark &&
            "rgba(var(--slate-500-rgb), 0.5) var(--slate-500) var(--slate-500)",
        }}
      />
    </div>
  );
};

export const Shimmer = ({
  isLoading = true,
  light = false,
  slow = false,
}: {
  isLoading?: boolean;
  light?: boolean;
  slow?: boolean;
}) => {
  const color = light ? "rgba(var(--utility-white-rgb), 0.05)" : "var(--shine)";
  return (
    <div
      className={styles.shimmer}
      style={{
        backgroundImage: isLoading
          ? `linear-gradient(
              -65deg,
              transparent 40%,
              ${color} 50%,
              transparent 60%
            )`
          : "transparent",
        animationName: !isLoading
          ? "none"
          : slow
          ? styles.loadingShimmerSlow
          : styles.loadingShimmer,
        animationDuration: slow ? "10s" : "2s",
      }}
    />
  );
};

export const Placeholder = ({
  style: inlineStyles,
}: {
  style?: React.CSSProperties;
}) => {
  return <div className={styles.placeholder} style={inlineStyles} />;
};
