import React from "react";
import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import styles from "./DropdownMenu.module.css";

const DropdownMenu = ({
  label,
  trigger,
  arrow = false,
  side = "bottom",
  sideOffset = 5,
  align = "start",
  alignOffset = 0,
  disabled,
  children,
}: {
  label: string;
  trigger?: React.ReactNode;
  arrow?: boolean;
  side?: "top" | "bottom" | "left" | "right";
  sideOffset?: number;
  align?: "start" | "end";
  alignOffset?: number;
  disabled?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger asChild disabled={disabled}>
        {trigger || (
          <button className={styles.TriggerButton} aria-label={label}>
            <DotsHorizontalIcon />{" "}
          </button>
        )}
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <RadixDropdownMenu.Content
          className={styles.MenuContent}
          side={side}
          sideOffset={sideOffset}
          align={align}
          alignOffset={alignOffset}
          style={{
            padding: ".25rem",
          }}
        >
          {children}
          {arrow && <RadixDropdownMenu.Arrow className={styles.MenuArrow} />}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};

export const DropdownMenuItem = ({
  children,
  onSelect = () => {},
  destructive,
  disabled,
}: {
  children: React.ReactNode;
  onSelect?: () => void;
  destructive?: boolean;
  disabled?: boolean;
}) => {
  return (
    <RadixDropdownMenu.Item
      className={`
        ${styles.MenuItem}
        ${destructive && styles.destructive}
        ${disabled && styles.disabled}
      `}
      onSelect={onSelect}
      disabled={disabled}
    >
      {children}
      {/* <div className={styles.RightSlot}>⌘+T</div> */}
    </RadixDropdownMenu.Item>
  );
};

export default DropdownMenu;
