import { useState } from "react";

import Card from "brand/components/Card";
import BarChart from "brand/components/chart/BarChart";
import {
  useLocationHourly,
  fmtRainUnitM,
  fmtRainUnitI,
  mmToIn,
  chartTimeRangeOptions,
  ChartTimeRangeOption,
  inToMM,
} from "shared";
import { ErrorState } from "brand/components/EmptyState";
import Button, { ButtonGroupWrapper } from "brand/components/Button";
import { mngr } from "../store";

export default function LocationPageHourly({ location }) {
  const { preferredUnits } = mngr.useUnits();
  const fmtRainUnit = preferredUnits === "metric" ? fmtRainUnitM : fmtRainUnitI;
  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;
  const toOriginalUnits = preferredUnits === "metric" ? (n) => n : inToMM;

  const [timeRange, setTimeRange] = useState<ChartTimeRangeOption>(
    chartTimeRangeOptions[chartTimeRangeOptions.length - 1]
  );

  const {
    data: dataHourly,
    isLoading: isLoadingHourly,
    error: errorHourly,
  } = useLocationHourly(location, timeRange.start, timeRange.end);

  const startString = timeRange.start.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });
  const endString = timeRange.end.toLocaleDateString(undefined, {
    weekday: "short",
    hour: "numeric",
  });

  return (
    <Card>
      <div
        className="flex-row gap-4 justify-between align-center"
        style={{ marginBottom: "1rem" }}
      >
        <h4>
          Recent precipitation{" "}
          <span className="color-tertiary font-xs weight-normal">
            {startString} - {endString}
          </span>
        </h4>
        <ButtonGroupWrapper>
          {chartTimeRangeOptions.map((t) => (
            <Button
              key={t.label}
              secondary
              selected={timeRange.label === t.label}
              onClick={() => setTimeRange(t)}
            >
              {t.label}
            </Button>
          ))}
        </ButtonGroupWrapper>
      </div>

      {errorHourly ? (
        <ErrorState error={errorHourly} />
      ) : (
        <BarChart
          xAxisLabel="Time"
          yAxisLabel="Precipitation"
          xAxisTimeFormat="%a %I%p"
          xAxisTickFrequency="daily"
          nowLine={true}
          unitFormatter={fmtRainUnit}
          toOriginalUnits={toOriginalUnits}
          yUpperBoundMinimum={0.5}
          showRules
          isLoading={isLoadingHourly}
          data={dataHourly?.properties?.hours?.map(
            ({ startTime, precip, precip_probability, source }) => {
              return {
                value: toConvertedUnits(precip),
                probability: precip_probability,
                date: new Date(startTime),
                source,
              };
            }
          )}
        />
      )}
    </Card>
  );
}
