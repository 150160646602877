import React, { useEffect, useRef } from "react";
// @ts-ignore
import * as Plot from "https://cdn.jsdelivr.net/npm/@observablehq/plot@0.6.15/+esm";
import { mngr } from "../../store";
import { mmToIn } from "shared";
import { tickFormat } from "d3-scale";
// import { utcHour } from "https://cdn.jsdelivr.net/npm/d3-time@3.1.0/+esm";

export default function GroupedBar({ data: dataRaw, height }) {
  const ref = useRef(null);

  const { preferredUnits } = mngr.useUnits();

  const toConvertedUnits = preferredUnits === "metric" ? (n) => n : mmToIn;

  const data = dataRaw.map((d) => {
    return {
      ...d,
      value: toConvertedUnits(d.value),
    };
  });

  useEffect(() => {
    if (data.length === 0) return;
    
    
    // This worked pretty well for hourly data, ticks given to fx.ticks
    // ideally we'd round to every (N * 6) hours depending on width
    if (false) {
      const minPixelsPerTick = 75;
      const oneHour = 1000 * 60 * 60;
      const _minT = Math.min(...data.map((d) => d.date));
      const minT = Math.floor(_minT / (oneHour * 6)) * (oneHour * 6);
      const maxT = Math.max(...data.map((d) => d.date));
      const width = ref.current.clientWidth;
      const hours = (maxT - minT) / (oneHour);
      const approxTickCount = (width / minPixelsPerTick);
      const hoursPerTick = Math.ceil(hours / approxTickCount / 6) * 6;
      // @ts-ignore
      const ticks = Array(Math.ceil(hours / hoursPerTick)).fill().map((x,i)=> {
          const iT = minT + i * hoursPerTick * (oneHour);
          return new Date(iT);
      })
    }



    const plot = Plot.plot({
      x: {
        axis: null,
      },
      fx: {
        tickFormat: '%b %d'
      },
      color: { scheme: "blues", legend: true },
      marks: [
        Plot.barY(data, {
          tip: true,
          channels: { value: "value", source: "source", date: "date" },
          x: "source",
          y: "value",
          fx: "date",
          fill: "source",
        }),
        Plot.ruleY([0]),
      ],
      width: ref.current.clientWidth,
      height: height,
    });

    ref.current.append(plot);
    return () => plot.remove();
  }, [data]);
  return <div style={{ width: '100%', height: `${height}px` }} ref={ref}></div>;
}
