import Button from "brand/components/Button";
import PageLayout from "../layouts/PageLayout";
import { Helmet } from "react-helmet-async";

export default function NoAuthPage() {
  return (
    <PageLayout>
      <Helmet>
        <title>Precip | No access</title>
      </Helmet>

      <div
        style={{
          maxWidth: "800px",
          margin: "10vh auto",
        }}
      >
        <h1>Whoops, you don't have access to this page</h1>
        <br />
        <p>Make sure you're on the right organization account.</p>
        <Button secondary onClick={() => window.location.replace("/")}>
          Go home
        </Button>
      </div>
    </PageLayout>
  );
}
