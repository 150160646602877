/*
 * Dark mode
 *
 * TODO
 * - Add an app setting to let users override the OS preference
 *   and save it to local storage or Ionic Preferences
 * - Bonus: if the user has not set a preference, use different dark schemes based on
 *   whether it's day or night (according to the preferred theme OS setting)
 */

import { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: dark)";

export const useDarkMode = () => {
  const prefersDarkMode = useMediaQuery(COLOR_SCHEME_QUERY);

  useEffect(() => {
    if (prefersDarkMode) {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.setAttribute("data-theme", "light");
    }
  }, [prefersDarkMode]);

  return prefersDarkMode;
};
