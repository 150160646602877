import { Warning } from "@phosphor-icons/react";
import styles from "./Avatar.module.css";
import { Spinner } from "./Loading";

export default function Avatar({
  image,
  name,
  size = 32,
  isLoading,
  error,
}: {
  image?: string;
  name: string;
  size?: number;
  isLoading?: boolean;
  error?: Error;
}) {
  const text = !name
    ? ""
    : size > 25
    ? name
        .split(" ")
        .map((n) => n[0]?.toUpperCase() || "")
        .join("")
    : name[0]?.toUpperCase();

  return (
    <div
      className={styles.wrapper}
      title={isLoading ? "Loading..." : error ? error.message : text || ""}
      style={{
        width: size + "px",
        height: size + "px",
        fontSize: size / 3 + "px",
        fontWeight: size < 25 ? 600 : size < 35 ? 500 : 400,
      }}
    >
      {isLoading && <Spinner size={size * 0.5} />}
      {error && <Warning size={size * 0.5} />}
      {isLoading || error ? "" : image ? <img src={image} alt={""} /> : text}
    </div>
  );
}
